import { Search } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { Select } from 'components/MultiSelect';
import { moduleOptions } from 'pages/Module/common/const';

export const ModuleSearchBar = ({
  filterMode,
  setFilterMode,
  setSearchTerm,
  searchTerm,
}) => {
  return (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          // defaultValue={'all'}
          value={filterMode}
          onChange={(e) => setFilterMode(e.target.value)}
        >
          <FormControlLabel value="all" control={<Radio />} label="All" />
          <FormControlLabel
            value="selected"
            control={<Radio />}
            label="Selected"
          />
        </RadioGroup>
      </FormControl>

      <Select
        label="Module Type"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        options={[
          ...moduleOptions.moduleType,
          { value: 'ai', label: 'AI' },
          { value: 'translate', label: 'Translate' },
        ]}
      />

      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search by title or type"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );
};

export default ModuleSearchBar;

import { DoneOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { InputAdornment, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import {
  SelectDocumentAnalysisBulkAction,
  SelectDocumentAnalysisStatus,
} from 'components/SelectField';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import styles from './styles/Dashboard.module.css';

import organizationAPI from 'api/api';
import { LoadingSystem } from 'components/CustomMUI';
import { LoaderVariants } from 'components/CustomMUI/LoadingSystem';
import { useFetcher } from 'hooks/useFetcher';
import UploadDocument from 'pages/StudentView/DocumentAnalysis/Common/UploadDocument';

const RemoveModal = ({
  open,
  setOpen,
  getDocumentsByOrganization,
  documentId,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };
  const { call: deleteDocumentAnalayzeById } = useFetcher({
    fetcher: organizationAPI.deleteDocumentAnalayzeById,
    loaderId: 'deleteDocumentAnalayzeById',
    loaderStyle: 'simple',
  });
  const handleDelete = async (e, documentId) => {
    e.preventDefault();

    try {
      await deleteDocumentAnalayzeById(documentId);
      await getDocumentsByOrganization();
    } catch (error) {
      console.log(error);
    }

  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <h5>Remove</h5>
          <p>Are you sure you want to delete?</p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button
            onClick={(e) => {
              handleDelete(e, documentId);
              setOpen(false);
            }}
            className={styles.remove}
          >
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const DocumentDashboard = () => {
  const [canvasList, setCanvasList] = useState([]);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectDocumentType, setSelectDocumentType] = useState('All');
  const [bulkAction, setBulkAction] = useState('');
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [deleteDocumentId, setDeleteDocumentId] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const convertDate = (dateString) => {
    return moment(dateString).format('MMMM D, YYYY');
  };

  const { call: getDocumentAnalyzeByOrganization } = useFetcher({
    fetcher: organizationAPI.getDocumentAnalyzeByOrganization,
    successMessage: 'Fetched all documents successfully',
    loaderId: 'getDocumentAnalyzeByOrganization',
    loaderStyle: 'simple',
  });
  const { call: bulkActionDocumentAnalyze } = useFetcher({
    fetcher: organizationAPI.bulkActionDocumentAnalyze,
    loaderId: 'bulkActionDocumentAnalyze',
    loaderStyle: 'simple',
  });
  const { call: getUserdata } = useFetcher({
    fetcher: organizationAPI.getMe,
    loaderId: 'getMe',
    loaderStyle: 'simple',
  });

  // const handleAnalysis = (e) => {
  //   e.preventDefault();
  //   navigate('/coach/documentanalysis/newanalysis');
  // };

  const handleView = (e, documentId) => {
    e.preventDefault();
    navigate(`/coach/documentanalysis/view/${documentId}`);
  };

  const handleDateChange = (date, type) => {
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleOk = (data) => {
    setStartDate(data[0]);
    setEndDate(data[1]);
  };

  const handleClean = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleBulkAction = async (e) => {
    e.preventDefault();

    const selectedDocuments = canvasList
      .filter((canvas) => canvas.isSelected)
      .map((canvas) => canvas._id);

    const data = {
      documentIds: selectedDocuments,
    };

    if (bulkAction === 'Student Review') {
      data.status = 'studentReview';
    } else if (bulkAction === 'Done') {
      data.status = 'done';
    }

    try {
      const response = await bulkActionDocumentAnalyze(data);
      await getDocumentsByOrganization();
    } catch (error) {
      console.log(error);
    }

    navigate(0);
    setBulkAction('');
  };

  const getDocumentsByOrganization = async () => {
    try {
      const response = await getDocumentAnalyzeByOrganization();
      const temp = response.data.reverse().map((item) => {
        if (item.status === 'coachReview') {
          return {
            ...item,
            status: 'Coach Review',
            isSelected: false,
          };
        } else if (item.status === 'aiReview') {
          return {
            ...item,
            status: 'AI Review',
            isSelected: false,
          };
        } else if (item.status === 'studentReview') {
          return {
            ...item,
            status: 'Student Review',
            isSelected: false,
          };
        } else if (item.status === 'done') {
          return {
            ...item,
            status: 'Done',
            isSelected: false,
          };
        } else if (item.status === 'error') {
          return {
            ...item,
            status: 'Error',
            isSelected: false,
          };
        } else if (item.status === 'pending') {
          return {
            ...item,
            status: 'Pending',
            isSelected: false,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      setCanvasList(temp);
    } catch (error) {
      console.log(error);
    }
  };

  const getMe = async () => {
    try {
      const response = await getUserdata();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMe();
    getDocumentsByOrganization();
  }, []);

  return (
    <>
      <LoadingSystem
        variant={LoaderVariants.SIMPLE}
        title={`Loading Documents. Please Wait ...`}
        color={'#FFFFFFFF'}
        height={150}
        width={150}
      />

      <RemoveModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        getDocumentsByOrganization={getDocumentsByOrganization}
        documentId={deleteDocumentId}
      />
      <UploadDocument
        onUploadSuccess={getDocumentsByOrganization}
        showUploadModal={showUploadModal}
        setShowUploadModal={setShowUploadModal}
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />
      <h1>Welcome, {user && user.firstName + ' ' + user.lastName}</h1>
      <div className={styles.border}></div>
      <div className={styles.learner_header}>
        <h2>Documents</h2>
        <div className={styles.learner_header_buttons}>
          <button
            onClick={() => setShowUploadModal(true)}
            className={styles.iteration}
          >
            <AddIcon />
            Upload New Document
          </button>
        </div>
      </div>

      <div className={styles.input_fields}>
        <TextField
          id="outlined-basic"
          label="Search by keyword or tag"
          variant="outlined"
          className="search_input"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            width: '25%',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              'background-color': 'white',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <FormControl
          className={styles.dropdown_section}
          sx={{
            width: '25%',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              'background-color': 'white',
            },
          }}
        >
          <SelectDocumentAnalysisStatus
            value={selectDocumentType}
            setValue={setSelectDocumentType}
            input={'Filter by analysis status'}
            label={'Filter by analysis status'}
          />
        </FormControl>
        <FormControl
          className={styles.date_range}
          sx={{
            width: '25%',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              'background-color': 'white',
            },
          }}
        >
          {/* <TextField
                id="date"
                label="Date Range"
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
          <DateRangePicker
            placeholder="Date Range"
            format={'yyyy-MM-dd'}
            character="  to  "
            onChange={handleDateChange}
            onOk={handleOk}
            // ref={dateRangePickerRef}
            onClean={handleClean}
            style={{ minWidth: 200 }}
            className={styles.date_range_picker}
          />
        </FormControl>
        <FormControl
          className={styles.dropdown_section}
          sx={{
            width: '25%',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              'background-color': 'white',
            },
          }}
        >
          <SelectDocumentAnalysisBulkAction
            value={bulkAction}
            setValue={setBulkAction}
            input={'Select Bulk Action'}
            label={'Select Bulk Action'}
          />
        </FormControl>
      </div>
      {canvasList.filter((canvas) => canvas.isSelected).length > 0 && (
        <div className={styles.submit_bar}>
          <span>
            {canvasList.filter((canvas) => canvas.isSelected).length} documents
            selected.
          </span>
          <button onClick={handleBulkAction}>
            <DoneOutlined /> Submit to NFTE
          </button>
        </div>
      )}

      <div className={styles.coach_roaster}>
        <div className={styles.learner_table}>
          <table className="styled_table">
            <thead className="table_headers">
              <tr>
                <th>
                  <Checkbox
                    {...label}
                    value={canvasList.every((canvas) => canvas.isSelected)}
                    onChange={(e) => {
                      const newCanvasList = canvasList.map((canvas) => {
                        return {
                          ...canvas,
                          isSelected: e.target.checked,
                        };
                      });
                      setCanvasList(newCanvasList);
                    }}
                  />
                  Name
                </th>
                <th>Uploaded by</th>
                <th>Uploaded on</th>

                {/* <th>Tags</th> */}
                <th
                  style={{
                    textAlign: 'left',
                  }}
                >
                  Analysis Status
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {canvasList.length > 0 &&
                canvasList
                  .filter((item) => {
                    if (search) {
                      return (
                        item.answers[0]?.answer?.title
                          ?.toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.userId
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.userName
                          ?.toLowerCase()
                          .includes(search.toLowerCase())
                      );
                    }
                    return true;
                  })
                  .filter((item) => {
                    if (selectDocumentType !== 'All') {
                      return item.status === selectDocumentType;
                    } else {
                      return true;
                    }
                  })
                  .filter((item) => {
                    if (startDate && endDate) {
                      return (
                        moment(item.createdAt).isSameOrAfter(startDate) &&
                        moment(item.createdAt).isSameOrBefore(endDate)
                      );
                    }
                    return true;
                  })
                  .map((item, index) => {
                    return (
                      <tr className={styles.coachrow} key={index}>
                        <td className={styles.data}>
                          <Checkbox
                            checked={item.isSelected}
                            onChange={(e) => {
                              const newCanvasList = canvasList.map(
                                (canvas, i) => {
                                  if (i === index) {
                                    return {
                                      ...canvas,
                                      isSelected: e.target.checked,
                                    };
                                  }
                                  return canvas;
                                }
                              );
                              setCanvasList(newCanvasList);
                            }}
                          />
                          {item.answers[0]?.answer?.title
                            ? item.answers[0]?.answer?.title
                            : item.filename}
                        </td>
                        <td className={styles.data}>
                          {item.userName ? item.userName : item.userId}
                        </td>
                        <td className={styles.data}>
                          {convertDate(item.createdAt)}
                        </td>

                        {/* <td className={styles.tag_year}>
                        <span>TAG 01</span> <span>2024</span>
                      </td> */}
                        <td className={styles.data}>
                          {item.status === 'Student Review' && (
                            <p>
                              <FiberManualRecordOutlinedIcon />
                              {item.status}
                            </p>
                          )}
                          {item.status === 'Pending' && (
                            <p>
                              <FiberManualRecordOutlinedIcon />
                              {item.status}
                            </p>
                          )}
                          {item.status === 'AI Review' && (
                            <p>
                              <FiberManualRecordOutlinedIcon />
                              {item.status}
                            </p>
                          )}
                          {item.status === 'Error' && (
                            <p className={styles.error}>
                              <ReportProblemOutlinedIcon /> {item.status}
                            </p>
                          )}
                          {item.status === 'Coach Review' && (
                            <p className={styles.progress}>
                              <PendingOutlinedIcon /> {item.status}
                            </p>
                          )}
                          {item.status === 'Done' && (
                            <p className={styles.complete}>
                              <CheckCircleOutlineOutlinedIcon />
                              {item.status}
                            </p>
                          )}
                        </td>

                        <td className={styles.data}>
                          <div className={styles.status}>
                            <div className={styles.resend}>
                              <CloudDownloadOutlinedIcon />
                              <a
                                href={item.fileUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download
                              </a>
                            </div>
                            <div className={styles.resend}>
                              <VisibilityOutlinedIcon />
                              <button onClick={(e) => handleView(e, item._id)}>
                                View
                              </button>
                            </div>

                            <div className={styles.resend}>
                              <DeleteOutlineOutlinedIcon />
                              <button
                                onClick={(e) => {
                                  setDeleteDocumentId(item._id);
                                  setDeleteModalOpen(true);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {canvasList.length === 0 && (
            <div className={styles.empty_table}>
              <p>No submissions have been made yet.</p>
              {/* <p onClick={handleOpen} className={styles.link}>
                Click here to make a new submission template.
              </p> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentDashboard;

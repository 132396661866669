import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import organizationAPI from 'api/api';
import {
  EmptyState,
  LoadingSystem,
  RadarChart,
  RaderChartLegend,
  RadioButton,
  SearchBar,
  SummaryInfo,
} from 'components/CustomMUI';
import { LoaderVariants } from 'components/CustomMUI/LoadingSystem';
import useModal from 'hooks/useConfirmationModal';
import { useFetcher } from 'hooks/useFetcher';
import useNotification from 'hooks/useNotification';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useNotificationStore from 'store/useNotificationStore';
import useStore from 'store/useStore';
import BasicInformation from './BasicInformation';
import CallHistoryTable from './CallHistoryTable';
import CallHistoryTableHeader from './CallHistoryTableHeader';
import ExpandableUserCallHistory from './ExpandableUserCallHistory';
import IndividualCallHistory from './IndividualCallHistory';
import KeyMetrics from './KeyMetrics';
import PerformanceSummary from './PerformanceSummary';
import styles from './styles/Style.module.css';

const ViewSalesRepresentative = () => {
  const [viewMode, setViewMode] = useState('linear'); // 'grouped' or 'linear'

  const { show, NotificationComponent } = useNotification();
  const { notification } = useNotificationStore();
  const { openModal, Modal } = useModal();
  const { userData, clearUserData } = useStore();
  const navigate = useNavigate();
  const [individualPerformanceData, setIndividualPerformanceData] = useState();
  const [individualUserStatsData, setIndividualUserStatsData] = useState();
  const [teamPerformanceData, setTeamPerformanceData] = useState();
  const [spiderChartData, setSpiderChartData] = useState([]);
  const [individualCallHistoryData, setIndividualCallHistoryData] = useState(
    []
  );
  const [allCallHistoryData, setAllCallHistoryData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { call: performanceAnalyzerCall } = useFetcher({
    fetcher: organizationAPI.getPerformanceAnalyzer,
    successMessage: 'Fetched Performance Analyze Data',
    loaderId: 'getPerformanceAnalyzer',
    loaderLabel: 'Performance Analysis Generated',
    loaderStyle: 'checklist',
  });

  const { call: individualUserStatsCall } = useFetcher({
    fetcher: organizationAPI.getIndividualUserStats,
    loaderId: 'individualUserStats',
    loaderLabel: 'User Stats Generated',
    loaderStyle: 'checklist',
  });

  const { call: individualCallHistoryCall } = useFetcher({
    fetcher: organizationAPI.getIndividualCallHistory,
    loaderId: 'userCallHistory',
    loaderLabel: 'User Call History Generated',
    loaderStyle: 'checklist',
  });

  const { call: individualSpiderChartCall } = useFetcher({
    fetcher: organizationAPI.getIndividualSpiderChart,
    loaderId: 'spiderChart',
    loaderLabel: 'Spider Chart Generated',
    loaderStyle: 'checklist',
  });

  const { call: getTeamPerformanceAnalyzerCall } = useFetcher({
    fetcher: organizationAPI.teamPerformanceAnalyzer,
    loaderId: 'teamPerformanceAnalysis',
    loaderLabel: 'Team Performance Analysis Generated',
    loaderStyle: 'checklist',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchPerformanceAnalyzerData = async () => {
      try {
        const response = await performanceAnalyzerCall(userData.userId);
        if (response && response.data) {
          const responseStrengthData = {
            title: `${response.data.performanceAnalysis.type} Strengths`,
            analysis: response.data.performanceAnalysis.analysis.strengths,
          };
          const responseWeaknessData = {
            title: `${response.data.performanceAnalysis.type} Weaknesses`,
            analysis: response.data.performanceAnalysis.analysis.weaknesses,
          };
          setIndividualPerformanceData({
            strengths: responseStrengthData,
            weaknesses: responseWeaknessData,
          });
        }
      } catch (error) {
        console.error('Error fetching personas', error);
        handleBackClick();
        clearUserData();
      }
    };
    const fetchIndividualUserStatsData = async () => {
      try {
        const response = await individualUserStatsCall(userData.userId);
        if (response && response.data) {
          setIndividualUserStatsData(response.data);
        }
      } catch (error) {
        console.error('Error fetching personas', error);
        handleBackClick();
        clearUserData();
      }
    };

    const fetchIndividualCallHistoryData = async () => {
      try {
        const response = await individualCallHistoryCall(userData.userId);

        if (response && response.data) {
          const personas = response.data.flatMap((team) => team.persona);
          setIndividualCallHistoryData(personas);

          const allCalls = response.data.flatMap((team) =>
            team.persona.flatMap((persona) => persona.data)
          );
          setAllCallHistoryData(allCalls);
        }
      } catch (error) {
        console.error('Error fetching personas', error);
        handleBackClick();
        clearUserData();
      }
    };
    const fetchIndividualSpiderChartData = async () => {
      try {
        const response = await individualSpiderChartCall(userData.userId);
        if (response && response.data) {
          setSpiderChartData(response.data);
        }
      } catch (error) {
        console.error('Error fetching personas', error);
        handleBackClick();
        clearUserData();
      }
    };

    const fetchTeamPerformanceAnalyzerResponseData = async () => {
      try {
        const response = await getTeamPerformanceAnalyzerCall();
        if (response) {
          const responseStrengthData = {
            title: `${response.data.performanceAnalysis.type} Strengths`,
            analysis: response.data.performanceAnalysis.analysis.strengths,
          };
          const responseWeaknessData = {
            title: `${response.data.performanceAnalysis.type} Weaknesses`,
            analysis: response.data.performanceAnalysis.analysis.weaknesses,
          };
          setTeamPerformanceData({
            strengths: responseStrengthData,
            weaknesses: responseWeaknessData,
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        handleBackClick();
        clearUserData();
      } finally {
      }
    };

    if (userData && userData.userId) {
      fetchPerformanceAnalyzerData();
      fetchIndividualUserStatsData();
      fetchTeamPerformanceAnalyzerResponseData();
      fetchIndividualCallHistoryData();
      fetchIndividualSpiderChartData();
    }
  }, [userData]);

  useEffect(() => {
    if (notification) {
      show(notification.message, notification.type);
    }
  }, [notification, show]);

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  const handleBackClick = () => {
    navigate('/admin/leaderboard');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredData = allCallHistoryData.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {/* {!notification && loading ? <LoadingOverlay></LoadingOverlay> : null} */}
      {notification && (
        <NotificationComponent
          notification={notification.message}
          type={notification.type}
        />
      )}
      <div className={styles.main}>
        <Modal />
        <LoadingSystem
          variant={LoaderVariants.CHECKLIST}
          title={`Loading Sales Representative Data`}
        />

        <div className={styles.content}>
          <div className={styles.navbar_container}>
            <div className={styles.navbar}>
              {userData && <h1>{userData.name} </h1>}
              <div className={styles.nav_right}>
                {/* <CustomDatepicker label={'Select date range'} /> */}
                <div className={styles.nav_button_container}>
                  <RadioButton
                    label={'Grouped by Persona'}
                    checked={viewMode === 'grouped'}
                    onChange={() => handleViewModeChange('grouped')}
                  />
                  <RadioButton
                    label={'Call History'}
                    checked={viewMode === 'linear'}
                    onChange={() => handleViewModeChange('linear')}
                  />
                </div>
                <button className={styles.nav_btn} onClick={handleBackClick}>
                  <ChevronLeftIcon />
                  <p>Return</p>
                </button>
              </div>
            </div>
            <Box
              sx={{
                border: '1px solid var(--global-outline-grey, #dae8e7)',
                marginTop: '10px',
                marginBottom: '30px',
              }}
            />
          </div>

          <div className={styles.container}>
            <div className={styles.container_split}>
              <div className={styles.container_left}>
                <BasicInformation data={userData} />
                {viewMode === 'grouped' && (
                  <KeyMetrics data={individualUserStatsData} />
                )}
              </div>
              <div className={styles.container_right}>
                {viewMode === 'grouped' ? (
                  <PerformanceSummary data={individualPerformanceData} />
                ) : (
                  <KeyMetrics data={individualUserStatsData} />
                )}
              </div>
            </div>

            {viewMode === 'grouped' ? (
              <></>
            ) : (
              <div className={styles.container_split}>
                <div className={styles.container_left}>
                  {spiderChartData ? (
                    <RadarChart
                      labels={spiderChartData.labels}
                      datasets={spiderChartData.datasets}
                    />
                  ) : (
                    <EmptyState />
                  )}
                  {spiderChartData && spiderChartData.datasets && (
                    <div className={styles.summary_left_nav}>
                      <RaderChartLegend
                        color={'rgba(0, 112, 108, 1)'}
                        title={spiderChartData.datasets[0].label}
                      />
                      <RaderChartLegend
                        color={'rgba(54, 162, 235, 1)'}
                        title={spiderChartData.datasets[1].label}
                      />
                    </div>
                  )}
                </div>
                {individualPerformanceData && teamPerformanceData ? (
                  <div className={styles.container_right}>
                    <SummaryInfo data={individualPerformanceData.strengths} />
                    <SummaryInfo data={individualPerformanceData.weaknesses} />
                    <SummaryInfo data={teamPerformanceData.strengths} />
                    <SummaryInfo data={teamPerformanceData.weaknesses} />
                  </div>
                ) : (
                  <EmptyState />
                )}
              </div>
            )}
            <div className={styles.call_history_navbar}>
              <h1>Call History</h1>
              <div className={styles.nav_button_group}>
                {/* <div className={styles.nav_button_container}>
                  <CustomCheckbox
                    label={'AI Roleplay Calls'}
                    color={'#00706C'}
                  />
                  <CustomCheckbox label={'Customer Calls'} color={'#00706C'} />
                </div> */}
                <SearchBar
                  placeholder="Search by name"
                  handleSearch={handleSearchChange}
                  searchText={searchTerm}
                />
              </div>
            </div>
            <Box
              sx={{
                border: '1px solid var(--global-outline-grey, #dae8e7)',
                width: '100%',
                marginTop: '-20px',
              }}
            />
            <div className={styles.call_history_container}>
              {viewMode === 'grouped' ? (
                <>
                  <CallHistoryTableHeader />
                  {!searchTerm &&
                    individualCallHistoryData &&
                    individualCallHistoryData.length > 0 &&
                    individualCallHistoryData.map((item, index) => (
                      <ExpandableUserCallHistory key={index} data={item} />
                    ))}
                  {searchTerm &&
                    filteredData.map((item, index) => (
                      <IndividualCallHistory key={index} data={item} />
                    ))}
                </>
              ) : (
                <>
                  {!searchTerm &&
                    allCallHistoryData &&
                    allCallHistoryData.length > 0 && (
                      <CallHistoryTable data={allCallHistoryData} />
                    )}
                  {searchTerm &&
                    filteredData.map((item, index) => (
                      <IndividualCallHistory key={index} data={item} />
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSalesRepresentative;

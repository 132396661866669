import { useMemo, useState } from 'react';

export const useTableSearch = ({
  data,
  searchFields,
  dateRangeColumn,
  filter = () => true,
  dependencies = [],
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const filteredData = useMemo(() => {
    if (!data) return [];

    return data.filter((item) => {
      const itemDate = new Date(item[dateRangeColumn]);

      const isWithinDateRange =
        (!startDate || new Date(startDate) <= itemDate) &&
        (!endDate || new Date(endDate).setHours(23, 59, 59, 999) >= itemDate);

      if (!isWithinDateRange) return false;
      if (!filter(item)) return false;

      return searchFields.some((field) => {
        const fieldValue = item[field];

        const normalizedField = fieldValue
          ?.toString()
          .toLowerCase()
          .replace(/\s+/g, ' ')
          .trim();
        const normalizedSearch = searchTerm
          .toLowerCase()
          .replace(/\s+/g, ' ')
          .trim();

        return normalizedField?.includes(normalizedSearch);
      });
    });
  }, [
    data,
    searchTerm,
    searchFields,
    startDate,
    endDate,
    filter,
    dateRangeColumn,
    ...dependencies,
  ]);

  return {
    searchTerm,
    setSearchTerm,
    filteredData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };
};

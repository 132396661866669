import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import { FormFields, LoadingSystem } from 'components/CustomMUI';
import { LoaderVariants } from 'components/CustomMUI/LoadingSystem';
import { useLoggedUser } from 'contexts/UserContext';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useFetcher } from 'hooks/useFetcher';
import { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useNavigate } from 'react-router-dom';
import useBirdsEyeDataStore from 'store/useBirdsEyeStatisticStore';

const DateRangePickerButton = ({ startDate, endDate, onChange }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState([
    {
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
      key: 'selection',
    },
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (item) => {
    setState([item.selection]);
    onChange(item.selection);
  };

  useEffect(() => {
    if (!startDate || !endDate) {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
    }
  }, [startDate, endDate]);

  const formattedDate =
    state[0].startDate &&
    state[0].endDate &&
    state[0].startDate.getTime() !== state[0].endDate.getTime()
      ? `${format(state[0].startDate, 'MMM dd, yyyy')} - ${format(
          state[0].endDate,
          'MMM dd, yyyy'
        )}`
      : '';

  return (
    <Box sx={{ width: '50%' }}>
      <TextField
        label="Select Date Range"
        value={formattedDate}
        onClick={handleClick}
        placeholder="Pick a date range"
        fullWidth
        readOnly
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ mt: 1 }}
      >
        <DateRangePicker
          onChange={handleDateChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
          locale={enUS}
        />
      </Popover>
    </Box>
  );
};
const BirdsEyeStatisticFilter = () => {
  const [managers, setManagers] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const { user } = useLoggedUser();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(true);
  const datePickerRef = useRef(null);
  const { setStatistics, clearAllData } = useBirdsEyeDataStore();
  const [formData, setFormData] = useState({
    products: [],
    geography: [],
    managers: [],

    startDate: null,
    endDate: null,
    callRecipients: {
      ai: false,
      customer: false,
    },
    persona: [],
    tone: [],
    mood: [],
    difficulty: [],
    salesType: {
      smb: false,
      midMarket: false,
      enterprise: false,
    },
  });

  const { call: getUserByRole } = useFetcher({
    fetcher: apis.getUserByRole,
  });

  const { call: getPersonas } = useFetcher({
    fetcher: apis.getAllPersonas,
  });

  const { call: getBirdEyeSearchCriteria } = useFetcher({
    fetcher: apis.getBirdEyeSearchCriteria,
  });

  const { call: getgetBirdEyeSearch, isLoading } = useFetcher({
    fetcher: apis.getgetBirdEyeSearch,
    loaderId: 'getgetBirdEyeSearch',
    loaderLabel: 'Birds Eye Statistics Results',
    loaderStyle: 'checklist',
  });

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getUserByRole({
        orgId: user.organization,
        role: 'ADMIN',
      });

      setManagers(response.data);
    };
    const fetchPersonas = async () => {
      const response = await getPersonas();
      setPersonas(response.data);
    };

    const fetchBirdEyeSearchCriteria = async () => {
      const response = await getBirdEyeSearchCriteria();
      setCriteria(response.data);
    };

    fetchUser();
    fetchPersonas();
    fetchBirdEyeSearchCriteria();
    handleReset();
  }, []);

  const handleSalesTypeChange = (field, value, subField = null) => {
    setFormData((prev) => {
      return {
        ...prev,
        [field]: {
          ...prev[field],
          [subField]: value,
        },
      };
    });
  };

  const handleChange = (field, value, subField = null) => {
    setFormData((prev) => {
      if (subField) {
        const updatedArray = value
          ? [...prev[field], subField]
          : prev[field].filter((item) => item !== subField);

        return {
          ...prev,
          [field]: updatedArray,
        };
      }

      return {
        ...prev,
        [field]: value,
      };
    });
  };

  const handleDateRangeChange = (selectedRange) => {
    const { startDate, endDate } = selectedRange || {};
    setFormData((prev) => ({
      ...prev,
      startDate: startDate ? format(startDate, 'yyyy-MM-dd') : '',
      endDate: endDate ? format(endDate, 'yyyy-MM-dd') : '',
    }));
  };

  const buildQueryParams = (formData) => {
    const query = {};
    if (
      formData.startDate &&
      formData.endDate &&
      formData.startDate !== formData.endDate
    ) {
      query.startDate = formData.startDate;
      query.endDate = formData.endDate;
    }

    if (formData.products && formData.products.length > 0) {
      query.products = JSON.stringify(formData.products);
    }
    if (formData.geography && formData.geography.length > 0) {
      query.geography = JSON.stringify(formData.geography);
    }

    if (formData.managers && formData.managers.length > 0) {
      query.managerIds = JSON.stringify(formData.managers.map((m) => m.id));
    }

    if (formData.persona && formData.persona.length > 0) {
      query.personaIds = JSON.stringify(formData.persona.map((p) => p.id));
    }

    if (formData.callRecipients?.ai) {
      query.isAi = true;
    }

    if (formData.callRecipients?.customer) {
      query.isCustomer = true;
    }

    if (formData.tone && formData.tone.length > 0) {
      query.tone = JSON.stringify(formData.tone);
    }
    if (formData.mood && formData.mood.length > 0) {
      query.mood = JSON.stringify(formData.mood);
    }
    if (formData.difficulty && formData.difficulty.length > 0) {
      query.difficulty = JSON.stringify(formData.difficulty);
    }

    if (formData.salesType) {
      const salesTypes = Object.keys(formData.salesType)
        .filter((key) => formData.salesType[key])
        .reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});

      if (Object.keys(salesTypes).length > 0) {
        query.salesTypes = JSON.stringify(salesTypes);
      }
    }

    return query;
  };

  const handleSubmit = async () => {
    const query = buildQueryParams(formData);

    try {
      const response = await getgetBirdEyeSearch(query);
      setStatistics(response.data);
      navigate('/birds-eye-statistics');
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const handleReset = () => {
    setFormData({
      products: [],
      geography: [],
      managers: [],
      startDate: null,
      endDate: null,
      callRecipients: {
        ai: false,
        customer: false,
      },
      persona: [],
      tone: [],
      mood: [],
      difficulty: [],
      salesType: {
        smb: false,
        midMarket: false,
        enterprise: false,
      },
    });
    clearAllData();
    if (datePickerRef && datePickerRef.current) {
      datePickerRef.current.reset();
    }
  };

  return (
    <Box
      sx={{
        width: '98%',
      }}
    >
      {isLoading && (
        <LoadingSystem
          variant={LoaderVariants.CHECKLIST}
          title={`Fetching Birds Eye Statictic Data ...`}
        />
      )}
      <Box
        sx={{
          width: '100%',
          border: '1px solid #DAE8E7',
          padding: '20px',
          backgroundColor: '#fff',
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '20px',
              fontWeight: 300,
            }}
          >
            Bird’s-Eye Statistics
          </Typography>
          <Button
            endIcon={
              showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
            }
            onClick={() => setShowFilter(!showFilter)}
            sx={{
              mr: 1,
              color: '#5B706F',
              border: '1px solid #5B706F',
              borderRadius: '5px',
              p: '6px 15px',
              '&:hover': { backgroundColor: '#D9DAD9FF' },
            }}
          >
            {showFilter ? 'Hide' : 'Show'}
          </Button>
        </Box>
        <Box sx={{ border: '1px solid #DAE8E7', marginBottom: '20px' }} />
        {showFilter && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {criteria.products && (
              <Autocomplete
                multiple
                freeSolo
                options={criteria.products}
                value={formData.products || []}
                onChange={(event, value) => handleChange('products', value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={option}
                      key={index}
                      sx={{
                        backgroundColor: '#DAE8E7',
                        m: 0.5,
                        '& .MuiChip-deleteIcon': {
                          color: '#5B706F',
                        },
                      }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Products"
                    placeholder="Type product name"
                  />
                )}
              />
            )}
            {criteria.geography && (
              <Autocomplete
                multiple
                freeSolo
                options={criteria.geography}
                value={formData.geography || []}
                onChange={(event, value) => handleChange('geography', value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={option}
                      key={index}
                      sx={{
                        backgroundColor: '#DAE8E7',
                        m: 0.5,
                        '& .MuiChip-deleteIcon': {
                          color: '#5B706F',
                        },
                      }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Geography"
                    placeholder="Type geography name"
                  />
                )}
              />
            )}
            {managers && (
              <FormFields
                type="taginput"
                name="managers"
                label="Managers"
                formData={formData}
                handleChange={(name, value) => handleChange(name, value)}
                options={managers.map((manager) => ({
                  id: manager.id,
                  name: `${manager.firstName} ${manager.lastName}`,
                }))}
                tagInput={false}
                props={{
                  getOptionLabel: (option) => option.name || '',
                  renderTags: (value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={option.name}
                        sx={{
                          backgroundColor: '#DAE8E7',
                          m: 0.5,
                          '& .MuiChip-deleteIcon': {
                            color: '#5B706F',
                          },
                        }}
                      />
                    )),
                  renderInput: (params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Managers"
                      placeholder="Type manager name"
                    />
                  ),
                }}
              />
            )}
            {/* <FormFields
              type="taginput"
              name="tags"
              label="Tags"
              formData={formData}
              handleChange={(name, value) => handleChange(name, value)}
              options={[]}
              tagInput={true}
              props={{
                renderTags: (value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={option}
                      sx={{
                        backgroundColor: '#DAE8E7',
                        m: 0.5,
                        '& .MuiChip-deleteIcon': {
                          color: '#5B706F',
                        },
                      }}
                    />
                  )),
                renderInput: (params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder="Type Tags Here..."
                  />
                ),
              }}
            /> */}
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <DateRangePickerButton
                startDate={
                  formData.startDate ? new Date(formData.startDate) : ''
                }
                endDate={formData.endDate ? new Date(formData.endDate) : ''}
                onChange={handleDateRangeChange}
              />
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  border: '1px solid #C2C7C7FF',
                  p: '6px 20px',
                  borderRadius: '5px',
                  width: '50%',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: '#243030',
                    fontFamily: 'Lexend',
                    fontSize: '16px',
                    fontWeight: 300,
                    textWrap: 'nowrap',
                    mr: 1,
                  }}
                >
                  Call Recipients
                </Typography>
                <FormFields
                  type="checkbox"
                  name="ai"
                  label="AI"
                  formData={formData.callRecipients}
                  handleChange={(name, value) => {
                    handleChange('callRecipients', {
                      ...formData.callRecipients,
                      ai: value,
                    });
                  }}
                  props={{}}
                />
                <FormFields
                  type="checkbox"
                  name="customer"
                  label="Customer"
                  formData={formData.callRecipients}
                  handleChange={(name, value) => {
                    handleChange('callRecipients', {
                      ...formData.callRecipients,
                      customer: value,
                    });
                  }}
                  props={{}}
                />
              </Box>
            </Box>
            {personas && (
              <FormFields
                type="taginput"
                name="persona"
                label="Persona"
                formData={formData}
                handleChange={(name, value) => handleChange(name, value)}
                options={personas.map((persona) => ({
                  id: persona._id,
                  name: `${persona.name}`,
                }))}
                tagInput={false}
                props={{
                  getOptionLabel: (option) => option.name || '',
                  renderTags: (value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={option.name}
                        sx={{
                          backgroundColor: '#DAE8E7',
                          m: 0.5,
                          '& .MuiChip-deleteIcon': {
                            color: '#5B706F',
                          },
                        }}
                      />
                    )),
                  renderInput: (params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Persona"
                      placeholder="Type Persona Name"
                    />
                  ),
                }}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                border: '1px solid #C2C7C7FF',
                p: '6px 20px',
                borderRadius: '5px',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: '#243030',
                  fontFamily: 'Lexend',
                  fontSize: '16px',
                  fontWeight: 300,
                  textWrap: 'nowrap',
                  mr: 1,
                }}
              >
                Tone
              </Typography>
              {criteria &&
                Array.isArray(criteria.tone) &&
                criteria.tone.map((tone) => (
                  <FormControlLabel
                    key={tone}
                    control={
                      <Checkbox
                        checked={
                          Array.isArray(formData.tone) &&
                          formData.tone.includes(tone)
                        }
                        onChange={(e) =>
                          handleChange('tone', e.target.checked, tone)
                        }
                        name={tone}
                      />
                    }
                    label={tone}
                  />
                ))}
            </Box>{' '}
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                border: '1px solid #C2C7C7FF',
                p: '6px 20px',
                borderRadius: '5px',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: '#243030',
                  fontFamily: 'Lexend',
                  fontSize: '16px',
                  fontWeight: 300,
                  textWrap: 'nowrap',
                  mr: 1,
                }}
              >
                Mood
              </Typography>
              {criteria &&
                Array.isArray(criteria.mood) &&
                criteria.mood.map((mood) => (
                  <FormControlLabel
                    key={mood}
                    control={
                      <Checkbox
                        checked={
                          Array.isArray(formData.mood) &&
                          formData.mood.includes(mood)
                        }
                        onChange={(e) =>
                          handleChange('mood', e.target.checked, mood)
                        }
                        name={mood}
                      />
                    }
                    label={mood}
                  />
                ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                border: '1px solid #C2C7C7FF',
                p: '6px 20px',
                borderRadius: '5px',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: '#243030',
                  fontFamily: 'Lexend',
                  fontSize: '16px',
                  fontWeight: 300,
                  textWrap: 'nowrap',
                  mr: 1,
                }}
              >
                Difficulty
              </Typography>
              {criteria &&
                Array.isArray(criteria.difficulty) &&
                ['Easy', 'Medium', 'Hard'].map((difficulty) => (
                  <FormControlLabel
                    key={difficulty}
                    control={
                      <Checkbox
                        checked={
                          Array.isArray(formData.difficulty) &&
                          formData.difficulty.includes(difficulty)
                        }
                        onChange={(e) =>
                          handleChange(
                            'difficulty',
                            e.target.checked,
                            difficulty
                          )
                        }
                        name={difficulty}
                      />
                    }
                    label={difficulty}
                  />
                ))}
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  border: '1px solid #C2C7C7FF',
                  p: '6px 20px',
                  borderRadius: '5px',
                  width: '100%',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: '#243030',
                    fontFamily: 'Lexend',
                    fontSize: '16px',
                    fontWeight: 300,
                    textWrap: 'nowrap',
                    mr: 1,
                  }}
                >
                  Vertical
                </Typography>
                {Object.keys(formData.salesType).map((key) => (
                  <FormFields
                    key={key}
                    type="checkbox"
                    name={key}
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    formData={formData.salesType}
                    handleChange={(name, value) => {
                      handleSalesTypeChange('salesType', value, name);
                    }}
                    props={{}}
                  />
                ))}
              </Box>
              {/* <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  border: '1px solid #C2C7C7FF',
                  p: '6px 20px',
                  borderRadius: '5px',
                  width: '50%',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: '#243030',
                    fontFamily: 'Lexend',
                    fontSize: '16px',
                    fontWeight: 300,
                    textWrap: 'nowrap',
                    mr: 1,
                  }}
                >
                  Call Type
                </Typography>
                {Object.keys(formData.callType).map((key) => (
                  <FormFields
                    key={key}
                    type="checkbox"
                    name={key}
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    formData={formData.callType}
                    handleChange={(name, value) =>
                      handleChange('callType', value, name)
                    }
                    props={{}}
                  />
                ))}
              </Box> */}
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Button
                startIcon={<CheckIcon />}
                sx={{
                  mr: 1,
                  color: '#fff',
                  borderRadius: '5px',
                  backgroundColor: '#00706C',
                  p: '16px 15px',
                  width: '50%',
                  '&:hover': { backgroundColor: '#028682FF', color: '#fff' },
                }}
                onClick={handleSubmit}
              >
                Display Results
              </Button>
              <Button
                startIcon={<UndoIcon />}
                onClick={handleReset}
                sx={{
                  mr: 1,
                  color: '#5B706F',
                  borderRadius: '5px',
                  border: '1px solid #5B706F',
                  p: '16px 15px',
                  width: '50%',
                  '&:hover': { backgroundColor: '#D9DAD9FF', color: '#5B706F' },
                }}
              >
                Reset
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BirdsEyeStatisticFilter;

import { useSnackbar } from 'contexts/SnackbarContext';
import { useCallback } from 'react';

// Move error messages to a separate object for better organization
const ERROR_MESSAGES = {
  // Authentication errors
  401: 'Unauthorized access',
  403: 'Forbidden access',
  404: 'Resource not found',
  // Server errors
  500: 'Something went wrong on the server. Please try again later.',
  501: 'The server does not support this request.',
  502: 'Bad Gateway: Received an invalid response from the upstream server.',
  503: 'Service Unavailable: The server is temporarily unavailable.',
  504: 'Gateway Timeout: The server took too long to respond.',
  // Default message
  default: 'An unexpected error occurred.',
};

export const useErrorHandler = (asyncFn) => {
  const { showSnackbar } = useSnackbar();

  return useCallback(
    async (...args) => {
      try {
        return await asyncFn(...args);
      } catch (error) {
        console.error('Error caught by hook:', error);

        const status = error.status;
        const message =
          ERROR_MESSAGES[status] || error.message || ERROR_MESSAGES.default;

        if (status >= 500 && status <= 504) {
          // Server errors shown in alert
          alert(message);
        } else {
          // All other errors shown in snackbar
          showSnackbar(message, 'error');
        }
      }
    },
    [asyncFn, showSnackbar]
  );
};

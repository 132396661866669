import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { parseDate } from 'utils';
import { naturalCompare } from 'utils/function';
export const DataTable = ({
  columns,
  data = [],
  sort = true,
  size = 'medium',
  orderBy = '_id',
  order = 'desc',
  onRowClick,
  rowStyle = {},
}) => {
  const [dataOrder, setDataOrder] = useState(order);
  const [dataOrderBy, setDataOrderBy] = useState(orderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (property) => {
    const isAsc = dataOrderBy === property && dataOrder === 'asc';
    setDataOrder(isAsc ? 'desc' : 'asc');
    setDataOrderBy(property);
  };

  const resetPageIndexWhileSearch = () => {
    // Reset page whenever data changes (e.g. when search filtering is applied)
    setPage(0);
  };

  useEffect(() => {
    resetPageIndexWhileSearch();
  }, [data]);

  const getSortableValue = (value) => {
    if (React.isValidElement(value) && value.props?.label) {
      return value.props.label;
    }
    return value;
  };

  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => {
      let valueA = getSortableValue(a[dataOrderBy]);
      let valueB = getSortableValue(b[dataOrderBy]);

      if (!valueA) return dataOrder === 'asc' ? -1 : 1;
      if (!valueB) return dataOrder === 'asc' ? 1 : -1;

      const dateA = parseDate(valueA);
      const dateB = parseDate(valueB);

      const isDateAValid = !isNaN(dateA.getTime());
      const isDateBValid = !isNaN(dateB.getTime());

      if (isDateAValid && isDateBValid) {
        return dataOrder === 'asc' ? dateA - dateB : dateB - dateA;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        const cmp = naturalCompare(valueA, valueB);
        return dataOrder === 'asc' ? cmp : -cmp;
      }

      return dataOrder === 'asc'
        ? valueA < valueB
          ? -1
          : valueA > valueB
          ? 1
          : 0
        : valueB < valueA
        ? -1
        : valueB > valueA
        ? 1
        : 0;
    });
  }, [data, dataOrder, dataOrderBy]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        <Table style={{ tableLayout: 'fixed' }} size={size}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    width: `${100 / columns.length}%`,
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {sort && column.id !== 'actions' ? (
                    <TableSortLabel
                      active={dataOrderBy === column.id}
                      direction={dataOrderBy === column.id ? dataOrder : 'asc'}
                      onClick={() => handleRequestSort(column.id)}
                      sx={{
                        maxWidth: '100%',
                        '& .MuiTableSortLabel-icon': {
                          position: 'absolute',
                          right: -5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                        '& .MuiTableSortLabel-root': {
                          width: '100%',
                        },
                      }}
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          paddingRight: '20px',
                        }}
                      >
                        {column.label}
                      </div>
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <React.Fragment key={row?.id || row._id || index}>
                    <TableRow
                      hover
                      key={index}
                      onClick={() => onRowClick && onRowClick(row)}
                      sx={{
                        '& > *': {
                          borderBottom: row?.subRows?.length
                            ? 'unset'
                            : '1px solid #ccc',
                        },
                        ...rowStyle,
                      }}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          style={{
                            width: `${100 / columns.length}%`,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {Array.isArray(row[column.id])
                            ? row[column.id].join(', ')
                            : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row?.subRows?.length
                      ? row.subRows.map((subItem, subIndex) => (
                          <TableRow
                            key={subIndex}
                            sx={{
                              '& > *': {
                                borderBottom:
                                  subIndex === row?.subRows?.length - 1
                                    ? '1px solid #ccc'
                                    : 'unset',
                              },
                            }}
                          >
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                style={{
                                  whiteSpace: 'nowrap',
                                  ...column.style,
                                  //overflow: 'hidden',
                                  //textOverflow: 'ellipsis',
                                }}
                              >
                                {Array.isArray(subItem[column.id])
                                  ? subItem[column.id].join(', ')
                                  : subItem[column.id]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DataTable;

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useState } from 'react';

export const ActionsDropdown = ({
  actions = [],
  buttonLabel = 'Actions',
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (onClick) {
      onClick();
    }
  };

  const handleClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {buttonLabel}
      </Button>
      {actions.length ? (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={(e) => e.stopPropagation()}
          slotProps={{
            paper: {
              style: {
                borderRadius: 8,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                marginTop: '8px',
              },
            },
          }}
        >
          {actions.length
            ? actions.map((action, index) => (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (action.onClick) action.onClick();
                    handleClose();
                  }}
                  style={{ padding: '12px 24px' }}
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <Typography variant="inherit">{action.label}</Typography>
                </MenuItem>
              ))
            : null}
        </Menu>
      ) : null}
    </div>
  );
};

export default ActionsDropdown;

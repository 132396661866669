import organizationAPI from 'api/api';
import {
  CallHistoryCard,
  EmptyState,
  LoadingSystem,
  RaderChartLegend,
  SummaryInfo,
  UploadAudio,
  Widget,
} from 'components/CustomMUI';
import RadarChart from 'components/CustomMUI/RaderChart';

import { LoaderVariants } from 'components/CustomMUI/LoadingSystem';
import { useFetcher } from 'hooks/useFetcher';
import useFormattedData from 'hooks/useFormattedData';
import useNotification from 'hooks/useNotification';
import prettyMilliseconds from 'pretty-ms';
import { useEffect, useState } from 'react';
import { useAuthStore } from 'store';
import useNotificationStore from 'store/useNotificationStore';
import { PhoneIcon } from 'svg/CustomSVGIcon';
import styles from './styles/Style.module.css';
import { Box } from '@mui/material';

const Header = ({ title, children }) => {
  return (
    <div className={styles.header}>
      <div className={styles.header_container}>
        <h1>{title}</h1>
        {children}
      </div>
      <div className={styles.border2}></div>
    </div>
  );
};

const ConvoLeaderboard = () => {
  const user = useAuthStore((state) => state.loggedUser);
  const [statsData, setStatsData] = useState();
  const [callHistoryData, setCallHistoryData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [spiderChartData, setSpiderChartData] = useState([]);
  const { show, NotificationComponent } = useNotification();
  const { notification } = useNotificationStore();
  const [individualStrengths, setIndividualStrengths] = useState();
  const [individualWeaknesses, setIndividualWeaknesses] = useState();
  const [teamStrengths, setTeamStrengths] = useState();
  const [teamWeaknesses, setTeamWeaknesses] = useState();
  const { formattedNum } = useFormattedData();

  const { call: getUserStatsCall } = useFetcher({
    fetcher: organizationAPI.getUserStats,
    successMessage: 'User Stats Fetched Successfully!',
    loaderId: 'userStats',
    loaderLabel: 'Generating User Stats',
    loaderStyle: 'checklist',
  });

  const { call: getCallHistoryCall } = useFetcher({
    fetcher: organizationAPI.getCallHistory,
    loaderId: 'getCallHistory',
    loaderLabel: 'Fetching Call History',
    loaderStyle: 'checklist',
  });

  const { call: getSpiderChartCall } = useFetcher({
    fetcher: organizationAPI.getSpiderChart,
    loaderId: 'getSpiderChart',
    loaderLabel: 'Fetching Spider Chart',
    loaderStyle: 'checklist',
  });

  const { call: getIndividualPerformanceAnalyzerCall } = useFetcher({
    fetcher: organizationAPI.individualPerformanceAnalyzer,
    loaderId: 'individualPerformanceAnalyzer',
    loaderLabel: 'Fetching Individual Performance Data',
    loaderStyle: 'checklist',
  });

  const { call: getTeamPerformanceAnalyzerCall } = useFetcher({
    fetcher: organizationAPI.teamPerformanceAnalyzer,
    loaderId: 'teamPerformanceAnalyzer',
    loaderLabel: 'Fetching Team Performance Data',
    loaderStyle: 'checklist',
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredData = callHistoryData.filter(
    (item) =>
      item.team.toLowerCase().includes(searchTerm) ||
      item.persona.some((persona) =>
        persona.name.toLowerCase().includes(searchTerm)
      )
  );

  useEffect(() => {
    const fetchUserStatsResponseData = async () => {
      const response = await getUserStatsCall();
      if (response) {
        setStatsData(response.data);
      }
    };
    const fetchCallHistoryResponseData = async () => {
      const response = await getCallHistoryCall();
      if (response) {
        setCallHistoryData(response.data);
      }
    };
    const fetchSpiderChartResponseData = async () => {
      const response = await getSpiderChartCall();
      if (response) {
        setSpiderChartData(response.data);
      }
    };
    const fetchIndividualPerformanceAnalyzerResponseData = async () => {
      try {
        const response = await getIndividualPerformanceAnalyzerCall();
        if (response) {
          const responseStrengthData = {
            title: `${response.data.performanceAnalysis.type} Strengths`,
            analysis: response.data.performanceAnalysis.analysis.strengths,
          };
          const responseWeaknessData = {
            title: `${response.data.performanceAnalysis.type} Weaknesses`,
            analysis: response.data.performanceAnalysis.analysis.weaknesses,
          };
          setIndividualStrengths(responseStrengthData);
          setIndividualWeaknesses(responseWeaknessData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };
    const fetchTeamPerformanceAnalyzerResponseData = async () => {
      try {
        const response = await getTeamPerformanceAnalyzerCall();
        if (response) {
          const responseStrengthData = {
            title: `${response.data.performanceAnalysis.type} Strengths`,
            analysis: response.data.performanceAnalysis.analysis.strengths,
          };
          const responseWeaknessData = {
            title: `${response.data.performanceAnalysis.type} Weaknesses`,
            analysis: response.data.performanceAnalysis.analysis.weaknesses,
          };
          setTeamStrengths(responseStrengthData);
          setTeamWeaknesses(responseWeaknessData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };
    fetchUserStatsResponseData();
    fetchCallHistoryResponseData();
    fetchSpiderChartResponseData();
    fetchIndividualPerformanceAnalyzerResponseData();
    fetchTeamPerformanceAnalyzerResponseData();
  }, []);

  useEffect(() => {
    if (notification) {
      show(notification.message, notification.type);
    }
  }, [notification, show]);

  return (
    <>
      {/* {!notification && loading ? <LoadingOverlay></LoadingOverlay> : null} */}
      {notification ? (
        <NotificationComponent
          notification={notification.message}
          type={notification.type}
        />
      ) : null}
      <div className={styles.main}>
        <LoadingSystem
          variant={LoaderVariants.CHECKLIST}
          title={`Loading ${user.firstName} ${user.lastName}'s Data...`}
        />
        <div className={styles.content}>
          <div className={styles.navbar_container}>
            <div className={styles.navbar}>
              <h1>Dashboard </h1>
              <div className={styles.nav_right}>
                {/* <div className={styles.nav_btn1}>
                  <CustomCheckbox label={'AI Roleplay'} color={'#2aa87e'} />
                  <CustomCheckbox label={'Live Calls'} color={'#2aa87e'} />
                </div>
                <CustomDatepicker label={'Select date range'} /> */}
                <UploadAudio />
                <a className={styles.nav_btn2} href="/airoleplaycall">
                  <PhoneIcon fill={'#00706c'} />
                  <p>Start AI Roleplay Call</p>
                </a>
              </div>
            </div>
            <div className={styles.border}></div>
          </div>
          <div className={styles.container}>
            {user && (
              <Header title={`${user.firstName} ${user.lastName}: My Stats`} />
            )}
            {statsData ? (
              <Box
                sx={{
                  display: 'grid',
                  width: '100%',
                  gridTemplateColumns: {
                    xs: '1fr',
                    sm: `repeat(2, calc((100% - 24px) / 2))`,
                    md: `repeat(3, calc((100% - 48px) / 3))`,
                    lg: `repeat(4, calc((100% - 290px) / 4))`,
                  },
                  gap: {
                    xs: '24px',
                    sm: '24px',
                    md: '24px',
                    lg: '80px',
                  },
                  mx: 'auto',
                  boxSizing: 'border-box',
                  '& > *': {
                    minWidth: 0,
                    width: '100%',
                    height: '160px',
                  },
                }}
              >
                <Widget
                  title={'Total Calls Duration'}
                  info={prettyMilliseconds(statsData.totalCallDuration.value)}
                  percentage={formattedNum(
                    statsData.totalCallDuration.percentage
                  )}
                  increase={statsData.totalCallDuration.hasIncreased}
                />
                <Widget
                  title={'Total Calls'}
                  info={statsData.totalCalls.value}
                  percentage={formattedNum(statsData.totalCalls.percentage)}
                  increase={statsData.totalCalls.hasIncreased}
                />
                <Widget title={'My Team'} info={statsData.teamMembers} />
                <Widget
                  title={'Average Scorecard'}
                  info={`${statsData.averageScore.value} / ${statsData.averageMaxScore}`}
                  percentage={formattedNum(statsData.averageScore.percentage)}
                  increase={statsData.averageScore.hasIncreased}
                />
              </Box>
            ) : (
              <EmptyState />
            )}

            <Header title={'Performance Summary'} />
            <div className={styles.summary_container}>
              <div className={styles.summary_left}>
                <div className={styles.chart}>
                  {spiderChartData ? (
                    <RadarChart
                      labels={spiderChartData.labels}
                      datasets={spiderChartData.datasets}
                    />
                  ) : (
                    <EmptyState />
                  )}
                </div>

                {spiderChartData && spiderChartData.datasets && (
                  <div className={styles.summary_left_nav}>
                    <RaderChartLegend
                      color={'rgba(0, 112, 108, 1)'}
                      title={spiderChartData.datasets[0].label}
                    />
                    <RaderChartLegend
                      color={'rgba(54, 162, 235, 1)'}
                      title={spiderChartData.datasets[1].label}
                    />
                  </div>
                )}
              </div>
              {individualStrengths && teamStrengths ? (
                <div className={styles.summary_right}>
                  {individualStrengths && (
                    <SummaryInfo data={individualStrengths} />
                  )}
                  {individualWeaknesses && (
                    <SummaryInfo data={individualWeaknesses} />
                  )}
                  {teamStrengths && <SummaryInfo data={teamStrengths} />}
                  {teamWeaknesses && <SummaryInfo data={teamWeaknesses} />}
                </div>
              ) : (
                <EmptyState />
              )}
            </div>
            <Header title={'Call History'}>
              <div className={styles.nav_right}>
                {/* <SearchBar
                  handleSearch={handleSearchChange}
                  searchText={searchTerm}
                /> */}
                {/* <button className={styles.nav_btn2}>
                  <PhoneIcon fill={'#00706c'} />
                  <p>Review Call Records</p>
                </button> */}
              </div>
            </Header>

            {!searchTerm && callHistoryData ? (
              callHistoryData.map((item, index) => (
                <CallHistoryCard key={index} data={item} />
              ))
            ) : (
              <EmptyState />
            )}

            {searchTerm &&
              filteredData.map((item, index) => (
                <CallHistoryCard key={index} data={item} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConvoLeaderboard;

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import Lottie from 'react-lottie';
import { useLoadingStore } from 'store/useLoadingState';
import threeDots from './LottieJsons/ThreeDots.json';
import defaultLoader from './LottieJsons/loader.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: threeDots,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const SimpleLoader = ({
  loaderData = defaultLoader,
  title,
  height = 150,
  width = 150,
  color = '#222222FF',
  showTitle,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Fade in={true}>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        }}
        open={true}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            my: 2,
            flexDirection: 'column',
          }}
        >
          <Lottie
            options={defaultOptions}
            height={height}
            width={width}
            style={{
              margin: 0,
              paddingRight: '10px',
            }}
          />
          {showTitle && (
            <Typography variant="h6" color={color}>
              {title}
            </Typography>
          )}
        </Box>
      </Backdrop>
    </Fade>
  );
};

const ChecklistLoader = ({ items, title }) => (
  <Fade in={true}>
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={true}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 2,
          p: 4,
          minWidth: 300,
          maxWidth: '90%',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <CircularProgress size={20} />

          <Typography variant="h6" color={'#222222FF'}>
            {title}
          </Typography>
        </Box>
        <Divider />
        <List>
          {Object.entries(items).map(([id, { label, status }]) => (
            <ListItem key={id} sx={{ px: 2 }}>
              <ListItemIcon>
                {status === 'loading' && <CircularProgress size={20} />}
                {status === 'waiting' && (
                  <Box sx={{ my: 0 }}>
                    <Lottie
                      options={defaultOptions}
                      height={20}
                      width={20}
                      style={{
                        margin: 0,
                        paddingRight: '10px',
                      }}
                    />
                  </Box>
                )}
                {status === 'success' && (
                  <CheckCircleOutlineIcon sx={{ color: green[500] }} />
                )}
                {status === 'error' && (
                  <ErrorOutlineIcon sx={{ color: red[500] }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={label}
                sx={{
                  color: 'text.primary',
                  '& .MuiTypography-root': {
                    fontWeight: status === 'loading' ? 500 : 400,
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Backdrop>
  </Fade>
);

export const LoaderVariants = {
  SIMPLE: 'simple',
  CHECKLIST: 'checklist',
};

export const LoadingSystem = ({
  variant = LoaderVariants.SIMPLE,
  title = 'Loading...',
  CustomLoader = null,
  customLoaderProps = {},
  height,
  width,
  loaderData,
  color,
  showTitle = true,
  ...rest
}) => {
  const { loaders, hasActiveLoaders } = useLoadingStore();
  const hasAnyLoaders = Object.keys(loaders).length > 0;
  const isLoading =
    hasAnyLoaders && (hasActiveLoaders() || variant === LoaderVariants.SIMPLE);

  if (!isLoading) return null;

  if (CustomLoader) {
    return (
      <CustomLoader loaders={loaders} title={title} {...customLoaderProps} />
    );
  }

  return variant === LoaderVariants.SIMPLE ? (
    <SimpleLoader
      loaderData={loaderData}
      height={width}
      width={height}
      title={title}
      color={color}
      showTitle={showTitle}
    />
  ) : (
    <ChecklistLoader items={loaders} title={title} />
  );
};
export default LoadingSystem;

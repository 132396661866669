import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBackIos, ArrowForwardIos, ExitToApp } from '@mui/icons-material';
import { Button, Divider, Stack, Typography } from '@mui/material';
import apis from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { useApi } from 'hooks';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthStore, useItemStore } from 'store';
import { cleanObject, StoreKeys } from 'utils';
import { moduleInfoSchema } from './common/schema';
import { ModuleDetails } from './ModuleDetails';
import ModuleSection from './ModuleSection';
import { DynamicLoadingChecklist } from 'components/CustomMUI';

export const AddModule = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.loggedUser);

  const itemStore = useItemStore((state) => state.items);
  const updateItemStore = useItemStore((state) => state.updateItem);

  const [showPage, setShowPage] = useState('details');
  const [moduleId, setModuleId] = useState(null);
  const { call: createModule, isPending: isModuleCreating } = useApi({
    fetcher: apis.createModule,
    successMessage: 'Module created successfully',
  });

  const methods = useForm({
    resolver: yupResolver(moduleInfoSchema),
    mode: 'onChange',
  });

  const saveModuleDetails = useErrorHandler(async (data) => {
    const cleanData = cleanObject(data);
    const response = await createModule({
      data: cleanData,
      organizationId: user.organization,
      userId: user._id,
    });

    const updatedList = [...itemStore.modules, response.data];
    updateItemStore({ key: StoreKeys.MODULES, value: updatedList });

    return response;
  });

  const saveAndExit = async (data) => {
    await saveModuleDetails(data);
    navigate(-1);
  };

  const gotoSection = async (data) => {
    const response = await saveModuleDetails(data);
    const { _id: savedModuleId } = response.data;
    setModuleId(savedModuleId);
    setShowPage('section');
  };

  const Header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Module Builder</Typography>
      <Stack direction={'row'} spacing={2}>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          variant="outlined"
          startIcon={<ArrowBackIos />}
        >
          Back to list
        </Button>
        <Button
          variant="outlined"
          disabled={showPage === 'section'}
          startIcon={<ExitToApp />}
          onClick={methods.handleSubmit(saveAndExit)}
        >
          Save and Exit
        </Button>
        <Button
          variant="outlined"
          endIcon={<ArrowForwardIos />}
          onClick={() => {
            if (showPage === 'section') navigate(-1);
            else methods.handleSubmit(gotoSection)();
          }}
        >
          {showPage === 'section' ? `Next: Complete` : 'Next: Sections'}
        </Button>
      </Stack>
    </Stack>
  );
  return (
    <FormProvider {...methods}>
      {isModuleCreating ? (
        <DynamicLoadingChecklist
          isLoading={isModuleCreating}
          checklistItems={[
            {
              id: 'createModule',
              label: 'Creating Module. Please Wait...',
              isComplete: false,
            },
          ]}
          title="Processing Module Creation"
        />
      ) : null}
      <Stack direction={'column'} gap={5}>
        {Header}
        <Divider />
        {showPage === 'section' ? (
          <ModuleSection moduleId={moduleId} />
        ) : (
          <ModuleDetails />
        )}
      </Stack>
    </FormProvider>
  );
};

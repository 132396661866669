// Extract all styles to a single function that returns style objects
export const getStyles = ({ hasValues, focused }) => {
  const commonLabelStyles = {
    backgroundColor: 'white',
    padding: '0 4px',
    color: hasValues || focused ? undefined : 'rgba(0, 0, 0, 0.6)',
    '&.Mui-focused': {
      color: hasValues || focused ? undefined : 'rgba(0, 0, 0, 0.6)',
    },
  };

  const commonOutlinedInputStyles = {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: hasValues || focused ? undefined : 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: hasValues || focused ? undefined : 'rgba(0, 0, 0, 0.23)',
      borderWidth: hasValues || focused ? undefined : '1px',
    },
  };

  const chipDeleteIconStyles = {
    ':hover': { fill: 'tomato' },
  };

  const arrowDropDownStyles = {
    cursor: 'pointer',
  };

  const clearButtonAdornmentStyles = {
    mr: 4,
  };

  const menuPaperStyles = {
    maxHeight: 224,
  };

  const loadingTextStyles = {
    marginLeft: 10,
  };

  const chipContainerStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  };

  return {
    commonLabelStyles,
    commonOutlinedInputStyles,
    chipDeleteIconStyles,
    arrowDropDownStyles,
    clearButtonAdornmentStyles,
    menuPaperStyles,
    loadingTextStyles,
    chipContainerStyles,
  };
};

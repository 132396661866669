import {
  ArrowDownward,
  ArrowUpward,
  Search,
  VisibilityOutlined,
} from '@mui/icons-material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';

const FlexibleTable = ({
  data,
  columns,
  onRowAction,
  actionIcon: ActionIcon = VisibilityOutlined,
  tableStyles = {},
  initialSortKey = 'createdAt',
  initialSortDirection = 'desc',
  isSearchable = false,
  component,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortKey, setSortKey] = useState(initialSortKey);
  const [sortDirection, setSortDirection] = useState(initialSortDirection);
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const defaultStyles = {
    tableContainer: {
      borderRadius: 2,
      boxShadow: 'none',
      border: '1px solid #DAE8E7',
      ...tableStyles.tableContainer,
    },
    headerCell: {
      width: '150px',
      maxWidth: '150px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '16px',
      fontWeight: 600,
      color: '#243030',
      ...tableStyles.headerCell,
    },
    bodyCell: {
      width: '150px',
      maxWidth: '150px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '16px',
      ...tableStyles.bodyCell,
    },
    searchField: {
      marginBottom: '16px',
      width: '100%',
      maxWidth: '400px',
      ml: 'auto',
    },
  };

  const filteredData = useMemo(() => {
    if (!searchTerm.trim()) return data;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return data.filter((row) => {
      return columns.some((column) => {
        if (!column.key) return false;

        const value = row[column.key];
        if (value === null || value === undefined) return false;

        if (
          value instanceof Date ||
          (typeof value === 'string' && !isNaN(Date.parse(value)))
        ) {
          try {
            const date = new Date(value);
            const formats = [
              date.toLocaleDateString(),
              date.toDateString(),
              date.toISOString(),

              date.toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }),
              date.toLocaleString('en-US', { month: 'short', day: 'numeric' }),
            ];

            return formats.some((format) =>
              format.toLowerCase().includes(lowerCaseSearchTerm)
            );
          } catch (e) {
            return String(value).toLowerCase().includes(lowerCaseSearchTerm);
          }
        }

        const stringValue = String(value).toLowerCase();
        return stringValue.includes(lowerCaseSearchTerm);
      });
    });
  }, [data, columns, searchTerm]);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      const valueA = a[sortKey];
      const valueB = b[sortKey];

      if (sortKey === 'createdAt' || sortKey === 'dueDate') {
        return sortDirection === 'desc'
          ? new Date(valueB) - new Date(valueA)
          : new Date(valueA) - new Date(valueB);
      }

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return sortDirection === 'desc' ? valueB - valueA : valueA - valueB;
      }

      return sortDirection === 'desc'
        ? String(valueB || '').localeCompare(String(valueA || ''))
        : String(valueA || '').localeCompare(String(valueB || ''));
    });
  }, [filteredData, sortKey, sortDirection]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key) => {
    const column = columns.find((col) => col.key === key);
    if (column && column.sortable) {
      if (sortKey === key) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortKey(key);
        setSortDirection('asc');
      }
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const renderCellContent = (column, row) => {
    const value = row[column.key];

    if (column.render) {
      return column.render(value, row);
    }

    if (column.getValue) {
      return column.getValue(row);
    }

    return value;
  };

  return (
    <Box>
      <Box
        display={'flex'}
        gap={2}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        {component}
        {/* <Typography variant="h6" sx={{ color: '#2B2B2B13', fontWeight: 300 }}>
          {tableTitle}
        </Typography> */}
        {isSearchable && (
          <TextField
            placeholder="Search table..."
            value={searchTerm}
            onChange={handleSearch}
            sx={defaultStyles.searchField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
          />
        )}
      </Box>

      <TableContainer component={Paper} sx={defaultStyles.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(({ header, key, sortable, align }) => (
                <TableCell
                  key={header || 'action'}
                  sx={{
                    ...defaultStyles.headerCell,
                    cursor: sortable ? 'pointer' : 'default',
                  }}
                  onClick={() => sortable && handleSort(key)}
                  onMouseEnter={() => setHoveredColumn(key)}
                  onMouseLeave={() => setHoveredColumn(null)}
                  align={align ? align : 'left'}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent:
                        align === 'center'
                          ? 'center'
                          : align === 'right'
                          ? 'flex-end'
                          : 'space-between',
                      textAlign: align || 'left',
                    }}
                  >
                    {header}
                    {sortable && (hoveredColumn === key || sortKey === key) && (
                      <Box
                        sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}
                      >
                        {sortKey === key && sortDirection === 'asc' && (
                          <ArrowUpward
                            sx={{
                              fontSize: 14,
                              color:
                                sortKey === key && sortDirection === 'asc'
                                  ? 'primary.main'
                                  : 'text.disabled',
                            }}
                          />
                        )}
                        {sortKey === key && sortDirection === 'desc' && (
                          <ArrowDownward
                            sx={{
                              fontSize: 14,
                              color:
                                sortKey === key && sortDirection === 'desc'
                                  ? 'primary.main'
                                  : 'text.disabled',
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row) => (
              <TableRow key={row.id || row._id}>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.key || 'action'}
                    sx={{ ...defaultStyles.bodyCell }}
                    align={column.align ? column.align : 'left'}
                  >
                    {column.key ? (
                      renderCellContent(column, row)
                    ) : onRowAction ? (
                      <IconButton onClick={() => onRowAction(row)}>
                        <ActionIcon sx={{ color: 'rgb(0, 128, 128)' }} />
                      </IconButton>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {paginatedData.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align="center"
                  sx={{ py: 4 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    {searchTerm
                      ? 'No results match your search'
                      : 'No data available'}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {sortedData.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '8px 16px',
              backgroundColor: '#F8F9FA',
              borderRadius: '8px',
              margin: '16px 0',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Displaying {page * rowsPerPage + 1}-
              {Math.min((page + 1) * rowsPerPage, sortedData.length)} of{' '}
              {sortedData.length} entries
              {searchTerm && filteredData.length !== data.length && (
                <> (filtered from {data.length} total entries)</>
              )}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <IconButton
                  size="small"
                  onClick={() => handleChangePage(page - 1)}
                  disabled={page === 0}
                  sx={{ p: 0.5 }}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <Typography variant="body2" color="text.secondary">
                  Page {page + 1} of{' '}
                  {Math.max(1, Math.ceil(sortedData.length / rowsPerPage))}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => handleChangePage(page + 1)}
                  disabled={
                    page >= Math.ceil(sortedData.length / rowsPerPage) - 1
                  }
                  sx={{ p: 0.5 }}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Entries per Page
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {[10, 25, 50, 100].map((value) => (
                  <Box
                    key={value}
                    onClick={() =>
                      handleChangeRowsPerPage({ target: { value } })
                    }
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      gap: 0.5,
                    }}
                  >
                    <input
                      type="radio"
                      checked={rowsPerPage === value}
                      onChange={() => {}}
                      id={`rows-${value}`}
                    />
                    <Typography
                      component="label"
                      htmlFor={`rows-${value}`}
                      variant="body2"
                      sx={{ cursor: 'pointer' }}
                    >
                      {value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default FlexibleTable;

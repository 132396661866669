import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from '@mui/material';
import { format } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { forwardRef, useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FORMAT_STRING } from 'utils/date';

export const DatePicker = forwardRef(
  (
    {
      onDateChange,
      value,
      label = '',
      onClear,
      width = '100%',
      size = 'small',
      disabled,
      sx,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
      if (value) {
        setSelectedDate(new Date(value));
      } else {
        setSelectedDate(null);
      }
    }, [value]);

    const resetDate = (e) => {
      e.stopPropagation();
      setSelectedDate(null);
      if (onDateChange) onDateChange(null);
      if (onClear) onClear();
    };

    const handleSelect = (date) => {
      setSelectedDate(date);
      if (onDateChange) {
        const isoDate = date.toISOString();
        onDateChange(isoDate);
      }
      handleClose(); // close popover on selection
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'date-picker-popover' : undefined;

    return (
      <Box>
        <TextField
          ref={ref}
          size={size}
          label={label}
          placeholder={label}
          onClick={handleClick}
          value={selectedDate ? format(selectedDate, FORMAT_STRING) : ''}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {selectedDate ? (
                  <IconButton size="small" onClick={resetDate}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <IconButton size="small" onClick={handleClick}>
                    <CalendarTodayIcon fontSize="small" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            width: width,
            ...sx,
          }}
          disabled={disabled}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Calendar
            date={selectedDate || new Date()}
            onChange={handleSelect}
            locale={enUS}
          />
        </Popover>
      </Box>
    );
  }
);

export default DatePicker;

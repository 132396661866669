import {
  alpha,
  darken,
  lighten,
  StepConnector,
  stepConnectorClasses,
  styled,
} from '@mui/material';

export const getStepperStyles = () => {
  const CustomConnector = styled(StepConnector)(({ theme }) => {
    const gradientStartColor = theme.palette.primary.main;
    const gradientEndColor = alpha(gradientStartColor, 0.7);
    return {
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
      },
      [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundImage: `linear-gradient(95deg, ${gradientStartColor} 0%, ${gradientEndColor} 100%)`,
        },
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundImage: `linear-gradient(95deg,  ${gradientEndColor} 0%, ${gradientEndColor}100%)`,
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
      },
    };
  });

  const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => {
    const gradientStartColor = lighten(theme.palette.primary.main, 0.5);
    const gradientEndColor = darken(theme.palette.primary.main, 0.1);
    return {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 40,
      height: 40,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: ownerState.completed ? 'pointer' : 'default',
      ...(ownerState.active && {
        backgroundImage: `linear-gradient(136deg, ${gradientStartColor} 0%, ${gradientEndColor} 100%)`,
        boxShadow: '0 4px 10px 0 rgba(44, 49, 46, 0.56)',
        border: `2px solid ${theme.palette.text.highlight}`,
      }),
      ...(ownerState.completed && {
        backgroundImage: `linear-gradient(136deg, ${gradientStartColor} 0%, ${gradientEndColor} 100%)`,
        '&:hover': {
          opacity: 0.8,
        },
      }),
    };
  });

  const stepperButtonContainer = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    p: 2,
    background: (theme) => theme.palette.background.paper,
    zIndex: 1,
  };

  const stepperContainer = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  };

  return {
    CustomConnector,
    CustomStepIconRoot,
    stepperContainer,
    stepperButtonContainer,
  };
};

import { yupResolver } from '@hookform/resolvers/yup';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Stack, Typography } from '@mui/material';
import LoadingOverlay from 'components/LoadingOverlay';
import { useDuplicateEmailChecker } from 'hooks/useDuplicateEmailCheck';
import { useUserContext } from 'pages/Settings/context';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { colorPalette } from 'theme/colors';
import { FormField } from '../common';
import { DisabledRoleOptions } from '../common/components/DisabledRoleOptions';
import { addPartner } from './validation/schema';
const blankPartnerInfo = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  companyType: '',
  role: 'PARTNER',
  course: '',
  region: '',
};

export const PartnerAdd = () => {
  const [userInfo, setUserInfo] = useState(blankPartnerInfo);
  const { post, isPosting } = useUserContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(addPartner),
    mode: 'onChange',
    defaultValues: { role: 'PARTNER' },
  });

  const { checkEmail, isChecking: isEmailChecking } = useDuplicateEmailChecker({
    setError,
  });

  const partnerAdd = async (data) => {
    await post({
      ...data,
      companyName: 'ABC company',
      companyType: 'XYZ type',
    });
    reset();
  };

  const renderFormFields = () => {
    const fields = [
      [
        { name: 'firstName', label: 'First Name', type: 'text' },
        { name: 'lastName', label: 'Last Name', type: 'text' },
        { name: 'email', label: 'Email', type: 'text' },
      ],
      [
        { name: 'companyName', label: 'Company Name', type: 'text' },
        { name: 'companyType', label: 'Company Type', type: 'text' },
      ],
    ];

    const renderStack = (fieldGroup) =>
      fieldGroup.map((field) => (
        <FormField
          loading={field.name === 'email' && isEmailChecking}
          formType="ADD"
          key={field.name}
          field={field}
          register={register}
          errors={errors}
          watch={watch}
          state={userInfo}
          stateUpdater={setUserInfo}
          hookFormUpdater={setValue}
          hookFormTrigger={trigger}
          onChange={(e) => {
            if (field.name === 'email') {
              checkEmail({ email: e.target.value });
            }
          }}
          sx={{ width: '100%' }}
        />
      ));

    return {
      firstStack: renderStack(fields[0]),
      secondStack: renderStack(fields[1]),
    };
  };

  return (
    <Box
      sx={{
        background: colorPalette.background.paper,
        px: 2,
        py: 4,
        borderRadius: 2,
      }}
      component="form"
      onSubmit={handleSubmit(partnerAdd)}
    >
      {isPosting && <LoadingOverlay />}
      <Stack direction="column" spacing={4}>
        <Typography variant="h6">Invite Personnel</Typography>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {renderFormFields().firstStack}
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DisabledRoleOptions selectedRole={'PARTNER'} />
          {renderFormFields().secondStack}
          <Button
            variant="contained"
            color="success"
            startIcon={<SendIcon />}
            type="submit"
            disabled={isEmailChecking || !!errors.email}
          >
            Send Invite
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export const colorPalette = {
  primary: {
    main: '#29A87E', //'#4c12a1',
  },
  secondary: {
    main: '#4c12a1',
  },
  success: {
    main: '#29A87E',
  },
  background: {
    paper: '#FFFFFF',
  },
  text: {
    hint: '#40806A',
    highlight: '#adff2f',
  },
  border: {
    main: '#DAE8E7',
  },
};

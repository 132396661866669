import { Add, Edit, RemoveCircleOutline, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { apis } from 'api/api';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import { ActionsDropdown } from 'components/Table/TableActions';
import { formatDuration } from 'date-fns';
import { useApi } from 'hooks';
import { useTableSearch } from 'pages/Settings/context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { useDialogStore, useItemStore } from 'store/useStore';
import { StoreKeys } from 'utils';
import { getOriginalZonedDate } from 'utils/date';
import { DataTable } from '../../components/Table/Table';
import { TableSkeleton } from '../../components/Table/TableSkeleton';
import { colorPalette } from '../../theme/colors';
import { stepMapper } from './common/const';
// import { EditDialog } from './common/_EditDialog';

const ModuleListTableColumns = [
  { id: 'title', label: 'Name' },
  { id: 'partnerName', label: 'Partner' },
  { id: 'totalNumberOfMeetings * numberOfHoursPerMeeting', label: 'Duration' },
  // { id: 'formatOfInstruction', label: 'Format' },
  // { id: 'supportStructure', label: 'Support' },
  { id: 'language', label: 'Language' },
  { id: 'location', label: 'Location' },
  // { id: 'createdAt', label: 'Created At' },
  { id: 'status', label: 'Status' },
  { id: 'actions', label: 'Actions' },
];
// export const JourneyEditDialogOpenKey = 'journeyDetails';
export const JourneyList = () => {
  const user = useAuthStore((state) => state.loggedUser);
  const setDeleteDialog = useDialogStore((state) => state.setDeleteDialog);

  const itemStore = useItemStore((state) => state.items);
  const addItemToStore = useItemStore((state) => state.addItem);
  const updateItemStore = useItemStore((state) => state.updateItem);

  const navigate = useNavigate();
  const { call: fetchJourneys, isLoading: isFetching } = useApi({
    fetcher: () => {
      return apis.getJourneyList({ organizationId: user.organization });
    },
    onSuccess: (updatedData) => {
      addItemToStore({ key: StoreKeys.JOURNEYS, value: updatedData });
    },
    queryKey: 'getJourneyList',
  });

  const { call: deleteJourney } = useApi({
    fetcher: apis.deleteJourney,
    successMessage: 'Journey deleted successfully',
  });

  useEffect(() => {
    fetchJourneys();
  }, []);

  const searchFields = ['title', 'partnerName'];

  const { setSearchTerm, filteredData } = useTableSearch({
    data: itemStore[StoreKeys.JOURNEYS],
    searchFields,
    filter: (item) => item.status !== 'archive',
  });

  const formatTotalDuration = ({ totalHours }) => {
    if (!totalHours) return 'N/A'; // Handle zero case explicitly

    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);

    return formatDuration({ hours, minutes });
  };

  const actions = (data) => {
    return [
      {
        label:
          data.status === stepMapper.complete.name
            ? 'View Details'
            : 'Edit Details',
        icon: <Edit color="success" />,
        onClick: () => {
          navigate(`/journey/${data._id}`);
        },
      },
      {
        label: 'Remove Journey',
        icon: <RemoveCircleOutline color="error" />,
        onClick: () =>
          setDeleteDialog({
            isOpen: true,
            forItem: 'Journey',
            onOk: async () => {
              await deleteJourney({
                journeyId: data._id,
              });

              const updatedList = itemStore[StoreKeys.JOURNEYS].filter(
                (journey) => journey._id !== data._id
              );
              updateItemStore({
                key: StoreKeys.JOURNEYS,
                value: updatedList,
              });
            },
          }),
      },
    ];
  };

  const handleRowClick = (journeyId) => {
    navigate(`/journey/${journeyId}`);
  };

  const adminSearchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  const header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Journeys</Typography>
    </Stack>
  );

  return (
    <Stack direction={'column'} gap={5}>
      {header}
      <Divider />
      <Box
        sx={{
          background: colorPalette.background.paper,
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Stack direction="column" spacing={5}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                navigate('/journey/create');
              }}
              variant="contained"
              startIcon={<Add />}
            >
              New Journey
            </Button>
            {adminSearchBar}
          </Stack>
          {isFetching ? (
            <TableSkeleton column={ModuleListTableColumns} />
          ) : (
            <DataTable
              columns={ModuleListTableColumns}
              sort={true}
              orderBy="createdAt"
              order="desc"
              data={filteredData?.map((item) => {
                const totalHours =
                  (item.totalNumberOfMeetings || 0) *
                  (item.numberOfHoursPerMeeting || 0);
                const formattedDuration = formatTotalDuration({ totalHours });

                return {
                  ...item,
                  actions: <ActionsDropdown actions={actions(item)} />,
                  name: `${item.firstName} ${item.lastName}`,
                  createdAt: getOriginalZonedDate({
                    date: item.createdAt ?? null,
                  }),
                  'totalNumberOfMeetings * numberOfHoursPerMeeting':
                    formattedDuration,
                  status: (
                    <Chip
                      label={
                        String(item.status).toLowerCase() === 'complete'
                          ? 'completed'
                          : item.status
                      }
                      variant="outlined"
                      size="small"
                      color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                      sx={{ textTransform: 'capitalize' }}
                    />
                  ),
                };
              })}
              onRowClick={(row) => handleRowClick(row._id)}
              rowStyle={{ cursor: 'pointer' }}
            />
          )}
        </Stack>
      </Box>
      <DeleteDialog />
      {/* <EditDialog forItem={JourneyEditDialogOpenKey} /> */}
    </Stack>
  );
};

import { Backdrop, Box, TextField, Typography } from '@mui/material';
import organizationAPI from 'api/api';
import Stepper from 'components/Stepper/Stepper';
import { useFetcher } from 'hooks/useFetcher';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useAuthStore } from 'store';
import loaderData from './loader.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const MyCustomLoader = () => (
  <Backdrop open={true}>
    <Box>
      <Lottie
        options={defaultOptions}
        height={100}
        width={100}
        style={{
          margin: 0,
        }}
      />
    </Box>
  </Backdrop>
);

const Test = () => {
  const user = useAuthStore((state) => state.loggedUser);
  const { call: getUserStatsCall } = useFetcher({
    fetcher: organizationAPI.getUserStats,
    successMessage: 'User Stats Fetched Successfully!',
    loaderId: 'userStats',
    loaderLabel: 'Generating User Stats',
    loaderStyle: 'checklist',
  });

  const { call: getCallHistoryCall } = useFetcher({
    fetcher: organizationAPI.getCallHistory,
    loaderId: 'callHistory',
    loaderLabel: 'Fetching Call History',
    loaderStyle: 'checklist',
  });

  const { call: getSpiderChartCall, data } = useFetcher({
    fetcher: organizationAPI.getSpiderChart,
    loaderId: 'spiderChart',
    loaderLabel: 'Generating Spider Chart',
    loaderStyle: 'checklist',
    cache: true,
    cacheKey: 'userStats',
  });

  useEffect(() => {
    getUserStatsCall();
    getCallHistoryCall();
    getSpiderChartCall();
  }, []);
  console.log(data);

  const steps = [
    {
      label: 'Personal Information',
      description: 'Enter your name and email',
      optional: 'Required',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <TextField label="Full Name" fullWidth required />
          <TextField label="Email" type="email" fullWidth required />
        </Box>
      ),
    },
    {
      label: 'Address',
      description: 'Enter your address details',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <TextField label="Address" fullWidth multiline rows={3} />
        </Box>
      ),
    },
    {
      label: 'Review',
      description: 'Review your information',
      content: (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Name</Typography>
          <Typography variant="subtitle1">Email</Typography>
          <Typography variant="subtitle1">Address</Typography>
        </Box>
      ),
    },
    {
      label: 'Review',
      description: 'Review your information',
      content: (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Name</Typography>
          <Typography variant="subtitle1">Email</Typography>
          <Typography variant="subtitle1">Address</Typography>
        </Box>
      ),
    },
  ];

  return (
    <>
      {/* <LoadingSystem
        style="simple"
        title={`Loading ${user.firstName} ${user.lastName}'s Data...`}
        loaderData={loaderData}
        height={150}
        width={150}
        color={'#00D39EFF'}
      /> */}
      {/* <LoadingSystem
        CustomLoader={MyCustomLoader}
        customLoaderProps={{ customProp: 'Custom Value' }}
        title="Custom Loading..."
      /> */}

      <Stepper
        steps={steps}
        activeStep={0}
        // setActiveStep={setActiveStep}
        orientation="horizontal"
        // onFinish={handleFinish}
        nextButtonText="Continue"
        finishButtonText="Submit"
      />
    </>
  );
};

export default Test;

import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { theme } from 'theme/muiTheme';
import { SnackbarProvider } from './SnackbarContext';
import { UserProvider } from './UserContext';
const queryClient = new QueryClient();
const AppContextProvider = ({ children }) => {
  const isLocalhost = window.location.hostname === 'localhost';
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>{children}</SnackbarProvider>
        </ThemeProvider>
        {isLocalhost && <ReactQueryDevtools initialIsOpen={false} />}
      </UserProvider>
    </QueryClientProvider>
  );
};

export default AppContextProvider;

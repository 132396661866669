import axios from 'axios';
import { getBaseUrl } from './config';

const baseUrl = getBaseUrl();

const baseApi = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: function () {
    return true;
  },
});

baseApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

baseApi.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      if (response.data.error) {
        return Promise.reject(new Error(response.data.error));
      }
      return response;
    }

    const userInLoginPage = window.location.pathname.includes('/login');
    if (response.status === 401 && !userInLoginPage) {
      console.error('401 error occured');
      window.location.href = changeURLToLogin(window.location);
      return Promise.reject(new Error('Unauthorized'));
    }

    return Promise.reject({
      status: response.status,
      message: response.data.message || 'An error occurred',
      data: response.data,
    });
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;

      if (status >= 400 && status < 500) {
        console.error('Client error:', error.response.data.message);
      } else if (status >= 500) {
        console.error('Server error:', error.response.data.message);
      }
    } else if (error.request) {
      console.error('Network error or no response received.');
    } else {
      console.error('Error in request setup:', error.message);
    }
    return Promise.reject(error);
  }
);

function changeURLToLogin(url) {
  const urlObj = new URL(url);
  urlObj.pathname = '/';
  return urlObj.toString();
}

// Save the organizationCode to localStorage
const saveOrganizationCodeToLocalStorage = async (organizationCode) => {
  await localStorage.setItem('organizationCode', organizationCode);
};

// Retrieve the organizationCode from localStorage
const getOrganizationCodeFromLocalStorage = () => {
  return localStorage.getItem('organizationCode');
};

// Save the token to localStorage
const saveTokenToLocalStorage = (token) => {
  localStorage.setItem('jwtToken', token);
};

// Retrieve the token from localStorage
const getTokenFromLocalStorage = () => {
  return localStorage.getItem('jwtToken');
};

// Save the token to localStorage
const saveCoachIDToLocalStorage = (id) => {
  localStorage.setItem('defaultCoachId', id);
};

// Retrieve the token from localStorage
const getCoachIDToLocalStorage = () => {
  return localStorage.getItem('defaultCoachId');
};

// Remove the token from localStorage
const removeTokenFromLocalStorage = () => {
  localStorage.removeItem('jwtToken');
};

const getUserProfile = async () => {
  try {
    const response = await baseApi.get('/api/v1/auth/me');
    if (response.data.data.type === 'pg') {
      saveCoachIDToLocalStorage('648e67ba10ee9307c26f87ab');
    } else if (response.data.data.type === 'elevate') {
      saveCoachIDToLocalStorage('648de163f0448cac872de27d');
    } else {
      console.log('invalid type');
    }

    return response;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

const getCoachProfile = async () => {
  try {
    const response = await baseApi.get('/api/v1/auth/coach/me');
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

const userLogin = async (data) => {
  const response = await baseApi.post(`/api/v1/auth/login`, data);
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const userSignup = async (data) => {
  const response = await baseApi.post(`/api/v1/auth/register`, data);
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const submitQuestion = async (data) => {
  const response = await baseApi.post(`/api/v1/coach/askCoach`, data);

  return response;
};

const submitFeedback = async (data) => {
  const response = await baseApi.post(`/api/v1/coach/giveFeedback`, data);

  return response;
};

const coachLogin = async (data) => {
  const response = await baseApi.post(`/api/v1/auth/coach/login`, data);
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const getUserQuestions = async () => {
  try {
    const response = await baseApi.get('/api/v1/coach/getUserQuestions');

    // Handle the response data
    const userData = response.data;
    return userData;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

const getCoachQuestions = async () => {
  try {
    const response = await baseApi.get(
      '/api/v1/coach/getAllCoachQuestions',
      {}
    );

    // Handle the response data
    const userData = response.data;
    return userData;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

const organizationUserLogin = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/user/login`, data);
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const organizationUserRegistration = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/user/register`,
    data
  );
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const organizationUserForget = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/user/forget`, data);

  return response;
};

const organizationUserReset = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/user/reset`, data);

  return response;
};

const googleSignup = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/user`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  saveTokenToLocalStorage(response.data.token);

  return response;
};

const inviteUser = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/invitation`, data);

  return response;
};

const getUsersList = async (organizationId, role) => {
  if (role) {
    const response = await baseApi.get(
      `/api/v1/organization/users/${organizationId}?role=${role}`,
      {}
    );

    return response;
  }

  const response = await baseApi.get(
    `/api/v1/organization/users/${organizationId}`
  );

  return response;
};

const checkDuplicateEmail = async ({ email }) => {
  const response = await baseApi.get(
    `/api/v1/organization/duplicate-email-check/${email}`
  );
  return response;
};

const getUser = async () => {
  const response = await baseApi.get(`/api/v1/organization/me`);

  return response;
};

const updateAssignees = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/users/assignee`,
    data
  );

  return response;
};

const deleteUser = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/user/delete`, data);

  return response;
};

const updateUser = async ({ userId, organizationId, data }) => {
  const response = await baseApi.post(
    `/api/v1/organization/user/update/${userId}`,
    data,
    {
      headers: {
        organizationId,
      },
    }
  );

  return response;
};

const resendInvitation = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/invitation/resend`,
    data
  );

  return response;
};

const revokeInvitation = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/invitation/revoke`,
    data
  );

  return response;
};

const askQuestion = async (data) => {
  const response = await baseApi.post(`/api/v1/coach/ask`, data);

  return response;
};

const getQuestion = async (organization_id, userId) => {
  const response = await baseApi.get(
    `/api/v1/organization/users/userQuestions/${organization_id}/${userId}`
  );

  return response;
};

const updateStar = async (data) => {
  const response = await baseApi.post(`/api/v1/coach/updateStarredFlag`, data);

  return response;
};

const updateQuestionClose = async (data) => {
  const response = await baseApi.post(
    `/api/v1/coach/updateClosedQuestionFlag`,
    data
  );

  return response;
};

const getMe = async () => {
  const response = await baseApi.get(`/api/v1/organization/me`);

  return response;
};

const getCoachQuestion = async (organization_id, userId) => {
  const response = await baseApi.get(
    `/api/v1/organization/users/coachQuestions/${organization_id}/${userId}`
  );

  return response;
};

const coachFeedback = async (data) => {
  const response = await baseApi.post(`/api/v1/coach/giveFeedback`, data);

  return response;
};

/////////////////////// CANVAS API

const createCanvas = async (data) => {
  const response = await baseApi.post(`/api/v1/canvas`, data);

  return response;
};

const getAllCanvas = async (userId) => {
  const response = await baseApi.get(`/api/v1/canvas/user/${userId}`);

  return response;
};

const getIndividualCanvas = async (canvasId) => {
  const response = await baseApi.get(`/api/v1/canvas/${canvasId}`);

  return response;
};

const addCanvasWorkflowItem = async (data, canvasId) => {
  const response = await baseApi.post(
    `/api/v1/canvas/addWorkflowItem/${canvasId}`,
    data
  );

  return response;
};

const updateCanvasWorkflowItem = async (data, canvasId, workflowId) => {
  const response = await baseApi.put(
    `/api/v1/canvas/updateWorkflowItem/${canvasId}/${workflowId}`,
    data
  );

  return response;
};

const deleteCanvasWorkflowItem = async (canvasId, workflowId) => {
  const response = await baseApi.delete(
    `/api/v1/canvas/deleteWorkflowItem/${canvasId}/${workflowId}`
  );

  return response;
};

const submitDraft = async (data) => {
  const response = await baseApi.post(
    `/api/v1/grading/askLeanCanvasAIForGrading`,
    data
  );

  return response;
};

const addCommentToCanvas = async (data, canvasId) => {
  const response = await baseApi.post(
    `/api/v1/canvas/comment/${canvasId}`,
    data
  );

  return response;
};

const getAllCanvasForCoach = async (query = {}) => {
  const queryParams = new URLSearchParams(query).toString();
  const url = `/api/v1/canvas/coach/all${queryParams ? `?${queryParams}` : ''}`;
  const response = await baseApi.get(url);
  return response;
};

const getIndividualCanvasForCoach = async (canvasId) => {
  const response = await baseApi.get(
    `/api/v1/canvas/coach/all?canvasId=${canvasId}`
  );

  return response;
};

const getGradingAIForIndividualCanvas = async (canvasId) => {
  const response = await baseApi.get(`/api/v1/grading/canvas/${canvasId}`);

  return response;
};

const updateCanvasTile = async (data) => {
  const response = await baseApi.post(`/api/v1/grading/updateCanvasTile`, data);

  return response;
};

const updateCanvasGradingStatus = async (data) => {
  const response = await baseApi.post(
    `/api/v1/grading/updateCanvasGradingStatus`,
    data
  );

  return response;
};

const updateCanvasStatus = async (canvasId, status) => {
  const response = await baseApi.put(
    `/api/v1/canvas/updateStatus/${canvasId}/${status}`
  );

  return response;
};

const getAllCanvasStudent = async (canvasId, status) => {
  const response = await baseApi.get(`/api/v1/canvas/students/all`);

  return response;
};

const addCollaboratorToCanvas = async (data, canvasId) => {
  const response = await baseApi.post(
    `/api/v1/canvas/addMembers/${canvasId}`,
    data
  );

  return response;
};

const deleteCollaboratorToCanvas = async (canvasId, userId) => {
  const response = await baseApi.delete(
    `/api/v1/canvas/deleteMember/${canvasId}/${userId}`
  );

  return response;
};

const bulkInvitee = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/invitation/bulk`,
    data
  );

  return response;
};

const getAllCourses = async () => {
  const response = await baseApi.get(`/api/v1/organization/course`);

  return response;
};

const createCourse = async (data) => {
  const response = await baseApi.post(`/api/v1/organization/course`, data);

  return response;
};

const updateCourse = async (data) => {
  const response = await baseApi.put(`/api/v1/organization/course`, data);

  return response;
};

const deleteCourse = async (courseId) => {
  const response = await baseApi.delete(
    `/api/v1/organization/course/${courseId}`
  );

  return response;
};

const getStatistics = async (data) => {
  const response = await baseApi.post(
    `/api/v1/statistics/filter/summary`,
    data
  );

  return response;
};

const createAdmissionTemplate = async (data) => {
  const response = await baseApi.post(`/api/v1/admissionTemplate`, data);

  return response;
};

const getAllAdmissionTemplates = async () => {
  const response = await baseApi.get(`/api/v1/admissionTemplate/`);

  return response;
};

const getAdmissionTemplateById = async (admissionId) => {
  const response = await baseApi.get(
    `/api/v1/admissionTemplate/${admissionId}`
  );

  return response;
};

const getAdmissionTemplateByUser = async () => {
  const response = await baseApi.get(
    `/api/v1/admissionTemplate/user/templates`
  );

  return response;
};

const addAdmissionTemplateAcademicInterest = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/academicInterest`,
    data
  );

  return response;
};

const addAdmissionTemplateExtracurricularActivities = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/extracurricularActivity`,
    data
  );

  return response;
};

const addAdmissionTemplatePersonalExperiences = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/personalExperiences`,
    data
  );

  return response;
};

const addAdmissionTemplateFutureGoals = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/futureGoals`,
    data
  );

  return response;
};

const addAdmissionTemplateSpecificInterest = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/specificInterest`,
    data
  );

  return response;
};

const addAdmissionTemplatEssayTopic = async (data) => {
  const response = await baseApi.post(
    `/api/v1/admissionTemplate/essayTopic`,
    data
  );

  return response;
};

const submitEssay = async (data) => {
  const response = await baseApi.post(`/api/v1/essay/evaluateEssay`, data);

  return response;
};

const getEssayById = async (essayId) => {
  const response = await baseApi.get(
    `/api/v1/essay/getEssayByTemplate/${essayId}`
  );

  return response;
};

const updateEssayImprovementSuggestions = async (essayId, data) => {
  const response = await baseApi.put(
    `/api/v1/essay/updateEssayImprovementSuggestions/${essayId}`,
    data
  );

  return response;
};

const updateCollegeDetails = async (essayId, data) => {
  const response = await baseApi.put(
    `/api/v1/essay/updateCollegeDetails/${essayId}`,
    data
  );

  return response;
};

const updateEssayRemarks = async (essayId, data) => {
  const response = await baseApi.put(
    `/api/v1/essay/updateEssayRemarks/${essayId}`,
    data
  );

  return response;
};

const updateGrade = async (essayId, data) => {
  const response = await baseApi.put(
    `/api/v1/essay/updateGrade/${essayId}`,
    data
  );

  return response;
};

const getSummaryByCanvasId = async (canvasId) => {
  const response = await baseApi.get(`/api/v1/summarizeAI/canvas/${canvasId}`);

  return response;
};

const createSummary = async (data) => {
  const response = await baseApi.post(`/api/v1/summarizeAI`, data);

  return response;
};

const getTeacherAIDocument = async () => {
  const response = await baseApi.get(`/api/v1/teacherAi`);

  return response;
};

const deleteTeacherAIDocumentFile = async (documentId, fileId) => {
  const response = await baseApi.delete(
    `/api/v1/teacherAi/deleteFile/${documentId}/${fileId}`
  );

  return response;
};

const updateTeacherAIDocumentFileStatus = async (data) => {
  const response = await baseApi.post(
    `/api/v1/teacherAi/updateFileStatus`,
    data
  );

  return response;
};

const uploadTeacherAI = async (formData) => {
  const response = await baseApi.post(
    `/api/v1/teacherAi/uploadKnowledge`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getTeacherAIChat = async () => {
  const response = await baseApi.get(`/api/v1/teacherAi/getChat`);

  return response;
};

const createTeacherAIChat = async (data) => {
  const response = await baseApi.post(`/api/v1/teacherAi/createChat`, data);

  return response;
};

const renameTeacherAIChat = async (data) => {
  const response = await baseApi.put(`/api/v1/teacherAi/renameChat`, data);

  return response;
};

const deleteTeacherAIChat = async (chatId) => {
  const response = await baseApi.delete(
    `/api/v1/teacherAi/deleteChat/${chatId}`
  );

  return response;
};

const documentAnalyzerUploadDocument = async (data) => {
  const response = await baseApi.post(
    `/api/v1/documentAnalyzer/uploadDocument`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const getDocumentAnalyzeByUser = async () => {
  const response = await baseApi.get(
    `/api/v1/documentAnalyzer/getDocumentsByUser`
  );

  return response;
};

const getDocumentAnalyzeByOrganization = async () => {
  const response = await baseApi.get(
    `/api/v1/documentAnalyzer/getDocumentsByOrganization`
  );

  return response;
};

const getDocumentAnalyzeById = async (documentId) => {
  const response = await baseApi.get(
    `/api/v1/documentAnalyzer/document/${documentId}`
  );

  return response;
};

const deleteDocumentAnalayzeById = async (documentId) => {
  const response = await baseApi.delete(
    `/api/v1/documentAnalyzer/document/${documentId}`
  );

  return response;
};

const addCommentToDocumentAnalyze = async (data, documentId) => {
  const response = await baseApi.post(
    `/api/v1/documentAnalyzer/comment/${documentId}`,
    data
  );

  return response;
};

const updateDocumentAnalyzeStatusById = async (documentId, data) => {
  const response = await baseApi.put(
    `/api/v1/documentAnalyzer/status/${documentId}/`,
    data
  );

  return response;
};

const bulkActionDocumentAnalyze = async (data) => {
  const response = await baseApi.put(
    `/api/v1/documentAnalyzer/bulkUpdateStatus`,
    data
  );

  return response;
};

const getOrganizationMetaByOrganizationMetaName = async (
  organizationMetaName
) => {
  const response = await baseApi.get(
    `/api/v1/organizationMeta/${organizationMetaName}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response;
};

const getOrganizationMetaByRole = async (organizationName) => {
  const response = await baseApi.get(
    `/api/v1/organizationMeta/organizationMetaDetails/${organizationName}`
  );

  return response;
};

const getAcceptedUser = async (organization_id) => {
  const response = await baseApi.get(
    `/api/v1/organization/users/accepted/${organization_id}`
  );

  return response;
};

const updateOwnPassword = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/user/update-password`,
    data
  );

  return response;
};

const updateUserPassword = async (data) => {
  const response = await baseApi.post(
    `/api/v1/organization/user/update-password/admin`,
    data
  );

  return response;
};

const getResumeRuleId = async () => {
  const response = await baseApi.get(
    `/api/v1/documentAnalyzer/getResumeRuleByOrganization`
  );

  return response;
};

const getAssessment = async () => {
  const response = await baseApi.get(`/api/v1/assessment`);

  return response;
};

const getAssessmentId = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/getAssessment/${assessmentId}`
  );

  return response;
};

const createAssessment = async (data) => {
  const response = await baseApi.post(`/api/v1/assessment`, data);

  return response;
};

const updateAssessment = async (assessmentId, data) => {
  const response = await baseApi.put(
    `/api/v1/assessment/${assessmentId}`,
    data
  );

  return response;
};

const deleteAssessment = async (assessmentId) => {
  const response = await baseApi.delete(`/api/v1/assessment/${assessmentId}`);

  return response;
};

const createAssessmentSection = async (assessmentId, data) => {
  const response = await baseApi.post(
    `/api/v1/assessment/${assessmentId}/section`,
    data
  );

  return response;
};

const createAssessmentQuestion = async (assessmentId, sectionId, data) => {
  const response = await baseApi.post(
    `/api/v1/assessment/${assessmentId}/question/${sectionId}`,
    data
  );

  return response;
};

const updateAssessmentSection = async (assessmentId, sectionId, data) => {
  const response = await baseApi.put(
    `/api/v1/assessment/${assessmentId}/section/${sectionId}`,
    data
  );

  return response;
};

const updateAssessmentQuestion = async (
  assessmentId,
  sectionId,
  questionId,
  data
) => {
  const response = await baseApi.put(
    `/api/v1/assessment/${assessmentId}/question/${sectionId}/${questionId}`,
    data
  );

  return response;
};

const deleteAssessmentSection = async (assessmentId, sectionId) => {
  const response = await baseApi.delete(
    `/api/v1/assessment/${assessmentId}/section/${sectionId}`
  );

  return response;
};

const deleteAssessmentQuestion = async (
  assessmentId,
  sectionId,
  questionId
) => {
  const response = await baseApi.delete(
    `/api/v1/assessment/${assessmentId}/question/${sectionId}/${questionId}`
  );

  return response;
};

const getAssessmentConditionSummary = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/conditions/${assessmentId}`
  );

  return response;
};

const getAssessmentGradeSummary = async (
  userId,
  assessmentId,
  evaluationId
) => {
  const response = await baseApi.get(
    `/api/v1/assessment/grades/summary/${userId}/${assessmentId}/${evaluationId}`
  );

  return response;
};

const getAssessmentDetailsStudent = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/assessmentResults/${assessmentId}`
  );

  return response;
};

const getAssessmentStudentTips = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/evaluation/tips/${assessmentId}`
  );

  return response;
};

const getOrganizationCourseRegionSchools = async () => {
  const response = await baseApi.get(`/api/v1/organization/search`);

  return response;
};

const publishRegionCourseSchoolAssessment = async (assessmentId, data) => {
  const response = await baseApi.post(
    `/api/v1/assessment/publish/${assessmentId}`,
    data
  );

  return response;
};

const deleteAssessmentPublish = async (assessmentId, publishId) => {
  const response = await baseApi.delete(
    `/api/v1/assessment/publish/${assessmentId}/${publishId}`
  );

  return response;
};

const getAIChat = async () => {
  const response = await baseApi.get(`/api/v1/coach/getCoachTeacherQuestions`);

  return response;
};

const createAIChat = async (data) => {
  console.log(data);
  const response = await baseApi.post(`/api/v1/coach/askForTeacher`, data);

  return response;
};

const createAIChatWithFile = async (data) => {
  const response = await baseApi.post(
    `/api/v1/coach/uploadDocumentFromTeacher`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    }
  );

  return response;
};

const deleteAIChat = async (chatId) => {
  const response = await baseApi.delete(
    `/api/v1/coach/deleteCoachTeacherAI/${chatId}`,

    {}
  );

  return response;
};

const getRegionalBreakdown = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/regionalBreakdownDashboard/${assessmentId}`
  );

  return response;
};

const getStudentAssessmentDashboard = async () => {
  const response = await baseApi.get(`/api/v1/assessment/userDashboard`);

  return response;
};

const getStudentAssessmentEvaluation = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/assessmentResults/${assessmentId}`
  );

  return response;
};

const submitAssessmentEvaluationAnswer = async (evaluationId, data) => {
  const response = await baseApi.post(
    `/api/v1/assessment/evaluation/${evaluationId}/question`,
    data
  );

  return response;
};

const getAssessmentUserList = async () => {
  const response = await baseApi.get(`/api/v1/assessment/userList`);

  return response;
};

const addUserToAssessmentEvaluation = async (data) => {
  const response = await baseApi.post(`/api/v1/assessment/evaluation`, data);

  return response;
};

const addCommentToAssessment = async (data, assessmentId) => {
  const response = await baseApi.post(
    `/api/v1/assessment/comment/${assessmentId}`,
    data
  );

  return response;
};

const getAssessmentCourseGradeSummary = async (assessmentId, courseId) => {
  const response = await baseApi.get(
    `api/v1/assessment/regionalBreakdownDashboard/${assessmentId}?summary=${courseId}`
  );

  return response;
};

const updateAssessmentEvaluation = async (assessmentEvaluationId, data) => {
  const response = await baseApi.put(
    `/api/v1/assessment/evaluation/status/${assessmentEvaluationId}`,
    data
  );

  return response;
};

const getAssessmentEvalutionTips = async (assessmentId) => {
  const response = await baseApi.get(
    `/api/v1/assessment/evaluation/tips/${assessmentId}`
  );

  return response;
};

const getAllPersonas = async () => {
  const response = await baseApi.get(`/api/v1/convoPersona`);

  return response;
};

const generateConvRubric = async (data) => {
  const response = await baseApi.post(
    `/api/v1/conversationalAI/generateConvRubric`,
    data
  );

  return response;
};

const getCallTranscript = async (docId) => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getTranscript/${docId}`
  );

  return response;
};

const genratePeerComparison = async (data) => {
  const response = await baseApi.post(
    `/api/v1/conversationalAI/genratePeerComparison`,
    data
  );

  return response;
};

const getPeerReponses = async (docId) => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getPeerReponses/${docId}`
  );

  return response;
};

const getConvRubric = async (docId) => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getConvRubric/${docId}`
  );

  return response;
};

const getUserStats = async () => {
  const response = await baseApi.get(`/api/v1/conversationalAI/getUserStats`);

  return response;
};

const getCallHistory = async () => {
  const response = await baseApi.get(`/api/v1/conversationalAI/getCallHistory`);

  return response;
};

const getSpiderChart = async () => {
  const response = await baseApi.get(`/api/v1/conversationalAI/getSpiderChart`);

  return response;
};

const individualPerformanceAnalyzer = async () => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/performanceAnalyzer`
  );

  return response;
};

const teamPerformanceAnalyzer = async () => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/teamPerformanceAnalyzer`
  );

  return response;
};

const setAcknowledgement = async ({ userId }) => {
  return await baseApi.post(`/api/v1/organization/user/update/${userId}`, {
    isConsentAcknowledged: true,
  });
};

const updateDemographics = async ({ data }) => {
  return await baseApi.post(`api/v1/organization/demographics`, data);
};

const uploadRecording = async ({ data }) => {
  try {
    const response = await baseApi.post(
      `/api/v1/conversationalAI/uploadRecording`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error in uploadRecording:', error);
  }
};

const getConvoTeam = async (organizationId, userType) => {
  const query = userType ? `?type=${userType}&` : '';
  const response = await baseApi.get(
    `/api/v1/convoTeam/${organizationId}${query}`
  );
  return response;
};

const postConvoTeam = async (data) => {
  const response = await baseApi.post('/api/v1/convoTeam', data);
  return response;
};

const updateConvoTeam = async ({ data, teamId }) => {
  const response = await baseApi.put(`/api/v1/convoTeam/${teamId}`, data);
  return response;
};

const deleteConvoTeam = async ({ teamId }) => {
  const response = await baseApi.delete(`/api/v1/convoTeam/${teamId}`);
  return response;
};

const addNewSalesRep = async (data) => {
  const response = await baseApi.post(
    '/api/v1/organization/admin/invitation',
    data
  );
  return response;
};

const getAdminDashboardBreakdown = async () => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getAdminDashboardBreakdown`
  );
  return response;
};

const getCallHistoryForOrganization = async () => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getCallHistoryForOrganization`
  );
  return response;
};

const getPerformanceAnalyzer = async (userId) => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/performanceAnalyzer?userId=${userId}`
  );
  return response;
};

const getIndividualUserStats = async (userId) => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getUserStats?userId=${userId}`
  );
  return response;
};

const getIndividualCallHistory = async (userId) => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getCallHistory?userId=${userId}`
  );
  return response;
};

const getIndividualSpiderChart = async (userId) => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getSpiderChart?userId=${userId}`
  );
  return response;
};

const getConvoAssignment = async () => {
  const response = await baseApi.get(`/api/v1/convoAssignment`);
  return response;
};

const updateConvoAdminAssignment = async ({ data, userAssignmentId }) => {
  const response = await baseApi.put(
    `/api/v1/convoAssignment/${userAssignmentId}/persona`,
    data
  );
  return response;
};

const analyzeRecording = async ({ data }) => {
  const response = await baseApi.post(
    '/api/v1/conversationalAI/analyzeRecording',
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response;
};

const getAllSalesReps = async (organizationId) => {
  const response = await baseApi.get(
    `/api/v1/convoTeam/${organizationId}?type=salesReps`
  );
  return response;
};

const getAIBehaviors = async () => {
  const response = await baseApi.get(`/api/v1/convoBehavior/getBehaviors`);
  return response;
};

const getCallHistoryUngrouped = async () => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getCallHistoryUngrouped`
  );
  return response;
};

const createObjectives = async (data) => {
  const response = await baseApi.post(
    '/api/v1/conversationalAI/createObjectives',
    data
  );
  return response;
};

const updateObjectives = async (data) => {
  const response = await baseApi.put(
    `/api/v1/conversationalAI/updateObjectives`,
    data
  );
  return response;
};

const getObjectives = async () => {
  const response = await baseApi.get(`/api/v1/conversationalAI/getObjectives`);
  return response;
};

const deleteObjective = async (data) => {
  const response = await baseApi.delete(
    `/api/v1/conversationalAI/deleteObjective`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    }
  );
  return response;
};
const createModule = async ({ data, organizationId, userId }) => {
  const response = await baseApi.post(`/api/v1/gcd/modules`, data, {
    headers: {
      organizationId: organizationId,
      id: userId,
    },
  });
  return response;
};

const updateModule = async ({ data, moduleId }) => {
  const response = await baseApi.put(`/api/v1/gcd/modules/${moduleId}`, data);
  return response;
};

const deleteModule = async ({ moduleId }) => {
  const response = await baseApi.put(`/api/v1/gcd/modules/${moduleId}`, {
    status: 'archive',
  });
  return response;
};

const getModuleList = async ({ organizationId }) => {
  const response = await baseApi.get(`/api/v1/gcd/modules`, {
    headers: {
      organizationId: organizationId,
    },
  });
  return response;
};

const getModuleById = async ({ moduleId }) => {
  const response = await baseApi.get(`/api/v1/gcd/modules/${moduleId}`);
  return response;
};

const createModuleSection = async ({ data, moduleId }) => {
  const response = await baseApi.post(
    `/api/v1/gcd/modules/${moduleId}/section`,
    data
  );
  return response;
};

const updateModuleSection = async ({ data, moduleId, sectionId }) => {
  const response = await baseApi.put(
    `/api/v1/gcd/modules/${moduleId}/section/${sectionId}`,
    data
  );
  return response;
};

const deleteModuleSection = async ({ moduleId, sectionId }) => {
  const response = await baseApi.delete(
    `/api/v1/gcd//modules/${moduleId}/section/${sectionId}`
  );
  return response;
};

const createModuleSubSection = async ({ data, moduleId, sectionId }) => {
  return await baseApi.post(
    `/api/v1/gcd/modules/${moduleId}/section/${sectionId}/subSection`,
    data
  );
};

const updateModuleSubSection = async ({
  data,
  moduleId,
  sectionId,
  subSectionId,
}) => {
  const response = await baseApi.put(
    `/api/v1/gcd/modules/${moduleId}/section/${sectionId}/subSection/${subSectionId}`,
    data
  );
  return response;
};

const deleteModuleSubSection = async ({
  moduleId,
  sectionId,
  subSectionId,
}) => {
  const response = await baseApi.delete(
    `/api/v1/gcd/modules/${moduleId}/section/${sectionId}/subSection/${subSectionId}`
  );
  return response;
};

const moduleSubsectionFileUpload = async ({
  moduleId,
  sectionId,
  subSectionId,
  file,
}) =>
  await baseApi.put(
    `/api/v1/gcd/modules/${moduleId}/section/${sectionId}/subSection/${subSectionId}/readingMaterialUpload`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

const generateModuleSectionDocx = async ({ moduleId, sectionId }) => {
  const response = await baseApi.get(
    `/api/v1/gcd/journeys/modules/${moduleId}/worksheet/${sectionId}`
  );
  return response;
};

const uploadDocument = async ({ formData }) => {
  const response = await baseApi.post(`/api/v1/gcd/uploadDocument`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

const createAsset = async ({ data }) => {
  const response = await baseApi.post(`/api/v1/gcd/assets`, data);
  return response;
};

const updateAsset = async ({ data, assetId }) => {
  const response = await baseApi.put(`/api/v1/gcd/assets/${assetId}`, data);
  return response;
};

const deleteAsset = async ({ assetId }) => {
  const response = await baseApi.delete(`/api/v1/gcd/assets/${assetId}`);
  return response;
};

const getAssetList = async () => {
  const response = await baseApi.get(`/api/v1/gcd/assets`);
  return response;
};

const getOpenAiStatus = async () => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getOpenAiStatus`
  );
  return response;
};

const convoAssignment = async (data) => {
  const response = await baseApi.post(`/api/v1/convoAssignment`, data);
  return response;
};

const getUserByRole = async ({ orgId, role }) => {
  const response = await baseApi.get(
    `/api/v1/organization/users/${orgId}?role=${role}`
  );
  return response;
};

const generateSuggestedPersona = async ({ data }) => {
  const response = await baseApi.post(
    `/api/v1/convoAssignment/generate/suggested/personas`,
    data
  );
  return response;
};

const getAllConvoAssignments = async () => {
  const response = await baseApi.get(
    `/api/v1/convoAssignment/organization/all`
  );
  return response;
};

const convoAssignmentById = async (id) => {
  const response = await baseApi.get(`/api/v1/convoAssignment/${id}`);
  return response;
};

const updateConvoAssignmentById = async ({ data, assignmentId }) => {
  const response = await baseApi.put(
    `/api/v1/convoAssignment/${assignmentId}`,
    data
  );
  return response;
};

const getAssignmentDetails = async ({ userId, assignmentId }) => {
  const response = await baseApi.get(
    `/api/v1/convoAssignment/user/${userId}?assignmentId=${assignmentId}`
  );
  return response;
};

const deleteAssignmnet = async (assignmentId) => {
  const response = await baseApi.delete(
    `/api/v1/convoAssignment/${assignmentId}`
  );
  return response;
};

const deleteAssigneeById = async ({ assignmentId, userId }) => {
  const response = await baseApi.delete(
    `/api/v1/convoAssignment/deleteAssignedTo/${assignmentId}/${userId}`
  );
  return response;
};

const getAssignmentsByUserId = async (userId) => {
  const response = await baseApi.get(`/api/v1/convoAssignment/user/${userId}`);
  return response;
};

const getAssignmentById = async (assignmentId) => {
  const response = await baseApi.get(`/api/v1/convoAssignment/${assignmentId}`);
  return response;
};

const getPersonaById = async (personaId) => {
  const response = await baseApi.get(`/api/v1/convoPersona/${personaId}`);
  return response;
};

const getAssignmentPersonas = async ({ userId, assignmentId }) => {
  const response = await baseApi.get(
    `/api/v1/convoAssignment/user/${userId}?assignmentId=${assignmentId}&callTracker=true`
  );
  return response;
};

const createJourney = async ({ data }) => {
  const response = await baseApi.post(`/api/v1/gcd/journeys`, data);
  return response;
};

const updateJourney = async ({ data, journeyId }) => {
  const response = await baseApi.put(`/api/v1/gcd/journeys/${journeyId}`, data);
  return response;
};

const deleteJourney = async ({ journeyId }) => {
  const response = await baseApi.delete(`/api/v1/gcd/journeys/${journeyId}`);
  return response;
};

const getJourney = async ({ journeyId }) => {
  const response = await baseApi.get(`/api/v1/gcd/journeys/${journeyId}`);
  return response;
};
const getJourneyList = async () => {
  const response = await baseApi.get(`/api/v1/gcd/journeys`);
  return response;
};

const createJourneyModules = async ({ modules = [], journeyId }) => {
  const response = await baseApi.post(
    `api/v1/gcd/journeys/${journeyId}/modules`,
    modules
  );
  return response;
};

const createJourneyModuleTranslation = async ({ data, journeyId }) => {
  const response = await baseApi.post(
    `api/v1/gcd/journeys/modules/translate/${journeyId}`,
    data
  );
  return response;
};

const getgetBirdEyeSearch = async (query = {}) => {
  const queryParams = new URLSearchParams(query).toString();
  const url = `/api/v1/conversationalAI/getBirdEyeSearch${
    queryParams ? `?${queryParams}` : ''
  }`;

  const response = await baseApi.get(url);
  return response;
};

const getBirdEyeSearchCriteria = async () => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getBirdEyeSearchCriteria`
  );
  return response;
};

const getBirdEyeCallChart = async (type) => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getBirdEyeCallChart?type=${type}`
  );
  return response;
};

const getAverageScoreChart = async (type) => {
  const response = await baseApi.get(
    `/api/v1/conversationalAI/getAverageScoreChart?type=${type}`
  );
  return response;
};

const getTranslatedModules = async (journeyId) => {
  const response = await baseApi.get(
    `/api/v1/gcd/journeys/modules/translated/${journeyId}`
  );
  return response;
};

const generatePPT = async (moduleId) => {
  const response = await baseApi.post(`/api/v1/gcd/${moduleId}/generatePPT`);
  return response;
};

const checkInvitation = async (queryString) => {
  const response = await baseApi.get(
    `/api/v1/organization/user/invitation/checkInvitation?${queryString}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response;
};

export const apis = {
  baseApi,
  saveOrganizationCodeToLocalStorage,
  getOrganizationCodeFromLocalStorage,
  userLogin,
  getTokenFromLocalStorage,
  getCoachIDToLocalStorage,
  getUserProfile,
  userSignup,
  coachLogin,

  //organization api methods
  getCoachProfile,
  submitQuestion,
  getUserQuestions,
  getCoachQuestions,
  submitFeedback,
  organizationUserLogin,
  organizationUserRegistration,
  organizationUserForget,
  organizationUserReset,
  googleSignup,
  inviteUser,
  getUsersList,
  updateAssignees,
  deleteUser,
  checkDuplicateEmail,
  resendInvitation,
  revokeInvitation,
  getUser,
  askQuestion,
  getQuestion,
  updateStar,
  updateQuestionClose,
  getMe,
  getCoachQuestion,
  coachFeedback,
  bulkInvitee,
  setAcknowledgement,
  updateDemographics,

  /////////////////////// COURSE
  getAllCourses,
  createCourse,
  deleteCourse,

  /////////////////////// CANVAS API
  createCanvas,
  getAllCanvas,
  getIndividualCanvas,
  addCanvasWorkflowItem,
  updateCanvasWorkflowItem,
  deleteCanvasWorkflowItem,
  submitDraft,
  addCommentToCanvas,

  getAllCanvasForCoach,
  getIndividualCanvasForCoach,
  getGradingAIForIndividualCanvas,
  updateCanvasTile,
  updateCanvasGradingStatus,
  updateCanvasStatus,
  getAllCanvasStudent,
  addCollaboratorToCanvas,
  deleteCollaboratorToCanvas,

  ////// INSIGHT
  getStatistics,

  ////// ADMISSION
  createAdmissionTemplate,
  getAdmissionTemplateById,
  getAllAdmissionTemplates,
  getAdmissionTemplateByUser,
  addAdmissionTemplateAcademicInterest,
  addAdmissionTemplateExtracurricularActivities,
  addAdmissionTemplatePersonalExperiences,
  addAdmissionTemplateFutureGoals,
  addAdmissionTemplateSpecificInterest,
  addAdmissionTemplatEssayTopic,
  submitEssay,
  getEssayById,
  updateCollegeDetails,
  updateEssayImprovementSuggestions,
  updateEssayRemarks,
  updateGrade,

  ////// SUMMARY
  getSummaryByCanvasId,
  createSummary,

  ////// TEACHER AI
  getTeacherAIDocument,
  uploadTeacherAI,
  getTeacherAIChat,
  createTeacherAIChat,
  deleteTeacherAIDocumentFile,
  updateTeacherAIDocumentFileStatus,
  renameTeacherAIChat,
  deleteTeacherAIChat,

  ////// DOCUMENT ANALYZER
  documentAnalyzerUploadDocument,
  getDocumentAnalyzeByUser,
  getDocumentAnalyzeByOrganization,
  getDocumentAnalyzeById,
  deleteDocumentAnalayzeById,
  addCommentToDocumentAnalyze,
  updateDocumentAnalyzeStatusById,
  bulkActionDocumentAnalyze,
  getResumeRuleId,

  ////// ORGANIZATION META
  getOrganizationMetaByOrganizationMetaName,
  getOrganizationMetaByRole,

  ///// ACCEPTED USER
  getAcceptedUser,
  updateOwnPassword,
  updateUserPassword,

  ////// ASSESSMENT
  getAssessment,
  getAssessmentId,
  createAssessment,
  updateAssessment,
  deleteAssessment,
  createAssessmentSection,
  createAssessmentQuestion,
  updateAssessmentSection,
  updateAssessmentQuestion,
  deleteAssessmentSection,
  deleteAssessmentQuestion,
  getAssessmentConditionSummary,
  getAssessmentGradeSummary,
  getOrganizationCourseRegionSchools,
  publishRegionCourseSchoolAssessment,
  deleteAssessmentPublish,
  getRegionalBreakdown,
  getAssessmentDetailsStudent,
  getAssessmentStudentTips,
  ////// AI CHAT
  getAIChat,
  createAIChat,
  deleteAIChat,
  createAIChatWithFile,
  getStudentAssessmentDashboard,
  getStudentAssessmentEvaluation,
  submitAssessmentEvaluationAnswer,
  getAssessmentUserList,
  addUserToAssessmentEvaluation,
  addCommentToAssessment,
  getAssessmentCourseGradeSummary,
  updateAssessmentEvaluation,
  getAssessmentEvalutionTips,

  updateUser,
  updateCourse,

  // conversational ai api calls
  getAllPersonas,
  generateConvRubric,
  getCallTranscript,
  genratePeerComparison,
  getPeerReponses,
  getConvRubric,
  getUserStats,
  getCallHistory,
  getSpiderChart,
  uploadRecording,
  getConvoTeam,
  postConvoTeam,
  updateConvoTeam,
  deleteConvoTeam,
  addNewSalesRep,
  getAdminDashboardBreakdown,
  getCallHistoryForOrganization,

  getPerformanceAnalyzer,
  getIndividualUserStats,
  getIndividualCallHistory,
  getIndividualSpiderChart,
  getConvoAssignment,
  updateConvoAdminAssignment,
  analyzeRecording,
  getAllSalesReps,
  individualPerformanceAnalyzer,
  teamPerformanceAnalyzer,
  getAIBehaviors,
  getCallHistoryUngrouped,
  createObjectives,
  updateObjectives,
  getObjectives,
  deleteObjective,
  getOpenAiStatus,

  //module
  createModule,
  updateModule,
  deleteModule,
  getModuleList,
  getModuleById,
  generatePPT,
  createModuleSection,
  updateModuleSection,
  deleteModuleSection,
  moduleSubsectionFileUpload,

  createModuleSubSection,
  updateModuleSubSection,
  deleteModuleSubSection,

  generateModuleSectionDocx,

  // asset
  uploadDocument,
  createAsset,
  getAssetList,
  updateAsset,
  deleteAsset,

  //assignments
  convoAssignment,
  getUserByRole,
  generateSuggestedPersona,
  getAllConvoAssignments,
  convoAssignmentById,
  updateConvoAssignmentById,
  getAssignmentDetails,
  deleteAssignmnet,
  deleteAssigneeById,
  getAssignmentsByUserId,
  getAssignmentById,
  getPersonaById,
  getAssignmentPersonas,

  //journey
  createJourney,
  updateJourney,
  deleteJourney,
  getJourney,
  getJourneyList,
  createJourneyModules,
  createJourneyModuleTranslation,
  getTranslatedModules,

  //birds-eye-statistic
  getgetBirdEyeSearch,
  getBirdEyeSearchCriteria,
  getBirdEyeCallChart,
  getAverageScoreChart,

  //invitation check
  checkInvitation,
};

export default apis;

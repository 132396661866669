import apis from 'api/api';
import { useApi } from 'hooks';

export const useModule = (moduleId) => {
  const {
    call: fetchModule,
    data: moduleDetails,
    loading: moduleLoading,
  } = useApi({
    fetcher: apis.getModuleById,
    queryKey: ['getModuleById', moduleId],
  });

  const { call: storeSection, isPending: isSectionCreating } = useApi({
    fetcher: apis.createModuleSection,
    successMessage: 'Section created successfully',
  });

  const { call: updateSection, isLoading: isSectionUpdating } = useApi({
    fetcher: apis.updateModuleSection,
    successMessage: 'Section updated successfully',
  });

  const { call: deleteSection } = useApi({
    fetcher: apis.deleteModuleSection,
    successMessage: 'Section deleted successfully',
  });

  const { call: storeSubSection, isPending: isSubSectionCreating } = useApi({
    fetcher: apis.createModuleSubSection,
    successMessage: 'Sub-section created successfully',
  });

  const { call: updateSubSection, isLoading: isSubSectionUpdating } = useApi({
    fetcher: apis.updateModuleSubSection,
    successMessage: 'Sub-section updated successfully',
  });

  const { call: deleteSubSection } = useApi({
    fetcher: apis.deleteModuleSubSection,
    successMessage: 'Sub-section deleted successfully',
  });

  const { call: subSectionContentUpload } = useApi({
    fetcher: apis.moduleSubsectionFileUpload,
  });

  const { call: generateModuleSectionDocx, loading: isExporting } = useApi({
    fetcher: apis.generateModuleSectionDocx,
  });

  const validateSection = (section) => {
    const errors = {};

    if (!section.title) {
      errors.title = 'Section Category is required';
    }

    if (!section.activity) {
      errors.activity = 'Activity is required';
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors,
    };
  };

  const validateSubsection = (subsection) => {
    const errors = {};

    if (!subsection.title) {
      errors.title = 'Title is required';
    }

    if (!subsection.minutes || subsection.minutes <= 0) {
      errors.minutes = 'Minutes must be greater than 0';
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors,
    };
  };

  return {
    fetchModule,
    moduleDetails,
    moduleLoading,
    storeSection,
    isSectionCreating,
    updateSection,
    isSectionUpdating,
    deleteSection,
    storeSubSection,
    isSubSectionCreating,
    updateSubSection,
    isSubSectionUpdating,
    deleteSubSection,
    subSectionContentUpload,
    validateSection,
    validateSubsection,
    generateModuleSectionDocx,
    isExporting,
  };
};

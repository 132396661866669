import { Close } from '@mui/icons-material';
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from '@mui/material';
import { useState } from 'react';

/**
 * Flexible Form Select Component
 * @param {Object} props - Component properties
 */
export const Select = ({
  label,
  options = [],
  formType = 'ADD',
  disabled = false,
  loading = false,
  sx = {},
  size = 'small',
  labelId = '',
  onChange = () => {},
  ...selectProps
}) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleClearValue = () => {
    setValue('');
    if (onChange) onChange({ target: { value: '' } });
  };
  const id = labelId ? labelId : label.toLowerCase().replace(' ', '-');
  return (
    <FormControl size={size}>
      <InputLabel id={id}>{label}</InputLabel>
      <MUISelect
        labelId={id}
        disabled={disabled}
        label={label}
        onChange={handleChange}
        value={value}
        endAdornment={
          value && (
            <InputAdornment position="end" sx={{ mr: '10px' }}>
              <IconButton
                aria-label="clear value"
                onClick={handleClearValue}
                edge="end"
                size="small"
              >
                <Close fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        }
        sx={{
          '.MuiSelect-select': {
            paddingRight: '4px !important',
          },
          width: 160,
          ...sx,
        }}
        {...selectProps}
      >
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={24} sx={{ mr: 1 }} />
            <span>Loading...</span>
          </MenuItem>
        ) : (
          options.map((option, index) => (
            <MenuItem
              key={`${option.value}-${index}`}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label || option.value}
            </MenuItem>
          ))
        )}
      </MUISelect>
    </FormControl>
  );
};

import {
  Autocomplete,
  Chip,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import { ContentBox } from 'components/Box';
import { DataTable } from 'components/Table';
import { useApi } from 'hooks';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDialogStore } from 'store/useStore';
import { moduleOptions } from './common/const';

const SectionListTableColumns = [
  { id: 'title', label: 'Section' },
  { id: 'activity', label: 'Activity' },
  { id: 'minutes', label: 'Minutes' },
  // { id: 'sectionType', label: 'Type' },
];
const dialogOpenKey = 'moduleDetails';
export const ModuleDetails = () => {
  const editDialogState = useDialogStore((state) => state.editDialog);
  const [sectionData, setSectionData] = useState([]);

  const { call: fetchModule, isLoading: isFetchingModule } = useApi({
    fetcher: apis.getModuleById,
    onSuccess: (module) => {
      setSectionData(module?.sections);

      if (module?.category && Array.isArray(module.category)) {
        setValue('category', module.category);
      }

      if (module?.course && Array.isArray(module.course)) {
        setValue('course', module.course);
      }
    },
  });

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useFormContext();

  useEffect(() => {
    reset(editDialogState.data);

    if (
      editDialogState.data?._id &&
      editDialogState.forItem === dialogOpenKey
    ) {
      fetchModule({ moduleId: editDialogState.data?._id });
    }
  }, [editDialogState.data]);

  const renderFormFields = () => {
    const infoFields = [
      { name: 'title', label: 'Module Title', type: 'text' },

      {
        name: 'description',
        label: 'Description',
        type: 'text',
        multiline: true,
      },
      {
        name: 'type',
        label: 'Type',
        type: 'select',
        options: moduleOptions.moduleType,
      },
    ];

    return (
      <>
        {infoFields.map((item) => (
          <FormField
            //loading={srLoading || personasLoading}
            formType="ADD"
            key={item.name}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
          />
        ))}

        <Autocomplete
          multiple
          id="category-autocomplete"
          options={moduleOptions.category || []}
          getOptionLabel={(option) => {
            // Handle both object format and string format
            return typeof option === 'object' ? option.label : option;
          }}
          value={
            Array.isArray(watch('category'))
              ? watch('category').map((value) => {
                  // First check if value is already an object with label/value
                  if (typeof value === 'object' && value.value && value.label) {
                    return value;
                  }

                  // Otherwise find the matching option or create a temporary one
                  return (
                    moduleOptions.category?.find(
                      (option) => option.value === value
                    ) || { value, label: value }
                  );
                })
              : []
          }
          onChange={(_, newValue) => {
            const values = newValue.map((item) =>
              typeof item === 'object' ? item.value : item
            );
            setValue('category', values);
            trigger('category');
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category"
              error={!!errors.category}
              helperText={errors.category?.message}
              size="small"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={typeof option === 'object' ? option.label : option}
                {...getTagProps({ index })}
                sx={{
                  backgroundColor: '#DAE8E7',
                  '& .MuiChip-deleteIcon': {
                    color: '#5B706F',
                  },
                }}
              />
            ))
          }
          disableCloseOnSelect
        />

        <Autocomplete
          multiple
          id="course-autocomplete"
          options={moduleOptions.course || []}
          getOptionLabel={(option) => {
            // Handle both object format and string format
            return typeof option === 'object' ? option.label : option;
          }}
          value={
            Array.isArray(watch('course'))
              ? watch('course').map((value) => {
                  // First check if value is already an object with label/value
                  if (typeof value === 'object' && value.value && value.label) {
                    return value;
                  }

                  // Otherwise find the matching option or create a temporary one
                  return (
                    moduleOptions.course?.find(
                      (option) => option.value === value
                    ) || { value, label: value }
                  );
                })
              : []
          }
          onChange={(_, newValue) => {
            const values = newValue.map((item) =>
              typeof item === 'object' ? item.value : item
            );
            setValue('course', values);
            trigger('course');
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Course"
              error={!!errors.course}
              helperText={errors.course?.message}
              size="small"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={typeof option === 'object' ? option.label : option}
                {...getTagProps({ index })}
                sx={{
                  backgroundColor: '#DAE8E7',
                  '& .MuiChip-deleteIcon': {
                    color: '#5B706F',
                  },
                }}
              />
            ))
          }
          disableCloseOnSelect
        />
      </>
    );
  };

  const shouldShowSectionOverview = editDialogState?.isOpen;
  const sectionOverviewLoading = isFetchingModule;
  return (
    <ContentBox
      id="temp"
      sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
    >
      {renderFormFields()}
      {shouldShowSectionOverview ? (
        <>
          <Typography variant="button">Section Overview</Typography>
          <Divider sx={{ borderColor: 'primary.main' }} />
          {sectionOverviewLoading ? (
            <CircularProgress />
          ) : (
            <DataTable columns={SectionListTableColumns} data={sectionData} />
          )}
        </>
      ) : null}
    </ContentBox>
  );
};

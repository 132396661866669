import MobileOffIcon from '@mui/icons-material/MobileOff';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  keyframes,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

  const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(-20px); // Optional slight slide from top
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;
const DeviceNotAllowed = ({ open, setOpen }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          maxWidth: '300px',
          p: 3,
          overflow: 'visible',
          animation: `${fadeIn} 1s ease-in-out`,
        },
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          p: 0,
          overflow: 'visible',
        }}
      >
        <Paper
          sx={{
            width: isMobile ? '95%' : isTablet ? '80%' : '90%',
            maxWidth: '300px',
            p: isMobile ? 1 : 3,
            outline: 'none',
            borderRadius: 2,
            textAlign: 'center',
            // boxShadow: 24,
            boxShadow: 'none',
            bgcolor: 'transparent',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: isMobile ? 1.5 : 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: 'error.light',
                borderRadius: '50%',
                p: isMobile ? 1 : 1.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': {
                    transform: 'scale(1)',
                    opacity: 1,
                  },
                  '50%': {
                    transform: 'scale(1.05)',
                    opacity: 0.8,
                  },
                  '100%': {
                    transform: 'scale(1)',
                    opacity: 1,
                  },
                },
              }}
            >
              <MobileOffIcon
                sx={{
                  fontSize: isMobile ? 24 : 38,
                }}
              />
            </Box>

            <Typography
              variant={isMobile ? 'subtitle1' : 'h6'}
              sx={{
                fontWeight: 600,
                mb: 0.5,
              }}
            >
              Unsupported Device
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                mb: 2,
                px: isMobile ? 1 : 0,
              }}
            >
              Sorry, mobile devices are not supported at the moment.
            </Typography>

            <Button
              fullWidth
              variant="contained"
              onClick={() => setOpen(false)}
              sx={{
                textTransform: 'none',
                mt: 1,
                py: isMobile ? 0.75 : 1,
                fontSize: isMobile ? '0.875rem' : '1rem',
              }}
            >
              Dismiss
            </Button>
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};
export default DeviceNotAllowed;

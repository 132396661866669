import { ArrowDropDown, Close } from '@mui/icons-material';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  CustomDatepicker as DatePicker,
  DateRangePicker,
} from 'components/Datepicker';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import React, { useState } from 'react';
import { colorPalette } from 'theme/colors';

const getComponentProps = ({ formType, componentType }) => {
  const baseProps = {
    stack: {
      direction: 'row',
      sx: { alignItems: 'center' },
    },
    input: {
      fullWidth: true,
      sx: { flex: 1 },
    },
  };

  const addTypeProps = {
    stack: {
      sx: {
        background: colorPalette.background.paper,
        px: 2,
        py: 4,
        borderRadius: 2,
      },
    },
    input: {
      sx: { flex: 1 },
      size: 'small',
    },
    select: { size: 'small' },
  };

  return formType === 'ADD'
    ? addTypeProps[componentType]
    : baseProps[componentType];
};

export const FormField = ({
  formType = 'EDIT',
  field,
  register,
  errors,
  watch,
  loading,
  state,
  stateUpdater,
  hookFormUpdater,
  hookFormTrigger,
  disabled,
  onChange,
  sx,
}) => {
  const { name, label, type, options, ...rest } = field;
  const [inputValue, setInputValue] = useState('');

  const handleMultiSelectChange = ({ name, values }) => {
    hookFormUpdater(name, values);
    hookFormTrigger(name);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    hookFormUpdater(name, value);
    hookFormTrigger(name);
  };

  const handleClearValue = () => {
    hookFormUpdater(name, type === 'multiSelect' ? [] : '', {
      shouldValidate: true,
    });
    if (onChange) {
      const syntheticEvent = {
        target: { name, value: type === 'multiSelect' ? [] : '' },
      };
      onChange(syntheticEvent);
    }
  };

  const renderField = () => {
    switch (type) {
      case 'text':
      case 'number':
      case 'password':
        return (
          <TextField
            type={type}
            {...getComponentProps({
              formType,
              componentType: 'input',
              sx: {
                ...getComponentProps({ formType, componentType: 'input' }).sx,
                ...sx,
              },
            })}
            disabled={disabled}
            label={formType === 'ADD' ? label : ''}
            value={watch(name) || ''}
            {...register(name)}
            error={!!errors[name]}
            helperText={errors[name] ? errors[name].message : ''}
            onChange={(e) => {
              handleInputChange(e);
              if (onChange) onChange(e);
            }}
            multiline={rest.multiline}
            rows={4}
            InputProps={{
              endAdornment: loading ? <CircularProgress size={24} /> : null,
            }}
          />
        );
      case 'select':
        const currentValue = watch(name) || '';
        const hasValue = currentValue !== '';

        return (
          <FormControl
            fullWidth={formType === 'EDIT' ? true : false}
            sx={{ flex: 1 }}
            variant="outlined"
          >
            {formType === 'ADD' && (
              <InputLabel id={`${name}-label`} size="small">
                {label}
              </InputLabel>
            )}
            <Select
              {...getComponentProps({ formType, componentType: 'select' })}
              sx={{
                '.MuiSelect-select': {
                  paddingRight: '4px !important',
                },
                ...sx,
              }}
              {...register(name)}
              disabled={disabled}
              label={formType === 'ADD' ? label : ''}
              labelId={`${name}-label`}
              id={name}
              error={!!errors[name]}
              value={!loading ? currentValue : ''}
              onChange={(e) => {
                handleInputChange(e);
                hookFormUpdater(name, e.target.value, { shouldValidate: true });
                if (onChange) onChange(e);
              }}
              IconComponent={(props) =>
                loading ? (
                  <CircularProgress size={20} sx={{ mr: 1 }} />
                ) : (
                  <ArrowDropDown {...props} />
                )
              }
              endAdornment={
                hasValue && (
                  <InputAdornment position="end" sx={{ mr: 2 }}>
                    <IconButton
                      aria-label="clear value"
                      onClick={handleClearValue}
                      edge="end"
                      size="small"
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            >
              {loading ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                  <span style={{ marginLeft: 10 }}>Loading...</span>
                </MenuItem>
              ) : (
                options?.map((option, i) => (
                  <MenuItem key={`${option.value}-${i}`} value={option.value}>
                    {option.label || ''}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText error={!!errors[name]}>
              {errors[name]?.message || ''}
            </FormHelperText>
          </FormControl>
        );
      case 'multiSelect':
        const selectedValues = watch(name) || [];
        const hasSelectedValues = selectedValues.length > 0;

        return (
          <FormControl fullWidth sx={{ flex: 1 }}>
            <MultiSelect
              {...getComponentProps({ formType, componentType: 'select' })}
              {...register(name)}
              disabled={disabled}
              creatable={rest.autoComplete}
              error={!!errors[name]}
              errorMessage={errors[name]?.message || ''}
              loading={loading}
              options={options}
              label={label}
              selectedValues={selectedValues}
              handleSelectionChange={(e) => {
                const selectedValues = e.target.value;
                handleMultiSelectChange({ name, values: selectedValues });
                if (onChange) onChange(e);
              }}
              handleDelete={(valueToDelete) => {
                const updatedValues = selectedValues.filter(
                  (value) => value !== valueToDelete
                );
                handleMultiSelectChange({ name, values: updatedValues });
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) =>
                setInputValue(newInputValue)
              }
              clearButton={hasSelectedValues}
              handleClear={handleClearValue}
              sx={sx}
            />
          </FormControl>
        );
      case 'date':
        return (
          <FormControl fullWidth sx={{ flex: 1 }}>
            <DatePicker
              value={watch(name) || ''}
              label={formType === 'ADD' ? label : ''}
              onDateChange={(date) => {
                hookFormUpdater(name, date);
                hookFormTrigger(name);
                if (onChange) {
                  const syntheticEvent = { target: { name, value: date } };
                  onChange(syntheticEvent);
                }
              }}
              disabled={disabled}
              onClear={(resetDate) => {
                //handleClearValue();
              }}
              sx={sx}
            />
            <FormHelperText error={!!errors[name]}>
              {errors[name]?.message || ''}
            </FormHelperText>
          </FormControl>
        );

      case 'dateRange':
        return (
          <FormControl fullWidth sx={{ flex: 1 }}>
            <DateRangePicker
              disabled={disabled}
              width="100%"
              startDate={watch('startDate')}
              endDate={watch('endDate')}
              label={formType === 'ADD' ? label : ''}
              onDateChange={(date) => {
                const { startDate, endDate } = date;
                const start = startDate
                  ? new Date(startDate).toISOString()
                  : null;
                const end = endDate ? new Date(endDate).toISOString() : null;
                hookFormUpdater(name, {
                  startDate: start,
                  endDate: end,
                });
                hookFormTrigger(name);
              }}
              sx={sx}
            />
          </FormControl>
        );

      default:
        return null;
    }
  };

  if (formType === 'ADD') {
    return renderField();
  }

  return (
    <Stack {...getComponentProps({ componentType: 'stack', formType: 'EDIT' })}>
      {formType === 'EDIT' && (
        <Typography sx={{ flex: 0.5 }}>{label}</Typography>
      )}
      {renderField()}
    </Stack>
  );
};

export default React.memo(FormField);

import { RadioButtonUnchecked } from '@mui/icons-material';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { getStyles } from './styles';
import { formatDuration } from './utils';

export const DurationProgressBar = ({
  journeyInfo,
  currentDuration,
  progressPercentage,
}) => {
  const styles = getStyles();

  return (
    <Box
      sx={{
        width: '95%',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: 'white',
        padding: '20px 0px',
        alignSelf: 'center',
      }}
    >
      <Stack gap={2} direction={'row'} alignItems={'center'}>
        <Stack gap={1} direction={'row'} alignItems={'center'}>
          <RadioButtonUnchecked sx={{ color: '#1A8753', fontSize: 16 }} />
          <Typography
            variant="caption"
            color={'text.hint'}
            sx={{ textWrap: 'nowrap' }}
          >
            Current Duration:{' '}
            {isNaN(currentDuration) ? 0 : formatDuration(currentDuration)} (
            {isNaN(progressPercentage) ? 0 : progressPercentage.toFixed(0)}%)
          </Typography>
        </Stack>
        <LinearProgress
          variant="determinate"
          value={isNaN(progressPercentage) ? 0 : progressPercentage}
          sx={styles.progressBar}
        />

        <Typography
          variant="caption"
          color={'text.hint'}
          sx={{ textWrap: 'nowrap' }}
        >
          Total Allotted Time:{' '}
          {journeyInfo &&
          typeof journeyInfo.totalNumberOfMeetings === 'number' &&
          typeof journeyInfo.numberOfHoursPerMeeting === 'number'
            ? journeyInfo.totalNumberOfMeetings *
              journeyInfo.numberOfHoursPerMeeting
            : 0}{' '}
          hours
        </Typography>
      </Stack>
    </Box>
  );
};

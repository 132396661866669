import { Close, Save } from '@mui/icons-material';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import React from 'react';
import { useDialogStore } from 'store';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const EditDialogWrapper = ({
  children,
  forItem,
  title,
  onSubmit,
  onClose,
  isExportable = false,
  isFooter = true,
  onExport,
  sx,
  ...rest
}) => {
  const [exporting, setExporting] = React.useState(false);
  const dialogState = useDialogStore((state) => state.editDialog);
  const shouldOpen = forItem
    ? forItem === dialogState.forItem && dialogState.isOpen
    : dialogState.isOpen;
  const changeDialogState = useDialogStore((state) => state.setEditDialog);

  const handleClose = () => {
    changeDialogState({ isOpen: false, forItem: '', data: null });
    if (onClose) onClose();
  };

  const handleExport = async () => {
    if (onExport) {
      setExporting(true);
      try {
        await onExport();
      } finally {
        setExporting(false);
      }
    }
  };

  return (
    <Dialog
      open={shouldOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={false}
      fullScreen={false}
      component="form"
      onSubmit={onSubmit}
      PaperProps={{
        sx: {
          width: '50vw',
          height: '100vh',
          maxHeight: '100vh',
          position: 'absolute',
          right: 0,
          margin: 0,
          ...sx,
        },
      }}
      {...rest}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="span">
          {title}
        </Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>{children}</DialogContent>
      {isFooter && (
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Box
            sx={{ marginRight: 'auto' }}
            display={'flex'}
            alignItems={'center'}
            gap={2}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<Save />}
              type="submit"
            >
              Save
            </Button>

            {isExportable && (
              <Button
                startIcon={
                  exporting ? (
                    <CircularProgress size={14} />
                  ) : (
                    <GetAppIcon sx={{ fontSize: '12px' }} />
                  )
                }
                sx={{ color: '#5B706F' }}
                variant="outlined"
                onClick={handleExport}
                disabled={exporting}
              >
                {exporting ? 'EXPORTING' : 'EXPORT'}
              </Button>
            )}
          </Box>
          <Button
            variant="outlined"
            onClick={handleClose}
            startIcon={<Close />}
          >
            Discard
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default EditDialogWrapper;

import { create } from 'zustand';

export const useLoadingStore = create((set, get) => ({
  loaders: {},
  queue: [],
  currentProcessing: null,
  addLoader: (id, label) =>
    set((state) => ({
      loaders: {
        ...state.loaders,
        [id]: { label, status: 'waiting' },
      },
      queue: [...state.queue, id],
    })),
  updateLoader: (id, status) =>
    set((state) => ({
      loaders: {
        ...state.loaders,
        [id]: { ...state.loaders[id], status },
      },
    })),
  removeLoader: (id) =>
    set((state) => {
      const newLoaders = { ...state.loaders };
      delete newLoaders[id];
      return {
        loaders: newLoaders,
        queue: state.queue.filter((qId) => qId !== id),
        currentProcessing:
          state.currentProcessing === id ? null : state.currentProcessing,
      };
    }),
  clearLoaders: () => set({ loaders: {}, queue: [], currentProcessing: null }),
  setCurrentProcessing: (id) => set({ currentProcessing: id }),
  hasActiveLoaders: () => {
    const state = get();
    return Object.values(state.loaders).some((loader) =>
      ['loading', 'waiting'].includes(loader.status)
    );
  },
  getNextInQueue: () => {
    const state = get();
    return state.queue.find((id) => state.loaders[id]?.status === 'waiting');
  },
}));

import * as Yup from 'yup';

export const journeyDetails = Yup.object().shape({
  title: Yup.string().required('Journey title is required'),
  year: Yup.string().required('Year is required'),
  partnerId: Yup.string().required('Partner is required'),
  // language: Yup.string().required('Language is required'),
  questions: Yup.array().of(
    Yup.object().shape({
      question: Yup.string(), // optional or required as needed
      answer: Yup.string().required('Answer is required'),
    })
  ),
  description: Yup.string().required('Description is required'),
  totalNumberOfMeetings: Yup.number()
    .typeError('Must be a number')
    .min(0, 'Must be 0 or greater')
    .required('Total number of session is required'),
  numberOfHoursPerMeeting: Yup.number()
    .typeError('Must be a number')
    .min(0, 'Must be 0 or greater')
    .required('Number of hours per session is required'),
  formatOfInstruction: Yup.string().required(
    'Format of instruction is required'
  ),
  supportStructure: Yup.string().required('Support structure is required'),
  location: Yup.array().of(Yup.string()).min(1, 'Location is required'),
});

import { useErrorHandler } from 'hooks/useErrorHandler';
import { useEffect, useState } from 'react';
import { useItemStore } from 'store'; // adjust the import to your store setup
import { StoreKeys } from 'utils';
import { SelectionType } from '../ModuleSelection';

export const useModuleSelection = ({
  modules = [],
  selectionType,
  journeyInfo,
  setFilterMode,
  translatedModules,
}) => {
  // const [selectedModules, setSelectedModules] = useState([]);

  //const setSelectedModules = useItemStore((state) => state.setSelectedModules);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const itemStore = useItemStore((state) => state.items);
  const addItemToStore = useItemStore((state) => state.addItem);
  const selectedModules = itemStore[StoreKeys.SELECTED_MODULES];
  const setSelectedModules = (newValueOrUpdater) => {
    // Determine the new value based on whether the argument is a function or a value.
    const newValue =
      typeof newValueOrUpdater === 'function'
        ? newValueOrUpdater(selectedModules)
        : newValueOrUpdater;

    // Update the global store using addItemToStore.
    addItemToStore({ key: StoreKeys.SELECTED_MODULES, value: newValue });
  };

  const calculateDuration = (allModules) => {
    let totalMinutes = 0;
    if (!allModules || !allModules.length) return 0;

    if (selectionType === SelectionType.FINAL) {
      allModules = translatedModules;
    }

    selectedModules?.length &&
      selectedModules.forEach((selModule) => {
        const moduleData = allModules?.find(
          (m) => m._id === selModule.moduleId
        );

        if (moduleData) {
          if (selModule?.sections?.length) {
            selModule.sections.forEach((sectionId) => {
              const section = moduleData.sections?.find(
                (s) => s._id === sectionId
              );
              if (section) {
                totalMinutes += parseInt(section.minutes, 10) || 0;
              }
            });
          } else if (
            selectionType === SelectionType.AI &&
            moduleData.sections
          ) {
            moduleData.sections.forEach((section) => {
              totalMinutes += parseInt(section.minutes, 10) || 0;
            });
          } else if (selectionType === SelectionType.FINAL) {
            moduleData.sections.forEach((section) => {
              totalMinutes += parseInt(section.minutes, 10) || 0;
            });
          }
        }
      });

    // Special condition for aiRecommended when moduleId is an array
    if (
      selectionType === SelectionType.AI &&
      selectedModules.length === 1 &&
      Array.isArray(selectedModules[0].moduleId)
    ) {
      selectedModules[0].moduleId.forEach((id) => {
        const moduleData = allModules.find((m) => m._id === id);
        if (moduleData && moduleData.sections) {
          moduleData.sections.forEach((section) => {
            totalMinutes += parseInt(section.minutes, 10) || 0;
          });
        }
      });
    }
    return totalMinutes;
  };

  const makeSelectionOfEditPage = () => {
    if (selectionType === SelectionType.EDIT) {
      const modules = journeyInfo?.modules;
      setSelectedModules(modules);
    } else if (selectionType === SelectionType.AI) {
      const aiModuleIds = journeyInfo?.aiModuleId ?? [];
      const modules = aiModuleIds.map((moduleId) => ({ moduleId }));
      setSelectedModules(modules);
    } else if (selectionType === SelectionType.FINAL) {
      const translatedModules = journeyInfo?.translatedModule ?? [];
      const modules = translatedModules.map((module) => ({
        moduleId: module.translatedModule,
      }));
      setSelectedModules(modules);
    } else if (selectionType === SelectionType.ADD) {
      const modules = journeyInfo?.modules;
      setSelectedModules(modules);
    }
  };

  const setInitialFilterMode = () => {
    if (!journeyInfo || !setFilterMode) return;

    // For EDIT mode, set to 'selected' if there are modules
    if (selectionType === SelectionType.EDIT && journeyInfo?.modules?.length) {
      setFilterMode('selected');
    }
    // For AI mode, set to 'selected' if there are aiModuleIds
    else if (
      selectionType === SelectionType.AI &&
      journeyInfo?.aiModuleId?.length
    ) {
      setFilterMode('selected');
    }
  };

  useEffect(() => {
    makeSelectionOfEditPage();
    setInitialFilterMode();
    return () => {
      setSelectedModules([]);
    };
  }, [selectionType, journeyInfo]);

  useEffect(() => {
    const allModules = modules?.length ? modules : itemStore?.modules || [];
    const newDuration = calculateDuration(allModules);
    setCurrentDuration(newDuration);

    if (journeyInfo) {
      const totalAllottedMinutes =
        journeyInfo.totalNumberOfMeetings *
        journeyInfo.numberOfHoursPerMeeting *
        60;
      const percentage = (newDuration / totalAllottedMinutes) * 100;
      setProgressPercentage(Math.min(percentage, 100));
    }
  }, [selectedModules, journeyInfo]);

  // Handler for toggling module selection
  const handleModuleChange = useErrorHandler((moduleData, isChecked) => {
    const moduleId = moduleData._id;
    const sectionIds = moduleData.sections?.map((s) => s._id) || [];

    //userSelectionMade.current = true;
    setSelectedModules((prev) => {
      if (isChecked) {
        if (!prev.some((mod) => mod.moduleId === moduleId)) {
          return [...prev, { moduleId, sections: sectionIds }];
        }
      } else {
        return prev.filter((mod) => mod.moduleId !== moduleId);
      }
      return prev;
    });
  });

  // Handler for toggling a section within a module
  const handleSectionChange = (moduleId, sectionId, isChecked) => {
    setSelectedModules((prev) => {
      const moduleIndex = prev.findIndex((mod) => mod.moduleId === moduleId);
      if (moduleIndex > -1) {
        const updatedModules = [...prev];
        const module = updatedModules[moduleIndex];
        if (isChecked) {
          if (!module.sections.includes(sectionId)) {
            module.sections.push(sectionId);
          }
        } else {
          module.sections = module.sections.filter((s) => s !== sectionId);
          if (module.sections.length === 0) {
            updatedModules.splice(moduleIndex, 1);
          }
        }
        return updatedModules;
      } else {
        return [...prev, { moduleId, sections: [sectionId] }];
      }
    });
  };

  return {
    selectedModules,
    setSelectedModules,
    currentDuration,
    progressPercentage,
    handleModuleChange,
    handleSectionChange,
  };
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'contexts/SnackbarContext';
import { useErrorHandler } from './useErrorHandler';

export const useApi = ({
  queryKey,
  fetcher,
  onSuccess,
  successMessage,
  options = {},
}) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  // Default cache configuration
  const defaultOptions = {
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep inactive data in cache for 30 minutes
    refetchOnWindowFocus: false, // Don't refetch when window focuses
    refetchOnMount: false, // Don't refetch on component mount if data is cached
    ...options,
  };

  // const handleError = (err) => {
  //   showSnackbar(err.message || 'An error occurred', 'error');
  //   console.error('API Call Error:', err);
  // };

  const handleSuccess = (data) => {
    if (onSuccess) onSuccess(data);
    if (successMessage) showSnackbar(successMessage, 'success');
  };

  const query = useQuery({
    queryKey: Array.isArray(queryKey) ? queryKey : [queryKey],
    queryFn: () => fetcher().then((res) => res.data),
    enabled: !!queryKey && defaultOptions.enabled !== false,
    ...defaultOptions,
    onSuccess: (data) => {
      if (defaultOptions.onSuccess) defaultOptions.onSuccess(data);
      if (successMessage) showSnackbar(successMessage, 'success');
    },
    // onError: (err) => {
    //   if (defaultOptions.onError) defaultOptions.onError(err);
    //   handleError(err);
    // },
  });

  const mutation = useMutation({
    mutationFn: (params) => fetcher(...params),
    onSuccess: (response) => {
      if (response?.data) {
        handleSuccess(response.data);

        // Update cache instead of invalidating
        if (queryKey) {
          queryClient.setQueryData(
            Array.isArray(queryKey) ? queryKey : [queryKey],
            response.data
            // (oldData) => {
            //   if (Array.isArray(oldData)) {
            //     return [...oldData, response.data];
            //   }

            //   return response.data;
            // }
          );
        }
      }
      return response;
    },
    //onError: handleError,
  });

  const batchMutation = useMutation({
    mutationFn: async (requests) => {
      const results = await Promise.all(
        requests.map(async (params) => {
          try {
            return await fetcher(...params);
          } catch (err) {
            console.error('Batch API Error:', err);
            return null;
          }
        })
      );
      const filteredResults = results.filter(Boolean);
      return filteredResults.map((res) => res.data);
    },
    onSuccess: (data) => {
      handleSuccess(data);
      if (queryKey) {
        queryClient.setQueryData(
          Array.isArray(queryKey) ? queryKey : [queryKey],
          data
        );
      }
    },
    //onError: handleError,
  });

  const call = useErrorHandler(async (...params) => {
    return mutation.mutateAsync(params);
  });

  const batchCall = useErrorHandler(async (requests) => {
    return batchMutation.mutateAsync(requests);
  });
  const isFetching =
    query.isLoading ||
    query.isFetching ||
    mutation.isPending ||
    batchMutation.isPending;

  return {
    data: query.data,
    mutationData: mutation.data,

    isLoading: query.isLoading || query.fetchStatus === 'fetching', // ✅ for useQuery
    isPending: mutation.isPending, // ✅ for useMutation
    batchLoading: batchMutation.isPending, // ✅ for batchCall
    loading: isFetching, //✅ for query + mutation + batch + fetch under the hood
    isMutating: mutation.isLoading,
    refetch: query.refetch,

    error: query.error,
    mutationError: mutation.error,

    call,
    batchCall,
    reset: query.reset,
    queryClient,
  };
};

export default useApi;

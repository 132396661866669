import CheckIcon from '@mui/icons-material/Check';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import organizationAPI from 'api/api';
import spinnerData from 'components/lottieConfig/spinner.json';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Lottie from 'react-lottie';
import { TickMarkIcon, UploadIcon } from 'svg/CustomSVGIcon';
import styles from '../styles/Dashboard.module.css';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinnerData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const DragAndDrop = ({ name, setName, onDrop }) => {
  const [files, setFiles] = useState([]);

  const onDropCallback = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        onDrop(acceptedFiles);
      }
      setFiles(acceptedFiles);
      setName(acceptedFiles[0].name);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
  });

  const removeFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  return (
    <div className={styles.dropBox}>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #ccc',
          padding: '20px',
          color: '#888',
          textAlign: 'center',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <UploadIcon />
            <p>
              Click to attach your file, or drag and drop it here from your file
              browser. Please uplaod one file at a time.
            </p>
          </>
        )}

        {files.length > 0 && (
          <ul style={{ listStyle: 'none', padding: 0, margin: '0 auto' }}>
            {files.map((file) => (
              <li
                key={file.name}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  {name} - {(file.size / (1024 * 1024)).toFixed(2)} mb
                </div>
                <div>
                  <CloseOutlinedIcon onClick={() => removeFile(file)} />
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const UploadDocument = ({
  onUploadSuccess,
  showUploadModal = false,
  setShowUploadModal,
  showSuccessModal = false,
  setShowSuccessModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [name, setName] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);
  const [files, setFiles] = useState([{}]);

  useEffect(() => {
    if (name) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [name]);

  useEffect(() => {
    if (uploadLoading) {
      const timer = setTimeout(() => {
        if (uploadLoading.step1 === 'waiting') {
          setUploadLoading({
            step1: 'working',
            step2: 'waiting',
            step3: 'waiting',
          });
        } else if (uploadLoading.step1 === 'working') {
          setUploadLoading({
            step1: 'done',
            step2: 'working',
            step3: 'waiting',
          });
        } else if (uploadLoading.step2 === 'working') {
          setUploadLoading({
            step1: 'done',
            step2: 'done',
            step3: 'working',
          });
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [uploadLoading]);

  const handleDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const handleUploadDocument = async (e) => {
    e.preventDefault();
    setUploadLoading({
      step1: 'waiting',
      step2: 'waiting',
      step3: 'waiting',
    });

    try {
      const rule = await organizationAPI.getResumeRuleId();
      let ruleId = rule.data.ruleId;

      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('ruleId', ruleId);
      formData.append('filename', files[0].name);

      await organizationAPI.documentAnalyzerUploadDocument(formData);

      setUploadLoading(false);
      setShowUploadModal(false);
      setShowSuccessModal(true);

      // Call the callback function to refresh the document list
      if (onUploadSuccess) {
        onUploadSuccess();
      }
    } catch (error) {
      console.log(error);
      setUploadLoading(false);
    }
  };

  return (
    <>
      {/* Upload Document Modal */}
      <Modal
        open={showUploadModal}
        onClose={uploadLoading ? null : () => setShowUploadModal(false)}
        aria-labelledby="upload-document-modal"
        aria-describedby="upload-document-modal-description"
      >
        <Box className={styles.modal_view}>
          {uploadLoading ? (
            <div className={styles.modal_content}>
              <div className={styles.file_uploading}>
                <h3>
                  <Lottie
                    options={defaultOptions}
                    height={20}
                    width={20}
                    style={{
                      margin: 0,
                    }}
                  />
                  Preparing document for analysis, please wait...
                </h3>
                <p>
                  {uploadLoading.step1 === 'waiting' && <PendingOutlinedIcon />}
                  {uploadLoading.step1 === 'working' && (
                    <Lottie
                      options={defaultOptions}
                      height={20}
                      width={20}
                      style={{
                        margin: 0,
                      }}
                    />
                  )}
                  {uploadLoading.step1 === 'done' && <CheckIcon />}
                  Uploading document
                </p>
                <p>
                  {uploadLoading.step2 === 'waiting' && <PendingOutlinedIcon />}
                  {uploadLoading.step2 === 'working' && (
                    <Lottie
                      options={defaultOptions}
                      height={20}
                      width={20}
                      style={{
                        margin: 0,
                      }}
                    />
                  )}
                  {uploadLoading.step2 === 'done' && <CheckIcon />}
                  Initializing AI analysis engine
                </p>
                <p>
                  {uploadLoading.step3 === 'waiting' && <PendingOutlinedIcon />}
                  {uploadLoading.step3 === 'working' && (
                    <Lottie
                      options={defaultOptions}
                      height={20}
                      width={20}
                      style={{
                        margin: 0,
                      }}
                    />
                  )}
                  Parsing document contents
                </p>
              </div>
            </div>
          ) : (
            <>
              <h2>Upload New Document</h2>
              <DragAndDrop name={name} setName={setName} onDrop={handleDrop} />
              <div>
                <FormControl
                  className={styles.dropdown_section}
                  sx={{
                    width: '100%',
                  }}
                >
                  {/* This is intentionally left empty, as in the original code */}
                </FormControl>
              </div>
              <button
                className={styles.start}
                disabled={buttonDisable}
                onClick={handleUploadDocument}
              >
                Confirm and Upload
              </button>
            </>
          )}
        </Box>
      </Modal>

      {/* Success Modal */}
      <Modal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        aria-labelledby="success-modal"
        aria-describedby="success-modal-description"
      >
        <Box className={styles.modal_view}>
          <div className={styles.modal_upload}>
            <TickMarkIcon />
            <h2>Document Uploaded</h2>
            <p>Your document has been successfully uploaded.</p>
            <button
              className={styles.ok_button}
              onClick={() => setShowSuccessModal(false)}
            >
              Ok
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default UploadDocument;

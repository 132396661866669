import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormControl, InputAdornment } from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DoneIcon from '@mui/icons-material/Done';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StartIcon from '@mui/icons-material/Start';

import { CoachIcon, NFTEAIIcon } from 'svg/CustomSVGIcon';

import organizationAPI from 'api/api';
import ErrorOverlay from 'components/ErrorOverlay';

import CheckIcon from '@mui/icons-material/Check';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { CustomButton } from 'components/Button';
import { LoadingSystem } from 'components/CustomMUI';
import { LoaderVariants } from 'components/CustomMUI/LoadingSystem';
import { MultipleFilterSelect } from 'components/SelectField';
import { CustomTextField } from 'components/TextField';
import spinnerData from 'components/lottieConfig/spinner.json';
import { useLoggedUser } from 'contexts/UserContext';
import { useFetcher } from 'hooks/useFetcher';
import Lottie from 'react-lottie';
import '../styles/postQus.css';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinnerData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const convertDate = (dateString) => {
  return moment(dateString).format('dddd • MMMM D, YYYY • h:mm A');
};

const PersonReply = ({ name, reply, avatar }) => {
  return (
    <>
      <div className="person">
        <div className="personHeader">
          {avatar ? (
            <img
              src={avatar ? avatar : './male.jpg'}
              alt="Person"
              referrerpolicy="no-referrer"
            />
          ) : (
            <AccountCircleIcon style={{ color: '#4c12a1' }} />
          )}
          <span className="personNameText">{name}</span>
        </div>
      </div>

      <p>{reply}</p>
    </>
  );
};

const Question = ({
  name,
  date,
  data,
  question_id,
  isStarred,
  isQuestionClosed,
  organization_id,
  getQuestions,
  questionIsForNFTEAI,
}) => {
  // const [data, setData] = useState([]);
  const [reply, setReply] = useState('');
  const [star, setStar] = useState(isStarred);
  const [errorVisible, setErrorVisible] = useState(false);
  const [replyStatus, setReplyStatus] = useState(data[data.length - 1]?.status);
  const [uploadLoading, setUploadLoading] = useState(false);
  const { user } = useLoggedUser();

  useEffect(() => {
    if (uploadLoading) {
      const timer = setTimeout(() => {
        if (uploadLoading.step1 === 'waiting') {
          setUploadLoading({
            step1: 'working',
            step2: 'waiting',
            step3: 'waiting',
          });
        } else if (uploadLoading.step1 === 'working') {
          setUploadLoading({
            step1: 'done',
            step2: 'working',
            step3: 'waiting',
          });
        } else if (uploadLoading.step2 === 'working') {
          setUploadLoading({
            step1: 'done',
            step2: 'done',
            step3: 'working',
          });
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [uploadLoading]);

  useEffect(() => {
    setReplyStatus(data[data.length - 1]?.status);
  }, [data]);

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const { call: askQuestion } = useFetcher({
    fetcher: organizationAPI.askQuestion,
  });

  const { call: updateStar } = useFetcher({
    fetcher: organizationAPI.updateStar,
    loaderId: 'updateStar',
    loaderLabel: 'Successfully updated star ',
    loaderStyle: 'checklist',
  });

  const { call: updateQuestionClose } = useFetcher({
    fetcher: organizationAPI.updateQuestionClose,
    loaderId: 'updateQuestionClose',
    loaderLabel: 'Successfully updated & closed thread',
    loaderStyle: 'checklist',
  });

  const [confirm, setConfirm] = useState(isQuestionClosed);
  const handleReply = async (e) => {
    e.preventDefault();
    setUploadLoading({
      step1: 'waiting',
      step2: 'waiting',
      step3: 'waiting',
    });

    const data = {
      question: reply,
      question_id,
      organization_id,
      questionIsForNFTEAI,
      student: {
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
      },
    };

    setReply('');

    try {
      await askQuestion(data);
    } catch (error) {
      setErrorVisible(true);
    }
    setUploadLoading(false);

    await getQuestions();
  };

  const handleStarChange = async (e) => {
    e.preventDefault();
    const data = {
      question_id,
      flag: !star,
    };
    try {
      await updateStar(data);
    } catch (error) {
      setErrorVisible(true);
    }
    setStar(!star);
  };

  const handleCloseQuestionChange = async (e) => {
    e.preventDefault();

    const data = {
      question_id,
      flag: !confirm,
    };
    try {
      await updateQuestionClose(data);
    } catch (error) {
      setErrorVisible(true);
    }
    setConfirm(!confirm);
    getQuestions();
  };

  return (
    <div className="sub_container_right">
      {errorVisible && (
        <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />
      )}
      <h2>{date}</h2>
      <div className="star">
        <h6>{name}</h6>

        {star ? (
          <StarIcon onClick={handleStarChange} />
        ) : (
          <StarBorderIcon onClick={handleStarChange} />
        )}
      </div>

      {data.length === 0 && (
        <p>
          Your question is currently being processed. You will receive a
          response from Coach soon.
        </p>
      )}

      {data.length > 0 &&
        data.map((item, index) => {
          const name = item.user?.user
            ? `${item.user.user.firstName} ${item.user.user.lastName}`
            : 'NFTE AI';

          return (
            index !== 0 && (
              <PersonReply
                avatar={item.user?.user?.avatar}
                name={name}
                reply={item.text}
              />
            )
          );
        })}

      <div className="border"></div>

      {uploadLoading && (
        <div className="file_uploading">
          <h3>
            <Lottie
              options={defaultOptions}
              height={20}
              width={20}
              style={{
                margin: 0,
              }}
            />
            Preparing answer for your query, please wait...
          </h3>
          <p>
            {' '}
            {uploadLoading.step1 === 'waiting' && <PendingOutlinedIcon />}
            {uploadLoading.step1 === 'working' && (
              <Lottie
                options={defaultOptions}
                height={20}
                width={20}
                style={{
                  margin: 0,
                }}
              />
            )}
            {uploadLoading.step1 === 'done' && <CheckIcon />}
            Processing your response
          </p>
          <p>
            {uploadLoading.step2 === 'waiting' && <PendingOutlinedIcon />}
            {uploadLoading.step2 === 'working' && (
              <Lottie
                options={defaultOptions}
                height={20}
                width={20}
                style={{
                  margin: 0,
                }}
              />
            )}
            {uploadLoading.step2 === 'done' && <CheckIcon />}
            Initializing AI analysis engine
          </p>
          <p>
            {uploadLoading.step3 === 'waiting' && <PendingOutlinedIcon />}
            {uploadLoading.step3 === 'working' && (
              <Lottie
                options={defaultOptions}
                height={20}
                width={20}
                style={{
                  margin: 0,
                }}
              />
            )}
            Loading answer
          </p>
        </div>
      )}

      {!confirm && replyStatus !== 'gpt' && (
        <>
          <h4>Post Reply</h4>
          <CustomTextField
            autoFocus
            multiline
            minRows={5}
            label={'Reply'}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          />
        </>
      )}
      <div className="bottom">
        <div className="bottom_section_button">
          {confirm ? (
            <p className="confirm_msg">
              <DoneIcon /> Thread confirmed and closed.
            </p>
          ) : (
            <>
              {replyStatus !== 'gpt' && (
                <CustomButton disabled={!reply} onClick={(e) => handleReply(e)}>
                  <SendIcon />
                  Post Reply
                </CustomButton>
              )}
              <button
                className={`${data.length === 0 ? 'disabled' : 'close'}`}
                onClick={handleCloseQuestionChange}
              >
                <DoneIcon />
                Confirm and Close Thread
              </button>
            </>
          )}
        </div>
        {confirm ? (
          <p className="reopen" onClick={handleCloseQuestionChange}>
            <StartIcon />
            Reopen Issue
          </p>
        ) : (
          <p className="report">
            {/* <ReportGmailerrorredIcon /> Report Issue */}
          </p>
        )}
      </div>
    </div>
  );
};

const PostQuestion = () => {
  const [errorVisible, setErrorVisible] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [search, setSearch] = useState('');
  const { user } = useLoggedUser();
  const [personName, setPersonName] = useState(['All']);
  const [selectedQuestion, setSelectedQuestion] = useState([]);

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const { call: getQuestion } = useFetcher({
    fetcher: organizationAPI.getQuestion,
    successMessage: 'Fetching User Chat',
    loaderId: 'getQuestion',
    loaderLabel: 'Fetching Question Thread... ',
    loaderStyle: 'checklist',
  });

  const getQuestions = async () => {
    if (user) {
      try {
        const response = await getQuestion(user.organization, user._id);

        const reverseData = response.data.reverse();

        if (!selectedQuestionId && reverseData.length > 0)
          setSelectedQuestionId(reverseData[0].question_id);

        if (response && reverseData.length <= 0) {
          navigate('/student/new-query');
        }

        setSelectedQuestion(reverseData);
      } catch (error) {
        console.log(error);
        setErrorVisible(true);
      }
    }
  };

  // useEffect(() => {
  //   if (selectedQuestion.length <= 0) {
  //     navigate('/student/new-query');
  //   }
  // }, [selectedQuestion]);

  useEffect(() => {
    getQuestions();
  }, [user?.organization]);

  const handleSelectedQuestion = (question) => {
    let temp = [...selectedQuestion];

    temp.map((item) => {
      if (item.question_id === question.question_id) {
        setSelectedQuestionId(item.question_id);
      }
    });
    setSelectedQuestion(temp);
  };

  const navigate = useNavigate();

  const handleQuery = (e) => {
    e.preventDefault();
    navigate('/student/new-query');
  };

  return (
    <>
      <LoadingSystem
        variant={LoaderVariants.CHECKLIST}
        title={`Loading ${user.role}'s Chat Thread`}
      />
      <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />

      <div className="query__container__header">
        <h4>Chat</h4>
        <div className="query_header__right">
          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <MultipleFilterSelect
                value={personName}
                setValue={setPersonName}
              />
            </FormControl>
          </div>

          <CustomTextField
            id="input-with-icon-textfield"
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <CustomButton onClick={handleQuery}>
            <QuizOutlinedIcon /> New Chat
          </CustomButton>
        </div>
      </div>
      <div className="border"></div>

      <div className="query__container__body">
        <h4>Your Chat Archive</h4>
        <p>
          Welcome to your chat archive, featuring all your queries and their
          discussion threads.
        </p>
      </div>
      {selectedQuestion.length > 0 ? (
        <div className="sub_container">
          <div className="sub_container_left">
            {selectedQuestion
              .filter((item) => {
                if (personName.includes('All') || personName.length === 0) {
                  return true;
                }
                if (personName.includes('Open')) {
                  return !item.isQuestionClosed;
                }
                if (personName.includes('Starred')) {
                  return item.isStarred;
                }
                if (personName.includes('Closed')) {
                  return item.isQuestionClosed;
                }
                if (personName.includes('NFTE AI')) {
                  return item.isAI;
                }
                if (personName.includes('Coach')) {
                  return !item.isAI;
                }
              })
              .filter((item) => {
                if (search === '') {
                  return true;
                } else {
                  return item.question
                    .toLowerCase()
                    .includes(search.toLowerCase());
                }
              })
              .map((item) => {
                if (item.question_id === selectedQuestionId) {
                  return (
                    <div
                      className="sub_container_left_bottom"
                      onClick={() => handleSelectedQuestion(item)}
                    >
                      <p>{convertDate(item.createdAt)}</p>
                      <h5>
                        {item.question.length > 60
                          ? item.question.substring(0, 60) + '...'
                          : item.question}
                      </h5>
                      <p>
                        {item.isAI ? (
                          <NFTEAIIcon fill={'#F3F8F8'} />
                        ) : (
                          <CoachIcon fill={'#F3F8F8'} />
                        )}
                        {item.isAI ? 'NFTE AI' : 'Coach'}
                      </p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="sub_container_left_top"
                      onClick={() => handleSelectedQuestion(item)}
                    >
                      <p>{convertDate(item.createdAt)}</p>
                      <h5>
                        {item.question.length > 60
                          ? item.question.substring(0, 60) + '...'
                          : item.question}
                      </h5>
                      <p>
                        {item.isAI ? (
                          <NFTEAIIcon fill={'#8CA19F'} />
                        ) : (
                          <CoachIcon fill={'#8CA19F'} />
                        )}
                        {item.isAI ? 'NFTE AI' : 'Coach'}
                      </p>
                    </div>
                  );
                }
              })}
          </div>
          <div className="sub_container_right_section">
            {selectedQuestion
              .filter((item) => {
                if (personName.includes('All') || personName.length === 0) {
                  return true;
                }
                if (personName.includes('Starred')) {
                  return item.isStarred;
                }
                if (personName.includes('Closed')) {
                  return item.isQuestionClosed;
                }
                if (personName.includes('Open')) {
                  return !item.isQuestionClosed;
                }
                if (personName.includes('NFTE AI')) {
                  return item.isAI;
                }
                if (personName.includes('Coach')) {
                  return !item.isAI;
                }
              })
              .filter((item) => {
                if (search === '') {
                  return true;
                } else {
                  return item.question
                    .toLowerCase()
                    .includes(search.toLowerCase());
                }
              })
              .map((item) => {
                if (item.question_id === selectedQuestionId) {
                  return (
                    <Question
                      key={item.question_id}
                      question={item}
                      name={item.question}
                      date={convertDate(item.createdAt)}
                      data={item.data}
                      question_id={item.question_id}
                      isStarred={item.isStarred}
                      isQuestionClosed={item.isQuestionClosed}
                      organization_id={user?.organization}
                      getQuestions={getQuestions}
                      questionIsForNFTEAI={item.isAI}
                    />
                  );
                }
              })}
          </div>
        </div>
      ) : (
        <div className="query__container__body__box">
          <div className="empty">
            <p className="empty_text">There are no chat yet.</p>
            <p className="query_link" onClick={handleQuery}>
              Click here to make a new Chat.
            </p>
          </div>
        </div>
      )}

      {selectedQuestion.length > 0 &&
        selectedQuestion
          .filter((item) => {
            if (personName.includes('All') || personName.length === 0) {
              return true;
            }
            if (personName.includes('Starred')) {
              return item.isStarred;
            }
            if (personName.includes('Closed')) {
              return item.isQuestionClosed;
            }
            if (personName.includes('Open')) {
              return !item.isQuestionClosed;
            }
            if (personName.includes('NFTE AI')) {
              return item.isAI;
            }
            if (personName.includes('Coach')) {
              return !item.isAI;
            }
          })
          .filter((item) => {
            if (search === '') {
              return true;
            } else {
              return item.question.toLowerCase().includes(search.toLowerCase());
            }
          }).length === 0 && (
          <div className="query__container__body__box">
            <p className="empty_text">There are no queries for the filter.</p>
          </div>
        )}
    </>
  );
};

export default PostQuestion;

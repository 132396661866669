import { FormControl, InputAdornment } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import StartIcon from '@mui/icons-material/Start';

import { CoachIcon, NFTEAIIcon } from 'svg/CustomSVGIcon';

import organizationAPI from 'api/api';
import ErrorOverlay from 'components/ErrorOverlay';

import { CustomButton } from 'components/Button';
import { LoadingSystem } from 'components/CustomMUI';
import { LoaderVariants } from 'components/CustomMUI/LoadingSystem';
import { MultipleFilterSelect } from 'components/SelectField';
import { CustomTextField } from 'components/TextField';
import { useLoggedUser } from 'contexts/UserContext';
import { useFetcher } from 'hooks/useFetcher';
import '../styles/postQus.css';

// Cache for storing questions
const questionsCache = {
  data: null,
  timestamp: null,
  organizationId: null,
  userId: null,
};

// Cache expiration time in milliseconds (e.g., 5 minutes)
const CACHE_EXPIRATION_TIME = 5 * 60 * 1000;

const convertDate = (dateString) => {
  return moment(dateString).format('dddd • MMMM D, YYYY • h:mm A');
};

const PersonReply = ({ name, reply, avatar, color = '#4c12a1' }) => {
  return (
    <>
      <div className="person">
        <div className="personHeader">
          {avatar ? (
            <img src={avatar} alt="Person" referrerpolicy="no-referrer" />
          ) : (
            <AccountCircleIcon style={{ color: color }} />
          )}
          <span className="personNameText">{name}</span>
        </div>
      </div>

      <p>{reply}</p>
    </>
  );
};

const Question = ({
  user,
  name,
  date,
  data,
  question_id,
  isAI,
  isStarred,
  isQuestionClosed,
  getQuestions,
}) => {
  const gptReply =
    data[data.length - 1].status === 'gpt' ? data[data.length - 1].text : '';
  const [reply, setReply] = useState(gptReply);
  const [star, setStar] = useState(isStarred);
  const [errorVisible, setErrorVisible] = useState(false);

  const thread_question_id = data[data.length - 1].isThread
    ? data[data.length - 1]._id
    : '';

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const { call: coachFeedback } = useFetcher({
    fetcher: organizationAPI.coachFeedback,
    successMessage: 'Successfully submitted answer.',
    loaderId: 'coachFeedback',
    loaderLabel: 'Submitting answer...',
    loaderStyle: 'checklist',
  });

  const { call: updateStar } = useFetcher({
    fetcher: organizationAPI.updateStar,
    loaderId: 'updateStar',
    loaderLabel: 'Updating star...',
    loaderStyle: 'checklist',
  });

  const { call: updateQuestionClose } = useFetcher({
    fetcher: organizationAPI.updateQuestionClose,
    successMessage: 'Successfully closed thread...',
    loaderId: 'updateQuestionClose',
    loaderLabel: 'Updating question thread...',
    loaderStyle: 'checklist',
  });

  const [confirm, setConfirm] = useState(isQuestionClosed);
  const handleReply = async (e) => {
    e.preventDefault();
    const tempData = {
      feedback: reply,
      parent_question_id: question_id,
      student: data[0].user.user,
      coach: {
        name: user.firstName + ' ' + user.lastName,
      },
      questionTitle: data[0].text,
    };

    if (thread_question_id) tempData.thread_question_id = thread_question_id;

    try {
      await coachFeedback(tempData);
      // Clear cache on successful update
      questionsCache.data = null;
    } catch (error) {
      setErrorVisible(true);
    }
    setReply('');
    getQuestions();
  };

  const handleStarChange = async (e) => {
    e.preventDefault();
    const data = {
      question_id,
      flag: !star,
    };
    try {
      await updateStar(data);
      // Clear cache on successful update
      questionsCache.data = null;
    } catch (error) {
      setErrorVisible(true);
    }
    setStar(!star);
  };

  const handleCloseQuestionChange = async (e) => {
    e.preventDefault();
    const data = {
      question_id,
      flag: !confirm,
    };
    try {
      await updateQuestionClose(data);
      // Clear cache on successful update
      questionsCache.data = null;
    } catch (error) {
      setErrorVisible(true);
    }
    setConfirm(!confirm);
    getQuestions();
  };

  return (
    <div className="sub_container_right">
      {errorVisible && (
        <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />
      )}
      <h2>{date}</h2>
      <div className="star">
        <h6>{name}</h6>

        {/* {star ? (
          <StarIcon onClick={handleStarChange} />
        ) : (
          <StarBorderIcon onClick={handleStarChange} />
        )} */}
      </div>

      {data.length === 0 && (
        <p>
          Your question is currently being processed. You will receive a
          response from Coach soon.
        </p>
      )}

      {data.length > 0 &&
        data.map((item, index) => {
          const name = item.user?.user
            ? `${item.user.user.firstName} ${item.user.user.lastName}`
            : 'NFTE AI';

          return item.user?.user ? (
            <PersonReply
              key={index}
              // avatar={item.user?.user?.avatar}
              avatar={null}
              name={name}
              reply={item.text}
              color="#0086c4"
            />
          ) : (
            isAI && (
              <PersonReply
                key={index}
                avatar={null}
                name={'NFTE AI'}
                reply={item.text}
              />
            )
          );
        })}

      <div className="border"></div>

      {!confirm && !isAI && (
        <>
          <h4>Post Reply</h4>

          <CustomTextField
            autoFocus
            multiline
            minRows={5}
            label={'Reply'}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          />
        </>
      )}
      <div className="bottom">
        <div className="bottom_section_button">
          {confirm ? (
            <p className="confirm_msg">
              <DoneIcon /> Thread confirmed and closed.
            </p>
          ) : (
            !isAI && (
              <>
                <CustomButton disabled={!reply} onClick={handleReply}>
                  <SendIcon />
                  Post Reply
                </CustomButton>
                <button
                  className={`${data.length === 0 ? 'disabled' : 'close'}`}
                  onClick={handleCloseQuestionChange}
                >
                  <DoneIcon />
                  Confirm and Close Thread
                </button>
              </>
            )
          )}
        </div>
        {confirm ? (
          <p className="reopen" onClick={handleCloseQuestionChange}>
            <StartIcon />
            Reopen Issue
          </p>
        ) : (
          <p className="report">
            {/* <ReportGmailerrorredIcon /> Report Issue */}
          </p>
        )}
      </div>
    </div>
  );
};

const CoachQueryArchive = () => {
  const [errorVisible, setErrorVisible] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [search, setSearch] = useState('');
  const [personName, setPersonName] = useState(['All']);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const { user } = useLoggedUser();
  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const { call: getCoachQuestion } = useFetcher({
    fetcher: organizationAPI.getCoachQuestion,
    successMessage: 'Successfully fetched all the questions',
    loaderId: 'getCoachQuestion',
    loaderLabel: 'Fetching Questions ...',
    loaderStyle: 'checklist',
  });
  // Check if cache is valid
  const isCacheValid = useCallback(() => {
    if (!questionsCache.data || !questionsCache.timestamp) return false;
    if (
      questionsCache.organizationId !== user?.organization ||
      questionsCache.user?._id !== user?._id
    )
      return false;

    const now = new Date().getTime();
    return now - questionsCache.timestamp < CACHE_EXPIRATION_TIME;
  }, [user]);

  // Fetch questions with cache implementation
  const getQuestions = useCallback(async () => {
    if (!user?.organization || !user?._id) return;

    // Use cache if valid
    if (isCacheValid()) {
      setSelectedQuestion(questionsCache.data);
      if (!selectedQuestionId && questionsCache.data.length > 0) {
        setSelectedQuestionId(questionsCache.data[0].question_id);
      }
      return;
    }

    try {
      const response = await getCoachQuestion(user?.organization, user?._id);

      const reverseData = response.data.reverse();

      // Update cache
      questionsCache.data = reverseData;
      questionsCache.timestamp = new Date().getTime();
      questionsCache.organizationId = user?.organization;
      questionsCache.userId = user?._id;

      if (!selectedQuestionId && reverseData.length > 0) {
        setSelectedQuestionId(reverseData[0].question_id);
      }

      setSelectedQuestion(reverseData);
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
    }
  }, [user, selectedQuestionId, isCacheValid]);

  useEffect(() => {
    if (user?.organization && user?._id) {
      getQuestions();
    }
  }, [user, getQuestions]);

  const handleSelectedQuestion = (question) => {
    setSelectedQuestionId(question.question_id);
  };

  // Memoize filtered questions to prevent unnecessary re-renders
  const filteredQuestions = useMemo(() => {
    return selectedQuestion
      .filter((item) => {
        if (personName.includes('All') || personName.length === 0) {
          return true;
        }
        if (personName.includes('Open')) {
          return !item.isQuestionClosed;
        }
        if (personName.includes('Starred')) {
          return item.isStarred;
        }
        if (personName.includes('Closed')) {
          return item.isQuestionClosed;
        }
        if (personName.includes('NFTE AI')) {
          return item.isAI;
        }
        if (personName.includes('Coach')) {
          return !item.isAI;
        }
        return false;
      })
      .filter((item) => {
        if (search === '') {
          return true;
        } else {
          return item.question.toLowerCase().includes(search.toLowerCase());
        }
      });
  }, [selectedQuestion, personName, search]);

  // Memoize selected question for better performance
  const currentSelectedQuestion = useMemo(() => {
    return filteredQuestions.find(
      (item) => item.question_id === selectedQuestionId
    );
  }, [filteredQuestions, selectedQuestionId]);

  return (
    <>
      <LoadingSystem
        variant={LoaderVariants.CHECKLIST}
        title={`Loading ${user.role}'s Chat...`}
      />
      <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />

      <div className="query__container__header">
        <h4>Query</h4>
        <div className="query_header__right">
          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <MultipleFilterSelect
                value={personName}
                setValue={setPersonName}
              />
            </FormControl>
          </div>

          <CustomTextField
            id="input-with-icon-textfield"
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="border"></div>

      <div className="query__container__body">
        <h4>Your Chat Archive</h4>
        <p>
          Welcome to your chat archive, featuring all your queries and their
          discussion threads.
        </p>
      </div>
      {selectedQuestion.length > 0 ? (
        <div className="sub_container">
          <div className="sub_container_left">
            {filteredQuestions.map((item) => (
              <div
                key={item.question_id}
                className={
                  item.question_id === selectedQuestionId
                    ? 'sub_container_left_bottom'
                    : 'sub_container_left_top'
                }
                onClick={() => handleSelectedQuestion(item)}
              >
                <p>{convertDate(item.createdAt)}</p>
                <h5>
                  {item.question.length > 55
                    ? item.question.substring(0, 55) + '...'
                    : item.question}
                </h5>
                <p>
                  {item.isAI ? (
                    <NFTEAIIcon
                      fill={
                        item.question_id === selectedQuestionId
                          ? '#F3F8F8'
                          : '#8CA19F'
                      }
                    />
                  ) : (
                    <CoachIcon
                      fill={
                        item.question_id === selectedQuestionId
                          ? '#F3F8F8'
                          : '#8CA19F'
                      }
                    />
                  )}
                  {item.isAI ? 'NFTE AI' : 'Coach'}
                </p>
              </div>
            ))}
          </div>
          <div className="sub_container_right_section">
            {currentSelectedQuestion && (
              <Question
                key={currentSelectedQuestion.question_id}
                user={user}
                name={currentSelectedQuestion.question}
                date={convertDate(currentSelectedQuestion.createdAt)}
                data={currentSelectedQuestion.data}
                question_id={currentSelectedQuestion.question_id}
                isAI={currentSelectedQuestion.isAI}
                isStarred={currentSelectedQuestion.isStarred}
                isQuestionClosed={currentSelectedQuestion.isQuestionClosed}
                getQuestions={getQuestions}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="query__container__body__box">
          <p className="empty_text">There are no chat yet.</p>
        </div>
      )}

      {selectedQuestion.length > 0 && filteredQuestions.length === 0 && (
        <div className="query__container__body__box">
          <p className="empty_text">There are no chat for the filter.</p>
        </div>
      )}
    </>
  );
};

export default CoachQueryArchive;

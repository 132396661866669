export const areModuleArraysEqual = (arr1 = [], arr2 = []) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((module1) => {
    const matchingModule = arr2.find(
      (module2) => module2.moduleId === module1.moduleId
    );
    if (!matchingModule) {
      return false;
    }

    const sections1 = new Set(module1.sections);
    const sections2 = new Set(matchingModule.sections);

    if (sections1.size !== sections2.size) {
      return false;
    }

    return [...sections1].every((section) => sections2.has(section));
  });
};

export const formatDuration = (minutes) => {
  if (isNaN(minutes) || minutes === 0) return '0 minutes';
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${
      remainingMinutes > 0
        ? `${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`
        : ''
    }`;
  }
  return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
};

export const getStyles = () => {
  const sectionContainer = {
    alignItems: 'center',
    marginLeft: '20px',
    width: '350px',
  };
  const sectionTitle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
  };

  const progressBar = {
    width: '90%',
    height: 8,
    borderRadius: 4,
    bgcolor: '#F2F4F7',
    '& .MuiLinearProgress-bar': {
      bgcolor: '#2AA87E',
      borderRadius: 4,
    },
    border: '2px solid #DAE8E7',
  };

  const translation = {
    backdrop: {
      color: '#fff',
      zIndex: (theme) => theme.zIndex.drawer + 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    },

    container: {
      p: 3,
      bgcolor: '#fff',
      minHeight: '100vh',
      border: '1px solid #DAE8E7',
      borderRadius: '8px',
    },

    accordion: {
      boxShadow: 'none',
      border: '1px solid #DAE8E7',
      borderRadius: '8px',
    },
    accordionDetails: {
      pl: 4,
      border: 'none',
    },
    accordionSummary: {
      '&::before': { display: 'none' },
    },
    accordionTitle: {
      fontWeight: 500,
      mb: 1,
    },
    accordionSubTitle: {
      fontWeight: 400,
      mb: 1,
    },
    accordionContent: {
      pl: 2,
      mb: 2,
    },
    accordionSubContent: {
      ml: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
    },
    translationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 3,
      gap: 2,
    },
    translationHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      width: '100%',
      height: 50,
    },
    divider: {
      border: '1px solid #dae8e7',
      my: '10px',
      mb: '30px',
    },
    moduleContainer: {
      display: 'flex',
      position: 'relative',
      alignItems: 'start',
    },
    moduleContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      justifyContent: 'start',
    },
    accordionContainer: {
      mb: 4,
      gap: 2,
      display: 'flex',
      flexDirection: 'column',
    },
  };

  return { sectionTitle, sectionContainer, progressBar, translation };
};

import { ExpandMore } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import { FormFields } from 'components/CustomMUI';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { handleExportPPTX } from 'utils';

const SubSection = ({
  data,
  index,
  disabled,
  onChange,
  formData,
  sectionId,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { call: updateSubSection, isLoading: isSubSectionUpdating } = useApi({
    fetcher: apis.updateModuleSubSection,
    successMessage: 'Sub-section updated successfully',
  });

  const handleSubSectionSubmit = async (subSectionId) => {
    const subSection = formData.sections
      ?.flatMap((s) => s.subSections || [])
      .find((ss) => ss._id === subSectionId);

    const payload = {
      title: subSection.title,
      notes: subSection.notes,
    };

    const response = await updateSubSection({
      data: payload,
      moduleId: formData._id,
      sectionId: sectionId,
      subSectionId: subSection._id,
    });
    console.log('SUB-SECTION RESPONSE: ', response);
  };

  return (
    <Accordion
      key={index}
      sx={{
        '& .MuiAccordionSummary-content': { margin: 0 },
        boxShadow: 'none',
        border: '1px solid #DAE8E7',
        borderRadius: '8px',
        '&::before': { display: 'none' },
      }}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          '& .MuiAccordionSummary-content': {
            margin: '0 !important',
            marginRight: '16px !important',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography
            variant="h6"
            sx={{ fontSize: '18px', fontWeight: 400, flexGrow: 1 }}
          >
            Sub-Section {index + 1}
          </Typography>
          {!disabled && (
            <Box sx={{ mr: 1 }}>
              <Button
                sx={{
                  fontSize: '12px',
                  minWidth: 'auto',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                startIcon={<CheckIcon sx={{ fontSize: '12px' }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSubSectionSubmit(data._id);
                }}
              >
                SAVE
              </Button>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mb: 3 }}>
          <FormFields
            type="textfield"
            name="title"
            label="SUBMODULE TITLE"
            formData={data}
            handleChange={onChange}
            disabled={disabled}
          />
        </Box>
        <Box>
          <FormFields
            type="textarea"
            name="notes"
            label="SUBMODULE CONTENT"
            formData={data}
            handleChange={onChange}
            rows={4}
            disabled={disabled}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const Section = ({
  data,
  index,
  disabled,
  onChange,
  formData,
  setFormData,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { call: updateSection, isLoading: isSectionUpdating } = useApi({
    fetcher: apis.updateModuleSection,
    successMessage: 'Section updated successfully',
  });

  const handleSubSectionChange = (subSectionId, name, value) => {
    setFormData((prev) => ({
      ...prev,
      sections:
        prev.sections?.map((section) => ({
          ...section,
          subSections:
            section.subSections?.map((subSection) =>
              subSection._id === subSectionId
                ? { ...subSection, [name]: value }
                : subSection
            ) || [],
        })) || [],
    }));
  };

  const handleSection = async (sectionId) => {
    const section = formData.sections?.find((s) => s._id === sectionId);
    const payload = {
      activity: section.activity,
      minutes: section.minutes,
      title: section.title,
    };
    const response = await updateSection({
      data: payload,
      moduleId: formData._id,
      sectionId: sectionId,
    });
    console.log('SECTION RESPONSE: ', response);
  };

  return (
    <Accordion
      key={index}
      sx={{
        '& .MuiAccordionSummary-content': { margin: 0 },
        boxShadow: 'none',
        border: '1px solid #DAE8E7',
        borderRadius: '8px',
        '&::before': { display: 'none' },
      }}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          '& .MuiAccordionSummary-content': {
            margin: '0 !important',
            marginRight: '16px !important',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography
            variant="h6"
            sx={{ fontSize: '18px', fontWeight: 400, flexGrow: 1 }}
          >
            Section {index + 1}
          </Typography>
          {!disabled && (
            <Box sx={{ mr: 1 }}>
              <Button
                sx={{
                  fontSize: '12px',
                  minWidth: 'auto',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                startIcon={<CheckIcon sx={{ fontSize: '12px' }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSection(data._id);
                }}
              >
                SAVE
              </Button>
            </Box>
          )}
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Box sx={{ mb: 3 }}>
          <FormFields
            type="textfield"
            name="title"
            label="SECTION TITLE"
            formData={data}
            handleChange={onChange}
            disabled={disabled}
          />
        </Box>
        {data.subSections?.length > 0 &&
          data.subSections.map((subSection, subIndex) => (
            <SubSection
              data={subSection}
              index={subIndex}
              disabled={disabled}
              onChange={(name, value) =>
                handleSubSectionChange(subSection._id, name, value)
              }
              formData={formData}
              sectionId={data._id}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};
const ModuleContent = ({
  title,
  isTranslated,
  data: initialData,
  isReadOnly = false,
  disabled = false,
  exportable = false,
}) => {
  const [data, setData] = useState(initialData || {});
  const [activities, setActivities] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [exporting, setExporting] = useState(false);
  const { call: updateModule } = useApi({
    fetcher: apis.updateModule,
    successMessage: 'Module updated successfully',
  });

  const { call: generatePPT } = useApi({
    fetcher: apis.generatePPT,
    successMessage: 'Presentation generated successfully',
  });

  const { call: updateActivities, isLoading: isSectionUpdating } = useApi({
    fetcher: apis.updateModuleSection,
    successMessage: 'Activities updated successfully',
  });

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  useEffect(() => {
    if (data?.sections) {
      const extractedActivities = data.sections
        .map((section) => ({
          name: section.activity || '',
          duration: section.minutes?.toString() || '0',
          sectionId: section._id,
        }))
        .filter((activity) => activity.name || activity.duration !== '0');

      setActivities(extractedActivities);
    }
  }, [data?.sections]);

  const handleModuleChange = (name, value) => {
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSectionChange = (sectionId, name, value) => {
    setData((prev) => ({
      ...prev,
      sections:
        prev.sections?.map((section) =>
          section._id === sectionId ? { ...section, [name]: value } : section
        ) || [],
    }));
  };

  const handleActivityChange = (index, field, value) => {
    const activity = activities[index];
    const sectionId = activity.sectionId;

    const updatedActivities = activities.map((act, i) => {
      if (i === index) {
        return { ...act, [field]: value };
      }
      return act;
    });
    setActivities(updatedActivities);

    setData((prev) => ({
      ...prev,
      sections: prev.sections?.map((section) => {
        if (section._id === sectionId) {
          return {
            ...section,
            activity: field === 'name' ? value : section.activity,
            minutes: field === 'duration' ? parseInt(value) : section.minutes,
          };
        }
        return section;
      }),
    }));
  };

  const removeActivity = (index) => {
    const activity = activities[index];
    const sectionId = activity.sectionId;
    setData((prev) => ({
      ...prev,
      sections: prev.sections?.map((section) => {
        if (section._id === sectionId) {
          return {
            ...section,
            activity: '',
            minutes: 0,
          };
        }
        return section;
      }),
    }));

    const updatedActivities = activities.filter((_, i) => i !== index);
    setActivities(updatedActivities);

    console.log('Remove activity:', {
      sectionId,
      removedActivity: activity,
    });
  };

  const handleModuleSubmit = async (moduleId) => {
    const payload = {
      title: data?.title,
    };
    const response = await updateModule({
      data: payload,
      moduleId: moduleId,
    });
    console.log('MODULE RESPONSE: ', response);
  };

  const handleActivitiesSubmit = async () => {
    const changedSections = data.sections?.filter((section) => {
      const originalSection = initialData.sections?.find(
        (s) => s._id === section._id
      );
      return (
        originalSection &&
        (originalSection.activity !== section.activity ||
          originalSection.minutes !== section.minutes ||
          originalSection.title !== section.title)
      );
    });

    if (!changedSections?.length) {
      return;
    }

    const results = await Promise.all(
      changedSections.map((section) =>
        updateActivities({
          data: {
            activity: section.activity,
            minutes: section.minutes,
            title: section.title,
          },
          moduleId: data?._id,
          sectionId: section._id,
        })
      )
    );

    console.log('ACTIVITIES RESPONSES:', results);
  };

  const handleExport = async () => {
    setExporting(true);
    try {
      await handleExportPPTX({
        id: data?._id,
        title: data?.title,
        call: generatePPT,
      });
    } finally {
      setExporting(false);
    }
  };

  if (!data || Object.keys(data).length === 0) return null;

  return (
    <Box
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: 1,
        p: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h5"
          sx={{ mb: 2, fontSize: '20px', fontWeight: 300 }}
        >
          {title}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          {exportable && (
            <Button
              startIcon={
                exporting ? (
                  <CircularProgress size={14} />
                ) : (
                  <GetAppIcon sx={{ fontSize: '12px' }} />
                )
              }
              sx={{ color: '#5B706F', fontSize: '12px' }}
              onClick={handleExport}
              disabled={exporting}
            >
              {exporting ? 'EXPORTING' : 'EXPORT'}
            </Button>
          )}

          {!disabled && (
            <Button
              sx={{ fontSize: '12px' }}
              startIcon={<CheckIcon sx={{ fontSize: '12px' }} />}
              onClick={() => handleModuleSubmit(data._id)}
            >
              SAVE
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <FormFields
          type="textfield"
          name="title"
          label="MODULE TITLE"
          formData={data}
          handleChange={handleModuleChange}
          disabled={disabled}
        />
      </Box>

      <Accordion
        sx={{
          '& .MuiAccordionSummary-content': { margin: 0 },
          boxShadow: 'none',
          border: '1px solid #DAE8E7',
          borderRadius: '8px',
          '&::before': { display: 'none' },
        }}
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            '& .MuiAccordionSummary-content': {
              margin: '0 !important',
              marginRight: '16px !important',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography
              variant="h6"
              sx={{ fontSize: '18px', fontWeight: 400, flexGrow: 1 }}
            >
              Activities
            </Typography>
            {!disabled && (
              <Box sx={{ mr: 1 }}>
                <Button
                  sx={{
                    fontSize: '12px',
                    minWidth: 'auto',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  startIcon={<CheckIcon sx={{ fontSize: '12px' }} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleActivitiesSubmit();
                  }}
                >
                  SAVE
                </Button>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Box sx={{ mb: 2 }}>
            {activities.map((activity, index) => (
              <Box
                key={index}
                sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}
              >
                <FormFields
                  type="textfield"
                  name={`activity-${index}-name`}
                  label="Activity Name"
                  formData={{ [`activity-${index}-name`]: activity.name }}
                  handleChange={(_, value) =>
                    handleActivityChange(index, 'name', value)
                  }
                  disabled={disabled}
                />
                <Box
                  display="flex"
                  gap={1}
                  alignItems="center"
                  position="relative"
                >
                  <FormFields
                    type="textfield"
                    name={`activity-${index}-duration`}
                    label="Duration"
                    formData={{
                      [`activity-${index}-duration`]: activity.duration,
                    }}
                    handleChange={(_, value) =>
                      handleActivityChange(index, 'duration', value)
                    }
                    props={{
                      type: 'number',
                      sx: { width: 150 },
                      InputProps: {
                        endAdornment: (
                          <Typography
                            sx={{
                              position: 'absolute',
                              right: 40,
                              fontSize: '11px',
                              zIndex: 0,
                              top: 19,
                              bgcolor: '#02E0D5FF',
                              borderRadius: '14px',
                              padding: '2px 6px',
                              color: '#3A3A3AFF',
                            }}
                          >
                            minutes
                          </Typography>
                        ),
                      },
                    }}
                    disabled={disabled}
                  />
                </Box>
                {/* <IconButton
                  size="medium"
                  onClick={() => removeActivity(index)}
                  disabled={disabled}
                >
                  <Remove />
                </IconButton> */}
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>

      {data?.sections?.length > 0 &&
        data.sections.map((item, index) => (
          <Section
            key={item?._id}
            data={item}
            index={index}
            disabled={disabled}
            onChange={(name, value) =>
              handleSectionChange(item._id, name, value)
            }
            formData={data}
            setFormData={setData}
          />
        ))}
    </Box>
  );
};

export default ModuleContent;

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import useStore from 'store/useStore';
import DynamicLoadingChecklist from './DynamicLoadingChecklist';

const ObjectiveModal = ({ open, handleClose, objective }) => {
  const [objectiveName, setObjectiveName] = useState('');
  const [objectives, setObjectives] = useState([]);
  const { setRender } = useStore();
  const { call: updateObjectives, isPending: isLoading } = useApi({
    fetcher: organizationAPI.updateObjectives,
  });

  const [checklistItems, setChecklistItems] = useState([
    {
      id: 'updateObjectives',
      label: 'Objectives Updated Successfully',
      isComplete: false,
      apiCall: updateObjectives,
    },
  ]);

  const updateChecklistItem = (itemId, isComplete) => {
    setChecklistItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isComplete } : item
      )
    );
  };

  const currentTimestamp = new Date().toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  useEffect(() => {
    setObjectiveName(objective.name);
    setObjectives(objective.objectives);
  }, [objective]);

  const handleAddObjective = () => {
    setObjectives([...objectives, '']);
  };

  const handleRemoveObjective = (index) => {
    const newObjectives = objectives.filter((_, i) => i !== index);
    setObjectives(newObjectives);
  };

  const handleObjectiveChange = (index, value) => {
    const newObjectives = [...objectives];
    newObjectives[index] = value;
    setObjectives(newObjectives);
  };

  const handleObjectiveNameChange = (e) => {
    setObjectiveName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleClose();
    const formData = {
      docId: objective.id,
      name: objectiveName.trim(),
      objectives: objectives,
    };
    try {
      const response = await checklistItems[0].apiCall(formData);
      if (response) {
        updateChecklistItem('updateObjectives', true);
        setRender();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DynamicLoadingChecklist
        isLoading={isLoading}
        checklistItems={checklistItems}
        title="Processing Objectives..."
      />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Build New Objective Set
            </Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ mb: 5 }}>
            <Typography
              sx={{
                color: '#92a7a5',
                fontSize: 14,
                fontWeight: 500,
                mb: 1,
                backgroundColor: '#fff',
                width: 'fit-content',
                zIndex: 1,
                position: 'relative',
                ml: 1,
              }}
            >
              OBJECTIVE SET NAME
            </Typography>
            <TextField
              fullWidth
              value={objectiveName}
              onChange={handleObjectiveNameChange}
              sx={{
                mt: '-20px',
                zIndex: 0,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                },
              }}
            />
          </Box>

          <Box
            sx={{
              border: '1px solid #DAE8E7',
              padding: '10px',
              borderRadius: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  color: '#92a7a5',
                  fontSize: 14,
                  fontWeight: 500,
                  backgroundColor: '#fff',
                  width: 'fit-content',
                  zIndex: 1,
                  position: 'relative',
                  mt: '-25px',
                }}
              >
                OBJECTIVES
              </Typography>
              <Typography
                sx={{
                  color: '#666',
                  fontSize: 12,
                  backgroundColor: '#fff',
                  width: 'fit-content',
                  zIndex: 1,
                  position: 'relative',
                  mt: '-25px',
                }}
              >
                You can edit, reorder or remove the generated objectives, or add
                your own.
              </Typography>
            </Box>

            {objectives.map((objective, index) => (
              <TextField
                key={index}
                fullWidth
                value={objective}
                onChange={(e) => handleObjectiveChange(index, e.target.value)}
                sx={{ mb: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleRemoveObjective(index)}
                        edge="end"
                        sx={{ color: '#666' }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                rows={2}
                multiline
              />
            ))}

            <Button
              startIcon={<AddIcon />}
              onClick={handleAddObjective}
              sx={{
                color: '#00706C',
                textTransform: 'none',
                fontSize: 14,
                padding: '8px 16px',
                border: '1px solid #00706C',
                borderRadius: '5px',
                width: '100%',
                '&:hover': {
                  backgroundColor: 'rgba(0, 163, 163, 0.3)',
                },
              }}
            >
              Add new objective
            </Button>
          </Box>

          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{
              mt: 3,
              backgroundColor: '#00A3A3',
              color: 'white',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#008F8F',
              },
              fontSize: 14,
              padding: '12px',
              borderRadius: '4px',
            }}
          >
            Confirm Objective Set Addition
          </Button>
        </Box>
      </Modal>
    </>
  );
};
export default ObjectiveModal;

export const handleExportPPTX = async ({ id, title, call }) => {
  try {
    const response = await call(id);

    if (response?.data?.url) {
      window.open(response.data.url, '_blank');
    } else {
      console.error('Failed to get the download URL');
    }
  } catch (error) {
    console.error('Error generating PPT:', error);
  }
};

export const updateChecklistItem = ({ id, isComplete, setValue }) => {
  setValue((prevItems) =>
    prevItems.map((item) => (item.id === id ? { ...item, isComplete } : item))
  );
};

export const naturalCompare = (a, b) => {
  // Convert to string and trim
  a = a.toString().trim();
  b = b.toString().trim();

  // Split strings into numeric and non-numeric parts
  const re = /(\d+|\D+)/g;
  const aParts = a.match(re);
  const bParts = b.match(re);
  const len = Math.min(aParts.length, bParts.length);

  for (let i = 0; i < len; i++) {
    let aPart = aParts[i];
    let bPart = bParts[i];

    // If both parts are numbers, compare numerically
    if (!isNaN(aPart) && !isNaN(bPart)) {
      const diff = parseInt(aPart, 10) - parseInt(bPart, 10);
      if (diff !== 0) return diff;
    } else {
      // Compare as strings (case-insensitive)
      const cmp = aPart.localeCompare(bPart, undefined, {
        sensitivity: 'base',
      });
      if (cmp !== 0) return cmp;
    }
  }
  return aParts.length - bParts.length;
};

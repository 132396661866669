import { Edit } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Chip,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { TableSkeleton } from 'components/Table';
import { DataTable } from 'components/Table/Table';
import ActionsDropdown from 'components/Table/TableActions';
import {
  useDialog,
  useTableSearch,
  useUserContext,
} from 'pages/Settings/context';
import { useEffect } from 'react';
import { colorPalette } from 'theme/colors';

import apis from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { Select } from 'components/MultiSelect';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import RevokeDialog from '../Coaches/components/RevokeDialog';
import DeleteDialog from './components/DeleteDialog';
import EditDetailsDialog from './components/EditDialog';

const AdminListTableColumns = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'status', label: 'Status' },

  { id: 'actions', label: 'Actions' },
];

export const AdminList = () => {
  const { openEditDialog, openDeleteDialog } = useDialog();
  const { data, get, isFetching } = useUserContext();
  const { user: loggedUser } = useLoggedUser();
  const { openDialog } = useDialog();

  const searchFields = ['firstName', 'lastName', 'email', 'status'];
  const { searchTerm, setSearchTerm, filteredData } = useTableSearch({
    data,
    searchFields,
  });

  const { call: resend, isLoading: resending } = useApi({
    fetcher: (data) => {
      return apis.resendInvitation({
        organizationId: loggedUser.organization,
        invitationId: data.id,
      });
    },
    successMessage: 'Admin invitation resent successfully',
  });

  useEffect(() => {
    get();
  }, []);

  const actions = (data) => {
    return [
      ...(data.status === 'ACCEPTED'
        ? [
            {
              label: 'View/Edit Details',
              icon: <Edit color="success" />,
              onClick: () => openEditDialog({ data }),
            },
            {
              label: 'Remove Admin',
              icon: <PersonOffIcon color="error" />,
              onClick: () => openDeleteDialog({ data }),
            },
          ]
        : []),
      ...(data.status === 'PENDING'
        ? [
            {
              label: 'Resend Request',
              icon: <ReplayIcon color="success" />,
              onClick: () => resend(data),
            },
            {
              label: 'Revoke Request',
              icon: <HighlightOffIcon color="error" />,
              onClick: () => openDialog({ data, name: 'revoke' }),
            },
          ]
        : []),
    ];
  };

  const adminSearchBar = (
    <Stack direction="row" spacing={1}>
      <Select
        label="Status"
        labelId="status-label"
        options={[
          { label: 'Accepted', value: 'ACCEPTED' },
          { label: 'Pending', value: 'PENDING' },
        ]}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  return (
    <Box
      sx={{
        background: colorPalette.background.paper,
        padding: 2,
        borderRadius: 2,
      }}
    >
      {resending && <LoadingOverlay />}
      <Stack direction="column" spacing={5}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Admin Roster</Typography>
          {adminSearchBar}
        </Stack>
        {isFetching ? (
          <TableSkeleton column={AdminListTableColumns} />
        ) : (
          <DataTable
            columns={AdminListTableColumns}
            sort={true}
            orderBy="createdAt"
            order="desc"
            data={filteredData?.map((item) => ({
              ...item,
              actions: <ActionsDropdown actions={actions(item)} />,
              name: `${item.firstName} ${item.lastName}`,
              createdAt: new Date(item.createdAt).toDateString(),
              status: (
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                />
              ),
            }))}
          />
        )}
      </Stack>
      <DeleteDialog />
      <EditDetailsDialog />
      <RevokeDialog />
    </Box>
  );
};

export default AdminList;

import { GoogleLogin } from '@react-oauth/google';
import adminAPI, { apis } from 'api/api';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Nftelogin.module.css';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { yupResolver } from '@hookform/resolvers/yup';
import { Login as LoginIcon } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  keyframes,
  Typography,
} from '@mui/material';
import { CustomButton } from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import { CustomTextField } from 'components/TextField';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import DeviceNotAllowed from 'pages/DeviceNotAllowed';
import { FormField } from 'pages/Settings/Tabs/common';
import { useForm } from 'react-hook-form';
import { useAuthStore } from 'store';
import { loginSchema } from './common/schema';
const InvalidInvitationModal = ({ open, setOpen, organizationCode }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const handleRedirectToLogin = () => {
    setOpen(false);
    navigate(`/${organizationCode}/login`);
  };

  const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(-20px); // Optional slight slide from top
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          maxWidth: '500px',
          p: 3,
          animation: `${fadeIn} 1s ease-in-out`,
        },
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          p: 0,
        }}
      >
        <Box
          sx={{
            mb: 2,
          }}
        >
          <BlockIcon
            sx={{
              fontSize: 64,
              color: '#ef5350',
            }}
          />
        </Box>

        <Typography
          variant="h5"
          sx={{
            mb: 1,
            fontWeight: 500,
          }}
        >
          Invalid Invitation
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: 'text.secondary',
            mb: 4,
          }}
        >
          Sorry, this invitation is invalid.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            width: '100%',
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            onClick={handleRedirectToLogin}
            startIcon={<KeyboardArrowLeftIcon />}
            sx={{
              borderRadius: '5px',
              textTransform: 'none',
              textWrap: 'nowrap',
            }}
          >
            Return to Login
          </Button>

          {/* <Button
            variant="outlined"
            fullWidth
            onClick={handleClose}
            startIcon={<PersonIcon />}
            sx={{
              borderRadius: '5px',
              textTransform: 'none',
              textWrap: 'nowrap',
            }}
          >
            Contact Admin
          </Button> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const NFTELogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const invitationId = new URLSearchParams(location.search).get('invitationId');
  const organizationCode = location.pathname.split('/')[1];
  const { updateUser } = useLoggedUser();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deviceErrorModalOpen, setDeviceErrorModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [recipientEmailErrorMessage, setRecipientEmailErrorMessage] =
    useState('');

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const [organizationMeta, setOrganizationMeta] = useState({});

  const { call: userLogin, loading: isLogginIn } = useApi({
    fetcher: (data) => apis.organizationUserLogin(data),
  });

  const validateEmail = (value) => {
    let currentEmails = value;
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    if (!regex.test(currentEmails.replace(/\s/g, ''))) {
      setRecipientEmailErrorMessage(`Enter valid Email Address`);
    } else {
      setRecipientEmailErrorMessage('');
    }
  };

  useEffect(() => {
    if (email) validateEmail(email);
    else setRecipientEmailErrorMessage('');
  }, [email]);

  useEffect(() => {
    if (
      password !== '' &&
      confirmPassword !== '' &&
      password !== confirmPassword
    ) {
      setPasswordMatch(false);
      setErrorMessage('Passwords do not match');
    } else {
      setPasswordMatch(true);
      setErrorMessage('');
    }
  }, [password, confirmPassword]);

  const saveOrganizationCode = async () => {
    await adminAPI.saveOrganizationCodeToLocalStorage(organizationCode);
  };

  useEffect(() => {
    saveOrganizationCode();
  }, []);

  useEffect(() => {
    if (invitationId) {
      if (password && confirmPassword && passwordMatch) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    } else {
      if (email && password && !recipientEmailErrorMessage) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, [email, password, confirmPassword, passwordMatch]);

  const [error, setError] = React.useState('');

  const getMe = async () => {
    try {
      const response = await adminAPI.getMe();
      return response.data.user;
    } catch (error) {}
  };

  const roleMeta = async () => {
    try {
      const response = await adminAPI.getOrganizationMetaByRole(
        adminAPI.getOrganizationCodeFromLocalStorage()
      );
      return response.data;
    } catch (error) {}
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const data = {
        invitationId,
        credential: credentialResponse.credential,
        organizationCode,
      };
      const response = await adminAPI.googleSignup(data);
      const metaRoleRespons = await roleMeta();
      const userResponse = await getMe();
      await storeUserDataToLocalStorage(userResponse);

      if (response.status === 200 && response.data.success) {
        if (userResponse.role === 'ADMIN') {
          navigate('/dashboard');
        } else if (userResponse.role === 'COACH') {
          if (metaRoleRespons && metaRoleRespons.role.defaultPath) {
            navigate(metaRoleRespons.role.defaultPath);
          } else {
            navigate('/dashboard');
          }
        } else if (userResponse.role === 'STUDENT') {
          navigate('/student/query-archive');
        }
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setLoading(false);
      setError('Server Error 500. Please contact with Admin');
    }
  };

  const storeLoginInfoAtGlobalState = useAuthStore((state) => state.login);
  const { call: getLoggedUser } = useApi({ fetcher: apis.getMe });
  const storeUserDataToLocalStorage = async () => {
    const response = await getLoggedUser();
    if (response.data) {
      const { firstName, lastName, role, _id } = response.data.user;
      const userInfo = {
        firstName,
        lastName,
        email,
        role,
        _id,
        organization: response.data.organization_id,
        organizationType: response.data?.organizationType ?? null,
        organizationName: organizationCode,
      };
      updateUser(userInfo);
      storeLoginInfoAtGlobalState(userInfo);
    }
  };

  const handleLogin = async (data) => {
    try {
      const payload = {
        email: data.email,
        password: data.password,
        organizationCode,
      };

      const response = await userLogin(payload);

      const userResponse = await getMe();

      const metaRoleRespons = await roleMeta();

      if (userResponse) await storeUserDataToLocalStorage(userResponse);

      if (response.status === 200 && response.data.success) {
        if (userResponse.role === 'ADMIN') {
          navigate('/dashboard');
        } else if (userResponse.role === 'COACH') {
          if (metaRoleRespons && metaRoleRespons.role.defaultPath) {
            navigate(metaRoleRespons.role.defaultPath);
          } else {
            navigate('/dashboard');
          }
        } else if (userResponse.role === 'STUDENT') {
          navigate(metaRoleRespons.role.defaultPath);
        } else if (userResponse.role === 'PARTNER') {
          navigate('/journey');
        } else if (userResponse.role === 'SALES_REP') {
          if (metaRoleRespons && metaRoleRespons.role.defaultPath) {
            navigate(metaRoleRespons.role.defaultPath);
          } else {
            navigate(
              `/${String(userResponse.role).toLocaleLowerCase()}/leaderboard/`
            );
          }
        }
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setError('Invalid Credentials');
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return;
    }

    setLoading(true);

    try {
      const data = {
        password,
        invitationId,
        organizationCode,
      };

      const response = await adminAPI.organizationUserRegistration(data);

      const userResponse = await getMe();
      await storeUserDataToLocalStorage(userResponse);
      setLoading(false);
      if (response.status === 200 && response.data.success) {
        if (userResponse.role === 'ADMIN') {
          navigate('/dashboard');
        } else if (userResponse.role === 'COACH') {
          navigate(`/dashboard`);
        } else if (userResponse.role === 'STUDENT') {
          navigate('/student/query-archive');
        } else if (userResponse.role === 'SALES_REP') {
          navigate('/sales_rep/leaderboard');
        }
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setLoading(false);
      setError('Server Error 500. Please contact with Admin');
    }
  };

  const getOrganizationMeta = async () => {
    setLoading(true);
    try {
      const response = await adminAPI.getOrganizationMetaByOrganizationMetaName(
        organizationCode
      );
      if (String(response.data.device).toLowerCase() === 'mobile') {
        setDeviceErrorModalOpen(true);
        // navigate(`/unauthorized-device`);
      }
      setOrganizationMeta(response.data);
    } catch (error) {}
    setLoading(false);
  };

  const checkUserInvitation = async () => {
    try {
      const params = {
        organizationCode: organizationCode,
        invitationId: invitationId,
      };
      const queryString = new URLSearchParams(params).toString();
      const response = await apis.checkInvitation(queryString);
      if (!response.data.valid) {
        setOpen(true);
      }
      return response.data;
    } catch (error) {
      console.error('Invitation check failed:', error);
      throw error;
    }
  };

  useEffect(() => {
    getOrganizationMeta();
    if (invitationId && organizationCode) {
      checkUserInvitation();
    }
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });

  const renderLoginFormFields = () => {
    const fields = [
      { name: 'email', label: 'Email', type: 'text' },
      { name: 'password', label: 'Password', type: 'password' },
    ];

    return fields.map((field) => (
      <FormField
        formType="ADD"
        key={field.name}
        field={field}
        register={register}
        errors={errors}
        watch={watch}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
      />
    ));
  };

  return (
    <div className={styles.container}>
      {(loading || isLogginIn) && <LoadingOverlay />}

      <div className={styles.login}>
        <div className={styles.login_container}>
          <div className={styles.nfte_logo}>
            <img src={organizationMeta.loginLogo} alt="Logo" />
            <p className={styles.error}>{error}</p>
          </div>
          <DeviceNotAllowed
            open={deviceErrorModalOpen}
            setOpen={setDeviceErrorModalOpen}
          />
          {invitationId && <h1>Register Your Account</h1>}

          {invitationId ? (
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center',
                width: '100%',
                '& > :not(style)': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <InvalidInvitationModal
                open={open}
                setOpen={setOpen}
                organizationCode={organizationCode}
              />
              <FormControl className={styles.formControl} variant="filled">
                <CustomTextField
                  label="Password"
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  helperText={!passwordMatch ? 'Passwords do not match' : ''}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <FormControl className={styles.formControl} variant="filled">
                <CustomTextField
                  label="Confirm Password"
                  value={confirmPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  helperText={!passwordMatch ? 'Passwords do not match' : ''}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <CustomButton
                disabled={disableButton}
                className={styles.signInButton}
                onClick={handleRegistration}
              >
                Sign Up
              </CustomButton>
            </Box>
          ) : (
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center',
                width: '100%',
                '& > :not(style)': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              {renderLoginFormFields()}

              <Button
                variant="contained"
                size="large"
                color="secondary"
                onClick={handleSubmit(handleLogin)}
                startIcon={
                  isLogginIn ? <CircularProgress size={14} /> : <LoginIcon />
                }
              >
                Log In
              </Button>

              <a
                className={styles.forgetPassword}
                href={`/${organizationCode}/forget`}
              >
                Forgot Password?
              </a>
            </Box>
          )}

          <div className={styles.terms_section}>
            <a href="/terms-and-condition">Terms & Conditions</a>
            <span> and </span>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>

          <p className={styles.orText}>or</p>

          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
      </div>
      {/* <div className={styles.branding}>
        <div className={styles.brainding_section}>
          <h1>This is an extremely exciting title!</h1>
          <h3>
            And here is some extremely exciting body text to go with it. You
            should really schedule a demo. Yeah.
          </h3>
          <a href="#">Schedule Demo</a>
        </div>
      </div> */}
    </div>
  );
};

export default NFTELogin;

import { ArrowDropDown, Close } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import React, { forwardRef, useMemo, useState } from 'react';
import { getStyles } from './utils/styles';

// Loading indicator component to reduce duplication
const LoadingIndicator = ({ size = 20, sx = {} }) => (
  <CircularProgress size={size} sx={sx} />
);

// Clear button component to reduce duplication
const ClearButton = ({ onClick, sx = {} }) => (
  <InputAdornment position="end" sx={sx}>
    <IconButton
      aria-label="clear value"
      onClick={onClick}
      edge="end"
      size="small"
    >
      <Close fontSize="small" />
    </IconButton>
  </InputAdornment>
);

export const MultiSelect = forwardRef(
  (
    {
      creatable = false,
      options = [],
      selectedValues = [],
      handleSelectionChange,
      handleClear,
      loading = false,
      disabled = false,
      error = false,
      errorMessage = '',
      label = '',
      clearButton = false,
      ...rest
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);
    const dynamicOptions = useMemo(() => options, [options]);
    const hasValues = selectedValues?.length > 0;

    // Get all styles from the styles function
    const styles = useMemo(
      () => getStyles({ hasValues, focused }),
      [hasValues, focused]
    );

    // Handle focus state changes
    // useEffect(() => {
    //   if (!hasValues && focused) {
    //     setFocused(false);
    //   }
    // }, [selectedValues, hasValues, focused]);

    const handleFocus = () => setFocused(true);

    const handleBlur = (e) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        setFocused(false);
      }
    };

    // Handler for deleting selected values
    const handleDelete = (valueToDelete) => {
      const updatedValues = selectedValues.filter(
        (value) => value !== valueToDelete
      );

      handleSelectionChange({
        target: { value: updatedValues, name: rest.name },
      });

      if (updatedValues.length === 0) {
        setFocused(false);
      }
    };

    // Handler for adding new options in creatable mode
    const handleAddOption = (newOption) => {
      const isNewOption = !dynamicOptions.find(
        (opt) => opt.value === newOption
      );

      if (isNewOption) {
        handleSelectionChange({
          target: { value: [...selectedValues, newOption], name: rest.name },
        });
      }
    };

    // Handler for clearing all selections
    const handleClearAll = (e) => {
      e.stopPropagation();

      if (handleClear) handleClear();
      setFocused(false);
    };

    // Common props for both variants
    const commonProps = {
      error,
      disabled,
      onFocus: handleFocus,
      onBlur: handleBlur,
    };

    // Label component with consistent styling
    const renderLabel = () => (
      <InputLabel
        shrink={hasValues || focused}
        error={error}
        size={rest.size || 'medium'}
        sx={styles.commonLabelStyles}
      >
        {label}
      </InputLabel>
    );

    // Render chips for selected values
    const renderChips = (value, getTagProps) => {
      return value.map((option, index) => {
        const { key, ...tagProps } = getTagProps
          ? getTagProps({ index })
          : { key: index };
        const optionValue = option.value || option;
        const optionLabel = option.label || option;

        return (
          <Chip
            key={key}
            label={optionLabel}
            {...tagProps}
            deleteIcon={<CancelIcon sx={styles.chipDeleteIconStyles} />}
            onDelete={() => handleDelete(optionValue)}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.preventDefault()}
          />
        );
      });
    };

    // Clear button with consistent handling
    const renderClearButton = (additionalSx = {}) => {
      if (!clearButton || !hasValues) return null;

      return <ClearButton onClick={handleClearAll} sx={additionalSx} />;
    };

    // Creatable variant using Autocomplete
    if (creatable) {
      return (
        <FormControl
          fullWidth
          {...commonProps}
          onClick={() => {
            setFocused(true);
          }}
        >
          {renderLabel()}
          <Autocomplete
            size={rest.size}
            multiple
            freeSolo
            disabled={loading || disabled}
            options={dynamicOptions}
            getOptionLabel={(option) => option.label || option}
            value={selectedValues.map(
              (val) =>
                dynamicOptions.find((opt) => opt.value === val) || {
                  value: val,
                  label: val,
                }
            )}
            onChange={(event, newValue) => {
              const updatedValues = newValue.map((item) =>
                typeof item === 'string' ? item : item.value
              );

              handleSelectionChange({
                target: { value: updatedValues, name: rest.name },
              });

              newValue.forEach((item) => {
                if (typeof item === 'string') handleAddOption(item);
              });
            }}
            renderTags={renderChips}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label=""
                placeholder={hasValues ? 'Type and enter to add...' : ''}
                error={error}
                disabled={loading || disabled}
                onFocus={handleFocus}
                onBlur={handleBlur}
                InputProps={{
                  ...params.InputProps,
                  notched: hasValues || focused,
                  endAdornment: (
                    <>
                      {renderClearButton()}
                      {params.InputProps.endAdornment ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {React.Children.toArray(
                            params.InputProps.endAdornment
                          ).filter(
                            (child) => child.type?.muiName !== 'IconButton'
                          )}
                        </Box>
                      ) : null}
                      {loading ? <LoadingIndicator /> : null}
                      <ArrowDropDown sx={styles.arrowDropDownStyles} />
                    </>
                  ),
                  sx: styles.commonOutlinedInputStyles,
                }}
              />
            )}
            onClose={() => {
              if (!hasValues) {
                setFocused(false);
              }
            }}
            disableClearable={true}
          />
          <FormHelperText error={error}>{errorMessage}</FormHelperText>
        </FormControl>
      );
    }

    // Standard variant using Select
    return (
      <FormControl fullWidth {...commonProps}>
        {renderLabel()}
        <Select
          {...rest}
          multiple
          ref={ref}
          open={open}
          onOpen={() => {
            setOpen(true);
            setFocused(true);
          }}
          onClose={() => {
            setOpen(false);
            if (!hasValues) {
              setFocused(false);
            }
          }}
          error={error}
          value={selectedValues}
          onChange={(e) => {
            handleSelectionChange(e);
            setOpen(false);
          }}
          disabled={disabled}
          input={
            <OutlinedInput
              notched={hasValues}
              label={label}
              sx={styles.commonOutlinedInputStyles}
            />
          }
          renderValue={(selected) => (
            <Box sx={styles.chipContainerStyles}>
              {renderChips(
                selected
                  .map(
                    (value) =>
                      dynamicOptions.find((option) => option.value === value) ||
                      ''
                  )
                  .filter(Boolean)
              )}
            </Box>
          )}
          endAdornment={renderClearButton(styles.clearButtonAdornmentStyles)}
          IconComponent={(props) =>
            loading ? (
              <LoadingIndicator sx={{ mr: 1 }} />
            ) : (
              <ArrowDropDown {...props} />
            )
          }
          MenuProps={{
            PaperProps: {
              style: styles.menuPaperStyles,
            },
          }}
        >
          {loading ? (
            <MenuItem disabled>
              <LoadingIndicator size={24} />
              <span style={styles.loadingTextStyles}>Loading...</span>
            </MenuItem>
          ) : (
            dynamicOptions.map(({ value, label }, index) => (
              <MenuItem key={`${value}-${index}`} value={value}>
                {label}
              </MenuItem>
            ))
          )}
        </Select>
        <FormHelperText error={error}>{errorMessage}</FormHelperText>
      </FormControl>
    );
  }
);

export default MultiSelect;

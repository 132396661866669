import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const FORMAT_STRING = 'MMMM do, yyyy'; // "October 7th, 2023"

export const parseDate = (dateStr) => {
  if (typeof dateStr !== 'string') {
    return new Date(NaN);
  }

  // Clean the date string
  const cleanDateStr = dateStr.replace(/(\d+)(st|nd|rd|th)/, '$1');

  // Try to parse the cleaned string
  const parsedDate = new Date(cleanDateStr);

  // Additional validation - check for characteristics of valid dates
  if (!isNaN(parsedDate.getTime())) {
    // Reject strings that don't look like proper dates
    // Check if the string contains at least one digit and common date separators/patterns
    const datePattern =
      /\d+\s*[-/\.]\s*\d+|(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i;
    if (!datePattern.test(dateStr)) {
      return new Date(NaN);
    }

    // Additional check to reject strings like 'mo-123'
    // These often parse as dates but shouldn't
    if (/^[a-z]{2}-\d+$/i.test(dateStr)) {
      return new Date(NaN);
    }

    return parsedDate;
  }

  return new Date(NaN);
};

export const getOriginalZonedDate = ({
  date,
  formatString = FORMAT_STRING,
}) => {
  if (!date) return 'INVALID DATE';
  const utcDate = utcToZonedTime(date);

  const formattedDate = format(utcDate, formatString);
  return formattedDate;
};

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import organizationAPI from 'api/api';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import { DynamicLoadingChecklist, ObjectiveModal } from '.';

export const TeamIndividulaCallHistory = ({
  data,

  behaviors,
  callHistory,
}) => {
  const [selectedCalls, setSelectedCalls] = useState([]);
  const [objective, setObjective] = useState({
    id: '',
    name: '',
    objectives: [],
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const handleCloseModal = () => setModalOpen(false);
  const [persona, setPersona] = useState(null);

  const {
    selectPersona,
    selectedPersona,
    selectPersonaBehavior,
    personaBehavior,
    clearPersonaBehavior,
  } = usePersonaStore();
  const navigate = useNavigate();
  const maleImage = '/male.jpg';
  const femaleImage = '/female.jpg';

  const [selectedBehaviors, setSelectedBehaviors] = useState({
    mood: { id: '', name: '' },
    tone: { id: '', name: '' },
    difficulty: { id: '', name: '' },
  });

  const { call: createObjective, isPending: isLoading } = useApi({
    fetcher: organizationAPI.createObjectives,
  });

  const [checklistItems, setChecklistItems] = useState([
    {
      id: 'createObjective',
      label: 'Objectives Created Successfully',
      isComplete: false,
      apiCall: createObjective,
    },
  ]);

  const updateChecklistItem = (itemId, isComplete) => {
    setChecklistItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isComplete } : item
      )
    );
  };

  useEffect(() => {
    if (behaviors && behaviors.mood) {
      setSelectedBehaviors({
        mood: behaviors.mood[0],
        tone: behaviors.tone[0],
        difficulty: behaviors.difficulty[0],
      });
    }
  }, [behaviors]);

  useEffect(() => {
    if (personaBehavior) {
      clearPersonaBehavior();
    }
  }, []);

  useEffect(() => {
    selectPersonaBehavior(
      selectedBehaviors.mood,
      selectedBehaviors.tone,
      selectedBehaviors.difficulty
    );
  }, [selectedBehaviors]);

  useEffect(() => {
    if (data && data.length > 0 && !selectedPersona) {
      selectPersona(data[0]._id);
    }
  }, [data]);

  const handleNavigation = () => {
    navigate(`/airoleplaycall/ongoingcall/${persona._id}`);
  };

  const handleBehaviorChange = (category, value) => {
    setSelectedBehaviors((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const handleChange = (event) => {
    setSelectedCalls(event.target.value);
  };

  const handleOpenModal = async () => {
    const formData = {
      docIds: selectedCalls,
    };
    try {
      setMenuOpen(false);
      const response = await checklistItems[0].apiCall(formData);
      if (response && response.data) {
        setObjective(response.data);
        updateChecklistItem('createObjective', true);
        setModalOpen(true);
        setSelectedCalls([]);
      }
    } catch (error) {
      setMenuOpen(false);
      setSelectedCalls([]);
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        border: `1px solid #00BF8C`,
        borderRadius: '8px',
        width: '94%',
        height: '100%',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
    >
      {' '}
      <DynamicLoadingChecklist
        isLoading={isLoading}
        checklistItems={checklistItems}
        title="Processing Objectives Data..."
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'start',
          width: '100%',
        }}
      >
        <Box sx={{ mb: 3, width: '100%' }}>
          <Typography
            sx={{
              fontSize: 14,
              color: '#666',
              mb: 1,
            }}
          >
            BUYER PERSONA
          </Typography>
          <FormControl fullWidth>
            <Select
              value={
                selectedPersona
                  ? data.find((p) => p._id === selectedPersona._id) || ''
                  : ''
              }
              onChange={(e) => {
                setPersona(e.target.value);
                selectPersona(e.target.value._id);
              }}
              displayEmpty
              sx={{
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                },
              }}
            >
              <MenuItem value="" sx={{ color: '#666' }}>
                Select Buyer Persona
              </MenuItem>
              {data?.map((persona) => (
                <MenuItem
                  key={persona.id}
                  value={persona}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    py: 2,
                  }}
                >
                  <Avatar
                    src={
                      persona.avatar && persona.avatar !== 'invalid'
                        ? persona.avatar
                        : persona.gender === 'male'
                        ? maleImage
                        : femaleImage
                    }
                    alt={persona.name}
                    sx={{ width: 40, height: 40 }}
                  />
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography>{persona.name}</Typography>
                      <Chip
                        label={persona.callType}
                        size="small"
                        variant="outlined"
                        sx={{
                          height: 22,
                          fontSize: 12,
                          borderRadius: 12,
                        }}
                      />
                    </Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: 14 }}
                    >
                      {persona.role} @ {persona.company}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            width: '100%',
            mb: 1,
          }}
        >
          {/* Mood Dropdown */}
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: '#92a7a5',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Lexend',
                textWrap: 'nowrap',
                backgroundColor: '#fff',
                width: 'fit-content',
                zIndex: 1,
                position: 'relative',
                ml: 1,
              }}
            >
              MOOD
            </Typography>
            {behaviors && behaviors.mood && (
              <FormControl sx={{ minWidth: 130, mt: '-12px', zIndex: 0 }}>
                <Select
                  value={selectedBehaviors.mood.id}
                  onChange={(e) => {
                    const selectedMood = behaviors.mood.find(
                      (mood) => mood.id === e.target.value
                    );
                    handleBehaviorChange('mood', selectedMood);
                  }}
                  sx={{ height: '40px' }}
                >
                  {behaviors.mood.map((mood) => (
                    <MenuItem key={mood.id} value={mood.id}>
                      {mood.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          {/* Tone Dropdown */}
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: '#92a7a5',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Lexend',
                textWrap: 'nowrap',
                backgroundColor: '#fff',
                width: 'fit-content',
                zIndex: 1,
                position: 'relative',
                ml: 1,
              }}
            >
              TONE
            </Typography>
            {behaviors && behaviors.tone && (
              <FormControl sx={{ minWidth: 130, mt: '-12px', zIndex: 0 }}>
                <Select
                  value={selectedBehaviors.tone.id}
                  onChange={(e) => {
                    const selectedTone = behaviors.tone.find(
                      (tone) => tone.id === e.target.value
                    );
                    handleBehaviorChange('tone', selectedTone);
                  }}
                  sx={{ height: '40px' }}
                >
                  {behaviors.tone.map((tone) => (
                    <MenuItem key={tone.id} value={tone.id}>
                      {tone.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          {/* Difficulty Dropdown */}
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: '#92a7a5',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Lexend',
                textWrap: 'nowrap',
                backgroundColor: '#fff',
                width: 'fit-content',
                zIndex: 1,
                position: 'relative',
                ml: 1,
              }}
            >
              DIFFICULTY
            </Typography>
            {behaviors && behaviors.difficulty && (
              <FormControl sx={{ minWidth: 130, mt: '-12px', zIndex: 0 }}>
                <Select
                  value={selectedBehaviors.difficulty.id}
                  onChange={(e) => {
                    const selectedDifficulty = behaviors.difficulty.find(
                      (difficulty) => difficulty.id === e.target.value
                    );
                    handleBehaviorChange('difficulty', selectedDifficulty);
                  }}
                  sx={{ height: '40px' }}
                >
                  {behaviors.difficulty.map((difficulty) => (
                    <MenuItem key={difficulty.id} value={difficulty.id}>
                      {difficulty.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Box>
        {callHistory && callHistory.length > 0 && (
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: '#92a7a5',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Lexend',
                textWrap: 'nowrap',
                mb: 1,
                backgroundColor: '#fff',
                width: 'fit-content',
                zIndex: 1,
                position: 'relative',
                ml: 1,
              }}
            >
              OBJECTIVE SET CREATION
            </Typography>

            <FormControl sx={{ width: '100%', mt: '-20px', zIndex: 0 }}>
              <Select
                multiple
                value={selectedCalls}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Typography sx={{ color: '#666', fontSize: 14 }}>
                        Select calls for objective building
                      </Typography>
                    );
                  }
                  return `${selected.length} conversations selected.`;
                }}
                sx={{
                  height: '40px',
                  '& .MuiSelect-select': {
                    padding: '8px 12px',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      minWidth: '500px',
                      maxHeight: 400,
                      overflowY: 'auto',
                    },
                  },
                }}
                open={menuOpen}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
              >
                {selectedCalls.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 2,
                      padding: '5px 15px',
                    }}
                  >
                    <Typography sx={{ fontSize: 14 }}>
                      {selectedCalls.length} conversations selected.
                    </Typography>
                    <Button
                      startIcon={<FormatListNumberedIcon />}
                      onClick={handleOpenModal}
                      sx={{
                        border: '1px solid #00706C',
                        color: '#00706C',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#DFF1F1FF',
                        },
                        fontSize: 12,
                        padding: '4px 12px',
                        borderRadius: '4px',
                      }}
                    >
                      Build New Objective Set
                    </Button>
                  </Box>
                )}
                {callHistory.map((call) => (
                  <MenuItem
                    key={call.id}
                    value={call.id}
                    disabled={
                      selectedCalls.length >= 3 &&
                      !selectedCalls.includes(call.id)
                    }
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '8px 12px',

                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Checkbox
                        checked={selectedCalls.includes(call.id)}
                        sx={{
                          padding: 0,
                          marginRight: 1,
                        }}
                      />
                      <Typography sx={{ fontSize: 12 }}>{call.name}</Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: 10,
                        color: '#666',
                        border: '1px solid #00706C',
                        padding: '2px 6px',
                        borderRadius: '20px',
                      }}
                    >
                      {call.isAI ? 'AI' : 'Customer'}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          maxWidth: 175,
                        }}
                      >
                        <CalendarMonthIcon fontSize="12px" />
                        <Typography sx={{ fontSize: 12, color: '#666' }}>
                          {call.time}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <LocalPhoneIcon fontSize="12px" />
                        <Typography
                          sx={{ fontSize: 12, color: '#666', minWidth: '60px' }}
                        >
                          {call.duration}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>
      <ObjectiveModal
        open={modalOpen}
        handleClose={handleCloseModal}
        objective={objective}
      />
    </Box>
  );
};

export default TeamIndividulaCallHistory;

import { ChevronLeft, ChevronRight, Lock } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Stepper as MuiStepper,
  Step,
  StepLabel,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getStepperStyles } from './utils/styles';

const {
  CustomConnector,
  CustomStepIconRoot,
  stepperButtonContainer,
  stepperContainer,
} = getStepperStyles();

// Custom step icon component
function CustomStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <CustomStepIconRoot
      ownerState={{ active, completed }}
      className={className}
    >
      {completed ? (
        <Check sx={{ color: active ? 'text.highlight' : 'inherit' }} />
      ) : (
        icon
      )}
    </CustomStepIconRoot>
  );
}

const orientations = {
  horizontal: 'horizontal',
  vertical: 'vertical',
};

export const Stepper = ({
  steps,
  orientation = orientations.horizontal,
  variant = 'default',
  showButtons = true,
  stepperProps = {},
  nextButtonText = 'Next',
  backButtonText = 'Back',
  finishButtonText = 'Finish',

  onFinish = () => console.log('Completed all steps'),

  onStepChange,
  unlockedIndex = 0,
}) => {
  // Use prop if provided, otherwise use internal state
  const [activeStep, setActiveStepState] = useState(unlockedIndex);
  // const activeStep = activeStepState;

  const [unlockedSteps, setUnlockedSteps] = useState(
    steps.map((_, index) => index <= unlockedIndex)
  );
  const [stepCompletionStatus, setStepCompletionStatus] = useState(
    new Array(steps.length).fill(false)
  );

  useEffect(() => {
    setActiveStepState(unlockedIndex);

    // Update unlocked steps
    const newUnlockedSteps = steps.map((_, index) => index <= unlockedIndex);
    setUnlockedSteps(newUnlockedSteps);

    // Automatically mark steps as completed up to the unlocked index
    const newCompletionStatus = steps.map((_, index) => index <= unlockedIndex);
    setStepCompletionStatus(newCompletionStatus);
  }, [unlockedIndex, steps.length]);

  // Determine which setter to use
  const setActiveStep = onStepChange ? onStepChange : setActiveStepState;

  const completeStep = useCallback(
    (stepIndex) => {
      setStepCompletionStatus((prev) => {
        const newStatus = [...prev];
        newStatus[stepIndex] = true;
        return newStatus;
      });

      // Automatically unlock the next step if it exists
      if (stepIndex + 1 < steps.length) {
        setUnlockedSteps((prev) => {
          const newUnlockedSteps = [...prev];
          newUnlockedSteps[stepIndex + 1] = true;
          return newUnlockedSteps;
        });
      }
    },
    [steps.length]
  );

  const handleNext = async () => {
    const currentStep = steps[activeStep];
    if (currentStep?.validateStep) {
      const isValid = await currentStep.validateStep();
      if (!isValid) return; // Stop navigation if validation fails
    }

    if (currentStep?.handleSubmit) {
      await currentStep.handleSubmit();
    }

    completeStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    // Reset unlocked steps and completion status
    setUnlockedSteps(steps.map((_, index) => index === 0));
    setStepCompletionStatus(new Array(steps.length).fill(false));
  };

  const handleStepClick = (stepIndex) => {
    // Only allow clicking on unlocked or previously completed steps
    if (unlockedSteps[stepIndex] || stepIndex < activeStep) {
      setActiveStep(stepIndex);
    }
  };

  const isLastStep = activeStep === steps.length - 1;
  const isCompleted = activeStep === steps.length;

  return (
    <Box sx={stepperContainer}>
      <MuiStepper
        activeStep={activeStep}
        orientation={orientation}
        variant={variant}
        connector={<CustomConnector />}
        {...stepperProps}
      >
        {steps.map((step, index) => (
          <Step
            key={step.label}
            completed={stepCompletionStatus[index]}
            // Disable step if not unlocked
            disabled={!unlockedSteps[index]}
            onClick={() => handleStepClick(index)}
            sx={{
              cursor:
                unlockedSteps[index] && index < activeStep
                  ? 'pointer'
                  : 'default',
              '& .MuiStepLabel-root': {
                cursor:
                  unlockedSteps[index] && index < activeStep
                    ? 'pointer'
                    : 'default',
              },
            }}
          >
            <StepLabel
              StepIconComponent={CustomStepIcon}
              icon={
                unlockedSteps[index] ? (
                  CustomStepIcon({ ...CustomStepIcon.props, icon: index + 1 })
                ) : (
                  <Lock />
                )
              }
              optional={
                step.optional ? (
                  <Typography variant="caption">{step.optional}</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>

      {/* Content container */}
      <Box
        sx={{
          flexGrow: 1,
          pb: 8, // Space for buttons at bottom
          overflowY: 'auto',
        }}
      >
        {/* {!isCompleted ? ( */}
        <Box sx={{ p: 3 }}>
          {/* Step Content */}
          <Typography sx={{ mb: 2 }}>
            {steps[activeStep]?.description}
          </Typography>
          {steps[activeStep]?.content}
        </Box>
        {/* ) 
        : (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              {resetButtonText}
            </Button>
          </Paper>
        )} */}
      </Box>

      {showButtons && (
        <Box sx={stepperButtonContainer}>
          <Button
            startIcon={<ChevronLeft />}
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            {backButtonText}
          </Button>
          <Button
            endIcon={<ChevronRight />}
            variant="contained"
            onClick={isLastStep ? onFinish : handleNext}
          >
            {isLastStep ? finishButtonText : nextButtonText}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Stepper;

import { Add, Edit, RemoveCircleOutline } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Chip,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { apis } from 'api/api';
import { useApi } from 'hooks';
import { useTableSearch } from 'pages/Settings/context';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { useDialogStore, useItemStore } from 'store/useStore';
import { StoreKeys } from 'utils';
import { getOriginalZonedDate } from 'utils/date';
import FlexibleTable from '../../components/CustomMUI/FlexibleTable';
import { TableSkeleton } from '../../components/Table/TableSkeleton';
import { colorPalette } from '../../theme/colors';
import DeleteDialog from './common/DeleteDialog';
import { EditDialog } from './common/EditDialog';
export const ModuleEditDialogOpenKey = 'moduleDetails';

// Custom ActionsDropdown component for FlexibleTable with Action text and chevron
const ActionsDropdown = ({ actions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action) => {
    action.onClick();
    handleClose();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Button
        aria-label="actions"
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          color: '#0BA34AFF',
          minWidth: 'auto',
          textTransform: 'none',
        }}
      >
        Actions
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {actions.map((action, index) => (
          <MenuItem key={index} onClick={() => handleActionClick(action)}>
            <Stack direction="row" spacing={1} alignItems="center">
              {action.icon}
              <Typography variant="body2">{action.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export const ModuleList = () => {
  const user = useAuthStore((state) => state.loggedUser);
  const setDeleteDialog = useDialogStore((state) => state.setDeleteDialog);
  const setEditDialog = useDialogStore((state) => state.setEditDialog);

  const itemStore = useItemStore((state) => state.items);
  const addItemToStore = useItemStore((state) => state.addItem);
  const updateItemStore = useItemStore((state) => state.updateItem);

  const navigate = useNavigate();
  const { call: fetchModules, isLoading: isFetching } = useApi({
    fetcher: () => {
      return apis.getModuleList({ organizationId: user.organization });
    },
    onSuccess: (updatedData) => {
      addItemToStore({ key: StoreKeys.MODULES, value: updatedData });
    },
    queryKey: 'getModuleList',
  });

  const { call: deleteModule } = useApi({
    fetcher: apis.deleteModule,
    successMessage: 'Module deleted successfully',
  });

  useEffect(() => {
    fetchModules();
  }, []);

  const searchFields = ['title', 'category'];
  const { setSearchTerm, filteredData } = useTableSearch({
    data: itemStore.modules,
    searchFields,
    filter: (item) => item.status !== 'archive',
  });

  const actions = (data) => {
    return [
      {
        label: 'View/Edit Details',
        icon: <Edit color="success" />,
        onClick: () => {
          setEditDialog({
            isOpen: true,
            data,
            forItem: ModuleEditDialogOpenKey,
          });
        },
      },
      {
        label: 'Remove Module',
        icon: <RemoveCircleOutline color="error" />,
        onClick: () =>
          setDeleteDialog({
            isOpen: true,
            forItem: 'Module',
            onOk: async () => {
              await deleteModule({
                moduleId: data._id,
                data: {
                  status: 'archive',
                },
              });

              const updatedList = itemStore.modules.filter(
                (module) => module._id !== data._id
              );
              updateItemStore({
                key: StoreKeys.MODULES,
                value: updatedList,
              });
            },
          }),
      },
    ];
  };

  // Define columns for FlexibleTable
  const columns = [
    {
      header: 'Module Name',
      key: 'title',
      sortable: true,
      align: 'left',
    },
    {
      header: 'Category',
      key: 'category',
      sortable: true,
      align: 'left',
    },
    {
      header: 'Created On',
      key: 'createdAt',
      sortable: true,
      align: 'left',
      render: (value) => getOriginalZonedDate({ date: value }),
    },
    {
      header: 'Status',
      key: 'status',
      sortable: true,
      align: 'left',
      render: (value) => (
        <Chip
          label={value}
          variant="outlined"
          size="small"
          sx={{ textTransform: 'capitalize' }}
          color={value === 'ACCEPTED' ? 'success' : 'info'}
        />
      ),
    },
    {
      header: 'Type',
      key: 'type',
      sortable: true,
      align: 'left',
    },
    {
      header: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, row) => <ActionsDropdown actions={actions(row)} />,
    },
  ];

  // Convert data for FlexibleTable
  const processedData = filteredData?.map((item) => ({
    ...item,
    id: item._id,
    category: Array.isArray(item.category)
      ? item.category.join(', ')
      : item.category || '',
  }));

  const header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Modules</Typography>
    </Stack>
  );

  return (
    <Stack direction={'column'} gap={5}>
      {header}
      <Divider />
      <Box
        sx={{
          background: colorPalette.background.paper,
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Stack direction="column" spacing={5}>
          {isFetching ? (
            <TableSkeleton column={columns} />
          ) : (
            <FlexibleTable
              data={processedData || []}
              columns={columns}
              initialSortKey="createdAt"
              initialSortDirection="desc"
              isSearchable={true}
              tableStyles={{
                tableContainer: {
                  borderRadius: 2,
                  boxShadow: 'none',
                  border: '1px solid #DAE8E7',
                },
              }}
              component={
                <Button
                  onClick={() => {
                    navigate('/module/create');
                  }}
                  variant="contained"
                  startIcon={<Add />}
                >
                  New Module
                </Button>
              }
            />
          )}
        </Stack>
      </Box>
      <DeleteDialog />
      <EditDialog forItem={ModuleEditDialogOpenKey} />
    </Stack>
  );
};

import apis from 'api/api';
import { useApi } from 'hooks';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { useAuthStore, useDialogStore, useItemStore } from 'store';
import { cleanObject, StoreKeys } from 'utils';

export const useJourney = () => {
  const user = useAuthStore((state) => state.loggedUser);

  const itemStore = useItemStore((state) => state.items);
  const updateEditDialogState = useDialogStore((state) => state.setEditDialog);
  const updateItemStore = useItemStore((state) => state.updateItem);
  const addItemToStore = useItemStore((state) => state.addItem);

  const { call: createJourney, isLoading: isJourneyCreating } = useApi({
    fetcher: apis.createJourney,
    successMessage: 'Journey created successfully',
  });

  const { call: updateJourney, isLoading: isJourneyUpdating } = useApi({
    fetcher: apis.updateJourney,
    successMessage: 'Journey updated successfully',
  });

  const {
    call: createJourneyModules,
    data: aiGenModules,
    loading: isAiModulesCreating,
  } = useApi({
    fetcher: apis.createJourneyModules,
  });

  const { call: fetchModules, loading: isFetchingModules } = useApi({
    fetcher: () => {
      return apis.getModuleList({ organizationId: user.organization });
    },
    onSuccess: (updatedData) => {
      addItemToStore({ key: StoreKeys.MODULES, value: updatedData });
    },
    queryKey: ['getModuleList'],
  });

  const editJourney = async (value) => {
    const cleanData = cleanObject(value);
    const keysToRemove = [
      'organizationId',
      'userId',
      'createdAt',
      'sections',
      'modules',
      'aiModuleId',
      'targetLanguage',
      'translatedModule',
      '__v',
    ];

    const dataToUpdate = Object.fromEntries(
      Object.entries(cleanData)
        .filter(([key]) => !keysToRemove.includes(key))
        .map(([key, value]) => {
          // If this is the questions array, remove _id from each object
          if (key === 'questions' && Array.isArray(value)) {
            const newQuestions = value.map(({ _id, ...rest }) => rest);
            return [key, newQuestions];
          }
          return [key, value];
        })
    );

    await updateJourney({
      data: dataToUpdate,
      journeyId: cleanData._id,
    });

    const updatedList = itemStore[StoreKeys.JOURNEYS].map((journey) =>
      journey._id === cleanData._id ? { ...journey, ...cleanData } : journey
    );

    updateItemStore({ key: StoreKeys.JOURNEYS, value: updatedList });

    updateEditDialogState({ data: cleanData });
  };

  const addJourney = useErrorHandler(async (data) => {
    const cleanData = cleanObject(data);
    const response = await createJourney({
      data: cleanData,
    });

    const updatedList = [response.data, ...itemStore[StoreKeys.JOURNEYS]];
    updateItemStore({ key: StoreKeys.JOURNEYS, value: updatedList });

    return response.data;
  });

  const addJourneyModules = async ({ modules, journeyId }) => {
    const response = await createJourneyModules({
      modules,
      journeyId,
    });
    //to keep module list updated
    await fetchModules();
  };

  return {
    addJourney,
    isJourneyCreating,

    editJourney,
    isJourneyUpdating,

    fetchModules,
    addJourneyModules,
    isAiModulesCreating,
    isFetchingModules,
  };
};

import { ExpandMore } from '@mui/icons-material';
import TranslateIcon from '@mui/icons-material/Translate';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import {
  DynamicLoadingChecklist,
  FormFields,
  VerticalToggleButton,
} from 'components/CustomMUI';

import { useApi } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import useStore from 'store/useStore';
import { languageOptions } from './common/languages';
import { getStyles } from './common/styles';
import ModuleContent from './ModuleContent';

export const ModuleTranslation = ({
  journeyDetails,
  translatedData = [],
  language = '',
}) => {
  const [modules, setModules] = useState([]);
  const { dataUpdated } = useStore();
  const [showOriginal, setShowOriginal] = useState(true);
  const [journeyInfo, setJourneyInfo] = useState(null);
  const moduleRef = useRef(null);
  const [translation, setTranslation] = useState({
    language: '',
  });
  const { translation: styles } = getStyles();
  const [sortedData, setSortedData] = useState([]);
  const [expandedModules, setExpandedModules] = useState(
    modules.reduce((acc, _, index) => ({ ...acc, [index]: true }), {})
  );

  const [data, setData] = useState([]);

  const { call: createJourneyModuleTranslation, loading: isTranslating } =
    useApi({
      fetcher: apis.createJourneyModuleTranslation,
    });

  const { call: getTranslatedModules, loading: isFetchingTranslation } = useApi(
    {
      fetcher: apis.getTranslatedModules,
      // queryKey: ['getTranslatedModules'],
    }
  );

  const {
    batchCall: fetchModuleDetails,
    batchLoading: isModuleDetailsLoading,
  } = useApi({
    fetcher: apis.getModuleById,
    queryKey: ['getModuleById'],
  });

  const { call: getJourneyDetails, loading: journeyIsLoading } = useApi({
    fetcher: apis.getJourney,
    // queryKey: ['getJourney'],
  });
  const loading = isTranslating || isFetchingTranslation;
  const isLoadingTranslation = isModuleDetailsLoading || journeyIsLoading;

  const [checklistItems, setChecklistItems] = useState([
    {
      id: 'generateTranslation',
      label: `Generating Translation From English To ${translation.language}`,
      isComplete: false,
      apiCall: createJourneyModuleTranslation,
    },
    {
      id: 'getTranslation',
      label: `Fetching ${translation.language} Translation`,
      isComplete: false,
      apiCall: getTranslatedModules,
    },
  ]);

  const handleFetchModuleDetails = async () => {
    const uniqueModuleIds = new Set();
    journeyInfo?.translatedModule.forEach((module) => {
      uniqueModuleIds.add(module.originalModule);
      uniqueModuleIds.add(module.translatedModule);
    });
    const uniqueModuleIdArray = Array.from(uniqueModuleIds);

    const requestPayloads = uniqueModuleIdArray.map((id) => [{ moduleId: id }]);

    // Make one batch call for all unique module IDs
    const moduleResponses = await fetchModuleDetails(requestPayloads, {
      queryKey: ['getModuleById', uniqueModuleIdArray],
    });

    //lookup map from moduleId to fetched data
    const moduleDataMap = {};
    moduleResponses.forEach((data, index) => {
      moduleDataMap[uniqueModuleIdArray[index]] = data || {};
    });

    const moduleDetails = journeyInfo?.translatedModule.map((module) => ({
      originalModule: moduleDataMap[module.originalModule] || {},
      translatedModule: moduleDataMap[module.translatedModule] || {},
    }));

    setData(moduleDetails);
  };

  const handleFetchAiModules = async () => {
    const { aiModuleId } = journeyInfo;
    if (!aiModuleId?.length) return;

    const moduleRequests = aiModuleId.map((moduleId) => [{ moduleId }]);

    const moduleDetails = await fetchModuleDetails(moduleRequests);

    setModules(moduleDetails || []);
  };

  const loadInitData = async () => {
    await handleFetchModuleDetails();
    await handleFetchAiModules();
  };

  useEffect(() => {
    if (journeyInfo) {
      loadInitData();
      setTranslation({
        language: journeyInfo?.targetLanguage,
      });
    }
  }, [journeyInfo]);

  useEffect(() => {
    (async () => {
      if (journeyDetails?._id) {
        const response = await getJourneyDetails({
          journeyId: journeyDetails?._id ?? null,
        });
        setJourneyInfo(response.data);
      }
    })();
  }, [dataUpdated, journeyDetails]);

  useEffect(() => {
    setChecklistItems((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        label:
          item.id === 'generateTranslation'
            ? `Generating Translation From English To ${translation.language}`
            : `Fetching ${translation.language} Translation`,
      }))
    );
  }, [translation.language]);

  const updateChecklistItem = (itemId, isComplete) => {
    setChecklistItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isComplete } : item
      )
    );
  };

  const toggleOriginal = () => setShowOriginal(!showOriginal);

  const handleChange = (field, newValue, setValue) => {
    setValue((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const extractActivities = (module) => {
    const activities = [];
    if (module?.sections) {
      module.sections.forEach((section) => {
        if (section.activity && section.minutes) {
          activities.push({
            name: section.activity,
            duration: section.minutes.toString(),
          });
        }
      });
    }
    return activities;
  };

  useEffect(() => {
    const processedData =
      !data || data.length === 0
        ? modules.map((module) => ({
            originalModule: module,
            translatedModule: {},
          }))
        : [...data]
            .filter((item) => item?.originalModule)
            .sort((a, b) =>
              a.originalModule.title.localeCompare(b.originalModule.title)
            );

    setSortedData(processedData);
  }, [data, modules]);

  useEffect(() => {
    if (translatedData.length > 0) {
      setData(translatedData);
    }
    if (language) {
      setTranslation({ language: language });
    }
  }, [translatedData, language]);

  const handleTranslate = async () => {
    const tempData = {
      language: translation.language,
    };
    if (journeyDetails?._id) {
      await checklistItems[0].apiCall({
        data: tempData,
        journeyId: journeyDetails?._id,
      });

      updateChecklistItem('generateTranslation', true);
      const translatedResponse = await checklistItems[1].apiCall(
        journeyDetails?._id
      );
      updateChecklistItem('getTranslation', true);

      setData(translatedResponse.data);
    }
  };

  const handleAccordionChange = (moduleIndex) => {
    setExpandedModules((prev) => ({
      ...prev,
      [moduleIndex]: !prev[moduleIndex],
    }));
  };

  const LoadingOverlay = ({ open, message }) => {
    return (
      <Backdrop sx={styles.backdrop} open={open}>
        <CircularProgress color="inherit" />
        {message && (
          <Typography variant="h6" align="center">
            {message}
          </Typography>
        )}
      </Backdrop>
    );
  };

  return (
    <Box sx={styles.container}>
      {isLoadingTranslation ? (
        <LoadingOverlay
          open={isLoadingTranslation}
          message={'Please wait while fetching translation data..'}
        />
      ) : null}
      <DynamicLoadingChecklist
        isLoading={loading}
        checklistItems={checklistItems}
        title={`Translating data. This may take a few minutes.`}
      />
      <Typography
        variant="h6"
        sx={{ mb: 2, fontWeight: 300, fontSize: '20px' }}
      >
        Translation System
      </Typography>
      <Divider sx={styles.divider} />

      <Box sx={styles.accordionContainer}>
        {modules &&
          modules.length > 0 &&
          modules
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((module, index) => (
              <Accordion
                key={index}
                sx={{
                  ...styles.accordion,
                  ...styles.accordionSummary,
                }}
                expanded={expandedModules[index]}
                onChange={() => handleAccordionChange(index)}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>
                    Module {index + 1 + `: `}: {module.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={styles.accordionDetails}>
                  {module.sections.map((section, sIndex) => (
                    <Box key={sIndex} sx={styles.accordionContent}>
                      <Typography sx={styles.accordionTitle}>
                        Section {index + 1 + `: `} {section.title}
                      </Typography>
                      <Box sx={styles.accordionSubContent}>
                        {section.subSections.map((sm, smIndex) => (
                          <Typography
                            key={smIndex}
                            variant="body2"
                            color="text.secondary"
                          >
                            SM {index + 1 + `: `} {section.title}
                            {sm.title}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
      </Box>

      <Box sx={styles.translationContainer}>
        <Box sx={styles.translationHeader}>
          <FormFields
            type="select"
            name="language"
            label="TARGET LANGUAGE"
            formData={translation}
            canSearch={true}
            handleChange={(name, value) =>
              handleChange(name, value, setTranslation)
            }
            options={languageOptions}
            props={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 400,
                    overflowY: 'auto',
                  },
                },
              },
            }}
          />
        </Box>
        <Button
          variant="outlined"
          color="primary"
          sx={{ minWidth: 180, height: 55 }}
          startIcon={<TranslateIcon />}
          onClick={handleTranslate}
        >
          Translate
        </Button>
      </Box>

      {sortedData.length > 0 && (
        <Box sx={styles.moduleContainer} ref={moduleRef}>
          <Box
            sx={{ flex: 1, pr: 3, display: showOriginal ? 'block' : 'none' }}
          >
            <Box sx={styles.moduleContent}>
              {sortedData &&
                sortedData.length > 0 &&
                sortedData.map((item, index) => (
                  <ModuleContent
                    title={`Module ${index + 1}`}
                    isTranslated={false}
                    data={item.originalModule}
                    disabled={true}
                    key={`original-${index}`}
                  />
                ))}
            </Box>
          </Box>

          <VerticalToggleButton
            onClick={toggleOriginal}
            isExpanded={showOriginal}
            hideLabel={'HIDE ORIGINAL'}
            showLabel={'SHOW ORIGINAL'}
          />

          <Box sx={{ flex: 1, pl: showOriginal ? 3 : 3 }}>
            <Box sx={styles.moduleContent}>
              {sortedData &&
                sortedData.length > 0 &&
                sortedData.map((item, index) => (
                  <ModuleContent
                    title={`Module ${index + 1}`}
                    isTranslated={true}
                    data={item.translatedModule}
                    key={`translated-${index}`}
                    disabled={
                      journeyDetails &&
                      String(journeyDetails.status).toLowerCase() === 'complete'
                    }
                    exportable={true}
                  />
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

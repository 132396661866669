import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CancelIcon from '@mui/icons-material/Cancel';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import { useEffect, useState } from 'react';

const ImageUpload = ({
  onImageChange,
  onRemoveImage,
  previewImage = null,
  label = 'SELECT A TEAM AVATAR',
  color = '#243030',
  imageId,
  showRemoveButton = false,
}) => {
  const [uploadedImage, setUploadedImage] = useState(null);

  useEffect(() => {
    setUploadedImage(null);
  }, [imageId]);

  useEffect(() => {
    if (previewImage) {
      setUploadedImage(previewImage);
    }
  }, [previewImage]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const base64String = await convertToBase64(file);
        setUploadedImage(base64String);
        onImageChange({ base64: base64String, file, imageId });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    }
  };

  const handleRemoveImage = () => {
    setUploadedImage(null);
    if (onRemoveImage) {
      onRemoveImage();
    }
  };

  const inputId = `upload-image-${imageId}`;

  return (
    <FormControl variant="outlined" fullWidth>
      <Box
        sx={{
          border: '1px solid #DAE8E7',
          borderRadius: '8px',
          padding: '30px 10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '40px',
          position: 'relative',
        }}
      >
        {uploadedImage && (
          <>
            <Avatar
              src={uploadedImage}
              sx={{
                width: 90,
                height: 90,
                cursor: 'pointer',
                borderRadius: '8px',
              }}
              onClick={() => onImageChange({ base64: uploadedImage, imageId })}
            />
            {showRemoveButton && (
              <IconButton
                size="small"
                color="error"
                onClick={handleRemoveImage}
                sx={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  zIndex: 1,
                  backgroundColor: '#fff',
                  boxShadow: '0 0 5px rgba(0,0,0,0.2)',
                  '&:hover': {
                    backgroundColor: '#fff',
                    boxShadow: '0 0 8px rgba(0,0,0,0.3)',
                  },
                }}
              >
                <CancelIcon fontSize="small" />
              </IconButton>
            )}
          </>
        )}

        <label htmlFor={inputId}>
          <Box
            sx={{
              width: 90,
              height: 90,
              cursor: 'pointer',
              border: '1px dashed #00706C',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AddPhotoAlternateIcon sx={{ fontSize: '74px' }} />
          </Box>
          <input
            id={inputId}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
        </label>
      </Box>
      <InputLabel
        sx={{
          position: 'absolute',
          left: '10px',
          top: '-25px',
          backgroundColor: '#fff',
          paddingRight: '10px',
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: 'Lexend',
          color: color,
        }}
      >
        {label}
      </InputLabel>
    </FormControl>
  );
};

export default ImageUpload;

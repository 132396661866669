import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'contexts/SnackbarContext';
import { useEffect, useState } from 'react';
import { useLoadingStore } from 'store/useLoadingState';

const apiQueue = {
  queue: [],
  isProcessing: false,

  addToQueue(task) {
    return new Promise((resolve) => {
      this.queue.push({
        ...task,
        resolve,
      });
      this.processQueue();
    });
  },

  async processQueue() {
    if (this.isProcessing || this.queue.length === 0) return;

    this.isProcessing = true;
    const next = this.queue[0];

    try {
      const result = await next.execute();
      next.resolve(result);
    } catch (error) {
      next.resolve(null);
    } finally {
      this.queue.shift();
      this.isProcessing = false;
      if (this.queue.length > 0) {
        this.processQueue();
      }
    }
  },
};

export const useFetcher = ({
  fetcher,
  onSuccess,
  successMessage,
  loaderId,
  loaderLabel,
  loaderStyle = 'simple',
  cache = false,
  cacheKey,
  staleTime = 5 * 60 * 1000,
  cacheTime = 30 * 60 * 1000,
  updateHookData = true,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const { showSnackbar } = useSnackbar();
  const { addLoader, updateLoader, removeLoader } = useLoadingStore();
  const queryClient = useQueryClient();

  const generatedCacheKey =
    cacheKey || (typeof fetcher === 'function' ? fetcher.name : 'fetcher');

  const query = useQuery({
    queryKey: [generatedCacheKey],
    queryFn: () => fetcher().then((res) => res.data),
    enabled: cache && !!fetcher,
    staleTime,
    cacheTime,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    return () => {
      if (loaderId) {
        removeLoader(loaderId);
      }
    };
  }, [loaderId, removeLoader]);

  const executeCall = async (...params) => {
    try {
      if (loaderId) updateLoader(loaderId, 'loading');
      const res = await fetcher(...params);

      if (res?.data) {
        if (updateHookData) {
          setData(res.data);
        }

        if (cache) {
          queryClient.setQueryData([generatedCacheKey], res.data);
        }

        if (onSuccess) onSuccess(res.data);
        if (successMessage) showSnackbar(successMessage, 'success');
        if (loaderId) updateLoader(loaderId, 'success');
      }
      return res;
    } catch (err) {
      if (updateHookData) {
        setIsError(true);
        setError(err);
      }
      showSnackbar(err.message || 'An error occurred', 'error');
      if (loaderId) updateLoader(loaderId, 'error');
      console.error('API Call Error:', err);
      throw err;
    } finally {
      if (updateHookData) {
        setIsLoading(false);
      }

      if (loaderId) setTimeout(() => removeLoader(loaderId), 1000);
    }
  };

  const call = async (...params) => {
    if (updateHookData) {
      setIsLoading(true);
      setIsError(false);
      setError(null);
    }

    if (loaderId && loaderLabel) {
      addLoader(loaderId, loaderLabel);

      if (loaderStyle === 'checklist') {
        return apiQueue.addToQueue({
          execute: () => executeCall(...params),
        });
      } else {
        return executeCall(...params);
      }
    } else {
      return executeCall(...params);
    }
  };

  const invalidateCache = () => {
    if (cache) {
      queryClient.invalidateQueries([generatedCacheKey]);
    }
  };

  const clearData = () => {
    setData(null);
    setError(null);
    setIsError(false);
  };

  return {
    data: cache ? query.data : data,
    isLoading: cache ? query.isLoading || isLoading : isLoading,
    isError: cache ? query.isError || isError : isError,
    error: cache ? query.error || error : error,
    call,
    invalidateCache,
    clearData,
    queryClient,
  };
};

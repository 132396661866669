import AddIcon from '@mui/icons-material/Add';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import organizationAPI from 'api/api';
import spinnerData from 'components/lottieConfig/spinner.json';
import { useFetcher } from 'hooks/useFetcher';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TickMarkIcon } from 'svg/CustomSVGIcon';
import UploadDocument from './Common/UploadDocument';
import styles from './styles/Dashboard.module.css';
import { LoadingSystem } from 'components/CustomMUI';
import { LoaderVariants } from 'components/CustomMUI/LoadingSystem';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinnerData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const RemoveModal = ({
  open,
  setOpen,
  setLoading,
  getDocumentsByOrganization,
  documentId,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleDelete = async (e, documentId) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await organizationAPI.deleteDocumentAnalayzeById(
        documentId
      );
      await getDocumentsByOrganization();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <h5>Remove</h5>
          <p>Are you sure you want to delete?</p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button
            onClick={(e) => {
              handleDelete(e, documentId);
              setOpen(false);
            }}
            className={styles.remove}
          >
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const DocumentDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [canvasList, setCanvasList] = useState([]);
  const [user, setUser] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [documentUploadOpen, setDocumentUploadOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [startNew, setStartNew] = React.useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [owner, setOwner] = useState('');
  const [type, setType] = useState('');
  const [tags, setTags] = useState('');
  const [year, setYear] = useState('');

  const [buttonDisable, setButtonDisable] = useState(true);

  const [files, setFiles] = useState([{}]);

  const [deleteDocumentId, setDeleteDocumentId] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { call: getResumeRuleId } = useFetcher({
    fetcher: organizationAPI.getResumeRuleId,
    loaderId: 'getResumeRuleId',
    loaderStyle: 'simple',
  });

  const { call: documentAnalyzerUploadDocument } = useFetcher({
    fetcher: organizationAPI.documentAnalyzerUploadDocument,

    loaderId: 'documentAnalyzerUploadDocument',
    loaderStyle: 'simple',
  });

  const { call: getUserData } = useFetcher({
    fetcher: organizationAPI.getMe,
    loaderId: 'getUserData',
    loaderStyle: 'simple',
  });

  const { call: getDocumentAnalyzeByUser } = useFetcher({
    fetcher: organizationAPI.getDocumentAnalyzeByUser,
    successMessage: 'Fetched all documents successfully',
    loaderId: 'getDocumentAnalyzeByUser',
    loaderStyle: 'simple',
  });

  const convertDate = (dateString) => {
    return moment(dateString).format('dddd • MMMM D, YYYY • h:mm A');
  };

  const handleAnalysis = (e) => {
    e.preventDefault();
    navigate('/student/documentanalysis/newanalysis');
  };

  const handleUploadDocument = async (e) => {
    e.preventDefault();
    setUploadLoading({
      step1: 'waiting',
      step2: 'waiting',
      step3: 'waiting',
    });
    const rule = await getResumeRuleId();
    let ruleId = rule.data.ruleId;

    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('ruleId', ruleId);
    formData.append('filename', files[0].name);

    const response = await documentAnalyzerUploadDocument(formData);
    await getDocumentsByUser();

    setUploadLoading(false);

    setDocumentUploadOpen(true);
    setOpen(false);
  };

  const handleView = (e, documentId) => {
    e.preventDefault();
    navigate(`/student/documentanalysis/view/${documentId}`);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await getUserData();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (name) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [name]);

  useEffect(() => {
    if (uploadLoading) {
      const timer = setTimeout(() => {
        if (uploadLoading.step1 === 'waiting') {
          setUploadLoading({
            step1: 'working',
            step2: 'waiting',
            step3: 'waiting',
          });
        } else if (uploadLoading.step1 === 'working') {
          setUploadLoading({
            step1: 'done',
            step2: 'working',
            step3: 'waiting',
          });
        } else if (uploadLoading.step2 === 'working') {
          setUploadLoading({
            step1: 'done',
            step2: 'done',
            step3: 'working',
          });
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [uploadLoading]);

  const handleDrop = useCallback((acceptedFiles) => {
    // Do something with the dropped files, e.g. upload them to a server
    setFiles(acceptedFiles);
  }, []);

  const getDocumentsByUser = async () => {
    setLoading(true);
    try {
      const response = await getDocumentAnalyzeByUser();
      setCanvasList(
        response.data.reverse().map((item) => {
          if (item.status === 'coachReview') {
            return {
              ...item,
              status: 'Coach Review',
            };
          } else if (item.status === 'aiReview') {
            return {
              ...item,
              status: 'AI Review',
            };
          } else if (item.status === 'studentReview') {
            return {
              ...item,
              status: 'Student Review',
            };
          } else if (item.status === 'done') {
            return {
              ...item,
              status: 'Done',
            };
          } else if (item.status === 'pending') {
            return {
              ...item,
              status: 'Pending',
            };
          } else if (item.status === 'error') {
            return {
              ...item,
              status: 'Error',
            };
          } else {
            return item;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getDocumentsByUser();
  }, []);

  return (
    <>
      <LoadingSystem
        variant={LoaderVariants.SIMPLE}
        title={`Loading Documents. Please Wait ...`}
        color={'#FFFFFFFF'}
        height={150}
        width={150}
      />
      <RemoveModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        setLoading={setLoading}
        getDocumentsByOrganization={getDocumentsByUser}
        documentId={deleteDocumentId}
      />
      <UploadDocument
        onUploadSuccess={getDocumentsByUser}
        showUploadModal={showUploadModal}
        setShowUploadModal={setShowUploadModal}
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />

      <Modal
        open={documentUploadOpen}
        onClose={() => setDocumentUploadOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal_view}>
          <div className={styles.modal_upload}>
            <TickMarkIcon />
            <h2>Document Uploaded</h2>
            <p>Your document has been successfully uploaded.</p>
            <button
              className={styles.ok_button}
              onClick={() => setDocumentUploadOpen(false)}
            >
              Ok
            </button>
          </div>
        </Box>
      </Modal>
      <>
        <h1>Welcome, {user && user.firstName + ' ' + user.lastName}</h1>
        <div className={styles.border}></div>
        <div className={styles.learner_header}>
          <h2>Documents</h2>
          <div className={styles.learner_header_buttons}>
            <button
              onClick={() => setShowUploadModal(true)}
              className={styles.iteration}
            >
              <AddIcon />
              Upload New Document
            </button>
          </div>
        </div>
        <div className={styles.coach_roaster}>
          <div className={styles.learner_table}>
            <table className="styled_table">
              <thead className="table_headers">
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Uploaded on</th>

                  {/* <th>Tags</th> */}
                  <th>Analysis Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {canvasList.length > 0 &&
                  canvasList.map((item, index) => {
                    return (
                      <tr className={styles.coachrow} key={index}>
                        <td className={styles.data}>
                          {item.answers[0]?.answer?.title
                            ? item.answers[0]?.answer?.title
                            : item.filename}
                        </td>
                        <td className={styles.data}>Business Plan</td>
                        <td className={styles.data}>
                          {convertDate(item.createdAt)}
                        </td>

                        {/* <td className={styles.tag_year}>
                          <span>TAG_EXAMPLE_1</span> <span>2024</span>
                        </td> */}
                        <td className={styles.data}>{item.status}</td>

                        <td className={styles.data}>
                          <div className={styles.status}>
                            <div className={styles.resend}>
                              <CloudDownloadOutlinedIcon />
                              <a
                                href={item.fileUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download
                              </a>
                            </div>
                            <div className={styles.resend}>
                              <VisibilityOutlinedIcon />
                              <button onClick={(e) => handleView(e, item._id)}>
                                Analysis
                              </button>
                            </div>

                            <div className={styles.resend}>
                              <DeleteOutlineOutlinedIcon />
                              <button
                                onClick={(e) => {
                                  setDeleteDocumentId(item._id);
                                  setDeleteModalOpen(true);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {canvasList.length === 0 && (
              <div className={styles.empty_table}>
                <p>No submissions have been made yet.</p>
                <p onClick={handleOpen} className={styles.link}>
                  Click here to make a new submission template.
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default DocumentDashboard;

import { yupResolver } from '@hookform/resolvers/yup';
import { ContentBox } from 'components/Box';
import Stepper from 'components/Stepper/Stepper';
import { UserProvider } from 'pages/Settings/context';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useItemStore } from 'store';
import { stepMapper } from './common/const';
import { journeyDetails } from './common/schema';
import { useJourney } from './common/useJourney';
import { useModuleSelection } from './common/useModuleSelection';
import { JourneyDetails } from './JourneyDetails';
import { ModuleSelection } from './ModuleSelection';
import { ModuleTranslation } from './ModuleTranslation';

export const AddJourney = () => {
  const [currentJourney, setCurrentJourney] = useState(null);
  const itemStore = useItemStore((state) => state.items);
  const navigate = useNavigate();
  const { editJourney } = useJourney();
  const {
    addJourney,
    addJourneyModules,
    isAiModulesCreating,
    isFetchingModules,
  } = useJourney();
  const { selectedModules } = useModuleSelection({
    journeyInfo: currentJourney,
  });
  const methods = useForm({
    resolver: yupResolver(journeyDetails),
    mode: 'onChange',
    defaultValues: { language: 'English' },
  });

  const validateDetails = async () => {
    // trigger validation for fields that belong to the Details step
    return await methods.trigger();
  };

  const updateJourneyStatus = async (stepKey) => {
    const unlockedSteps = stepMapper[currentJourney?.status]?.index || 0;
    if (unlockedSteps < stepMapper[stepKey].index) {
      await editJourney({
        _id: currentJourney._id,
        status: stepMapper[stepKey].name,
      });
    }
  };

  const submitDetails = async () => {
    const alreadySaved = currentJourney?._id;
    if (!alreadySaved) {
      const data = methods.getValues();
      const { journeyDates, ...rest } = data;
      const response = await addJourney({
        ...rest,
        startDate: journeyDates.startDate,
        endDate: journeyDates.endDate,
        status: stepMapper.details.name,
      });
      setCurrentJourney(response);
    }
  };

  const submitModules = async () => {
    const alreadyAiGenerated = currentJourney?.aiModuleId?.length;
    if (!alreadyAiGenerated) {
      await addJourneyModules({
        journeyId: currentJourney?._id,
        modules: selectedModules,
        status: stepMapper.modules.name,
      });
    }
  };

  const submitAIModules = async () => {
    await updateJourneyStatus(stepMapper.aiModules.name);
  };

  const submitTranslation = async () => {
    await updateJourneyStatus(stepMapper.translation.name);
  };

  const onComplete = async () => {
    await updateJourneyStatus(stepMapper.complete.name);
  };

  useEffect(() => {
    return () => {
      methods.reset();
    };
  }, []);

  const steps = [
    {
      label: 'Details',
      description: 'Journey Details',
      optional: 'Required',
      content: (
        <JourneyDetails
          details={currentJourney}
          submitDetails={submitDetails}
        />
      ),
      validateStep: validateDetails,
      handleSubmit: submitDetails,
    },
    {
      label: 'Modules',
      description: 'Journey Module Selection',
      content: (
        <ModuleSelection
          selectionType="add"
          journeyId={currentJourney && currentJourney?._id}
          loadingText={
            isAiModulesCreating || isFetchingModules
              ? 'Generating AI modules... This may take up to a minute.'
              : null
          }
        />
      ),
      handleSubmit: submitModules,
    },
    {
      label: 'AI Modules',
      description: 'Journey AI Modules',
      content: (
        <ModuleSelection
          selectionType="ai"
          journeyId={currentJourney && currentJourney?._id}
        />
      ),
      handleSubmit: submitAIModules,
    },
    {
      label: 'Translation',
      description: 'Module Translation',
      content: (
        <ModuleTranslation journeyDetails={currentJourney} modules={[]} />
      ),
      handleSubmit: submitTranslation,
    },
    {
      label: 'Final Modules',
      description: 'Final Modules',
      content: (
        <ModuleSelection
          selectionType="final"
          journeyId={currentJourney && currentJourney?._id}
        />
      ),
      //handleSubmit: submitTranslation,
    },
  ];

  return (
    <UserProvider userType={'PARTNER'}>
      <FormProvider {...methods}>
        <ContentBox style={{ height: '90vh', overFlow: 'scroll' }}>
          <Stepper
            steps={steps}
            orientation="horizontal"
            finishButtonText="Submit"
            onFinish={() => {
              onComplete();
              navigate(-1);
            }}
          />
        </ContentBox>
      </FormProvider>
    </UserProvider>
  );
};

import { KeyboardArrowUp } from '@mui/icons-material';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Divider,
  Fab,
  IconButton,
  Stack,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { UserProvider, useUserContext } from 'pages/Settings/context';
import { FormField } from 'pages/Settings/Tabs/common';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDialogStore } from 'store/useStore';
import { countryOptions } from 'utils/const/countries';
import { defaultQuestions, languageOptions } from './common/const';
export const JourneyDetails = ({
  isEdit = false,
  details = null,
  submitDetails,
}) => {
  const navigate = useNavigate();
  const editDialogState = useDialogStore((state) => state.editDialog);
  const [isTitleManuallyEdited, setIsTitleManuallyEdited] = useState(false);
  const [questions, setQuestions] = useState(defaultQuestions);
  // const { editJourney } = useJourney();
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
    // handleSubmit,
    control,
  } = useFormContext();

  const {
    data: partnerList,
    get: getPartnerList,
    isFetching: fetchingPartner,
  } = useUserContext();

  useEffect(() => {
    if (details) {
      // in case of edit and step traverse, prefill the form with the details
      reset(details);
    }
  }, []);

  const saveAndExit = async (data) => {
    const isValidPartially = await trigger([
      'title',
      'partnerId',
      'description',
      'year',
    ]);
    if (submitDetails && isValidPartially) {
      await submitDetails(data);
      navigate(-1);
    }
  };

  const getRecentYears = () => {
    let years = [];
    let currentYear = new Date().getFullYear();
    for (let i = 0; i < 3; i++) {
      years.push({ value: currentYear + i, label: currentYear + i });
    }
    return years;
  };

  const updateApiQuestions = useCallback(
    (updatedQuestions) => {
      const apiQuestions = updatedQuestions.map(({ id, question, answer }) => ({
        question: question,
        answer: answer || '',
      }));
      setValue('questions', apiQuestions);
    },
    [setValue]
  );

  const removeQuestion = (id) => {
    const updatedQuestions = questions.filter((q) => q.id !== id);
    setQuestions(updatedQuestions);
    updateApiQuestions(updatedQuestions);
  };

  useEffect(() => {
    getPartnerList();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isEdit) return;
    const year = watch('year');
    const partner = watch('partnerName');
    const language = watch('language');
    if (!isTitleManuallyEdited && year && partner && language) {
      setValue('title', `${year}-${language}-${partner}`);
    }
  }, [watch('partnerName'), watch('year'), watch('language'), watch('title')]);

  useEffect(() => {
    if (editDialogState?.data?.questions) {
      const existingQuestionsMap = new Map(
        editDialogState.data.questions.map((q) => [q.question, q.answer])
      );
      const uiQuestions = defaultQuestions.map((q) => ({
        ...q,
        answer: existingQuestionsMap.get(q.question) || q.answer,
      }));

      setQuestions(uiQuestions);
      updateApiQuestions(uiQuestions);
    }
  }, [reset, updateApiQuestions]);

  const QAFields = (
    <Box sx={{ width: '100%', mb: 3 }} key={`qa-fields-${questions.length}`}>
      <Typography variant="button" sx={{ display: 'block', mb: 2 }}>
        Program Dimensions (Informs AI-assisted program design)
      </Typography>
      <Divider sx={{ borderColor: 'primary.main', my: 2 }} />
      <Stack spacing={3}>
        {questions.map((q, index) => (
          <Stack key={q.id} direction="row" spacing={2} alignItems="center">
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Controller
                name={`questions.${index}.answer`}
                control={control}
                defaultValue={q.answer}
                rules={{ required: 'Answer is required' }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      fullWidth
                      label={q.question}
                      placeholder={q.question}
                      error={!!errors.questions?.[index]?.answer}
                      helperText={errors.questions?.[index]?.answer?.message}
                      rows={4}
                      multiline
                      inputProps={{ style: { resize: 'both' } }}
                      disabled={
                        details &&
                        String(details.status).toLowerCase() === 'complete'
                      }
                      sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: '#585858FF',
                          WebkitTextFillColor: '#585858FF', // Needed for Safari
                        },
                      }}
                    />
                    <input
                      type="hidden"
                      {...register(`questions.${index}.question`)}
                      value={q.question}
                    />
                  </>
                )}
              />
            </Stack>
            {!q.isDefault && (
              <IconButton onClick={() => removeQuestion(q.id)} sx={{ mt: 1 }}>
                <RemoveIcon />
              </IconButton>
            )}
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ borderColor: 'primary.main', my: 3 }} />
    </Box>
  );

  const renderFormFields = () => {
    const infoFields = [
      {
        name: 'partnerId',
        label: 'Partner',
        type: 'select',
        options: partnerList?.map((partner) => ({
          value: partner.id,
          label: `${partner.firstName} ${partner.lastName}`,
        })),
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        multiline: true,
      },
    ];

    const settingsFields = [
      [
        {
          name: 'year',
          label: 'Year',
          type: 'select',
          options: getRecentYears(),
        },
        {
          name: 'totalNumberOfMeetings',
          label: 'Total Number Of Sessions',
          type: 'number',
        },

        {
          name: 'numberOfHoursPerMeeting',
          label: 'Hours Per Sessions',
          type: 'number',
        },
      ],
      [
        {
          name: 'formatOfInstruction',
          label: 'Format of Instruction',
          type: 'select',
          options: [
            { value: 'Virtual', label: 'Virtual Instruction' },
            { value: 'In-Person', label: 'In-person Instruction' },
          ],
        },
        {
          name: 'supportStructure',
          label: 'Support Structure',
          type: 'select',
          options: [
            { label: 'Facilitated', value: 'Facilitated' },
            { label: 'Independent', value: 'Independent' },
          ],
        },

        {
          name: 'location',
          label: 'Location',
          type: 'multiSelect',
          options: countryOptions,
          autoComplete: true,
        },
        {
          name: 'language',
          label: 'Target Language',
          type: 'select',
          options: languageOptions,
        },
      ],
      [
        {
          name: 'journeyDates',
          label: 'Start Date',
          type: 'dateRange',
        },
      ],
      {
        name: 'questions',
        component: QAFields,
      },
      {
        name: 'additionalInstructions',
        label: 'Additional Instruction Prompts',
        type: 'text',
        multiline: true,
      },
      { name: 'title', label: 'Journey Title', type: 'text' },
    ];

    return (
      <>
        {infoFields.map((item, index) => (
          <FormField
            loading={fetchingPartner && item.name === 'partnerId'}
            formType="ADD"
            key={`${item.name}-${index}`}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
            onChange={(e) => {
              if (item.name === 'partnerId') {
                const partner = partnerList.find(
                  (item) => item.id === e.target.value
                );
                const partnerName = `${partner.firstName} ${partner.lastName}`;
                setValue('partnerName', partnerName);
              }
            }}
            disabled={
              details && String(details.status).toLowerCase() === 'complete'
            }
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                color: '#585858FF',
                WebkitTextFillColor: '#585858FF', // Needed for Safari
              },
            }}
          />
        ))}
        <Typography variant="button">Module Settings</Typography>
        <Divider sx={{ borderColor: 'primary.main' }} />

        {settingsFields.map((item, index) => {
          const isChunkOfFields = Array.isArray(item);
          if (isChunkOfFields) {
            return (
              <Stack
                direction={'row'}
                spacing={2}
                key={`${item.name}-${index}`}
              >
                {item.map((nestedItem) => {
                  if (nestedItem.type === 'custom' && nestedItem.render) {
                    return (
                      <Box key={`${nestedItem.name}-${index}`} sx={{ flex: 1 }}>
                        {nestedItem.render({
                          onChange: (value) => setValue(nestedItem.name, value),
                          value: watch(nestedItem.name),
                        })}
                      </Box>
                    );
                  }
                  return (
                    <FormField
                      formType="ADD"
                      key={`${nestedItem.name}-${index}`}
                      field={nestedItem}
                      register={register}
                      errors={errors}
                      watch={watch}
                      hookFormUpdater={setValue}
                      hookFormTrigger={trigger}
                      disabled={
                        details &&
                        String(details.status).toLowerCase() === 'complete'
                      }
                      sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: '#585858FF',
                          WebkitTextFillColor: '#585858FF', // Needed for Safari
                        },
                      }}
                    />
                  );
                })}
              </Stack>
            );
          } else {
            if (item.component) {
              return item.component;
            }
            return (
              <FormField
                formType="ADD"
                key={`settings-${item.name}`}
                field={item}
                register={register}
                errors={errors}
                watch={watch}
                hookFormUpdater={setValue}
                hookFormTrigger={trigger}
                onChange={(e) => {
                  if (item.name === 'title') {
                    //if (e.target.value !== '')
                    setIsTitleManuallyEdited(true);
                    //else setIsTitleManuallyEdited(false);
                  }
                }}
                disabled={
                  details && String(details.status).toLowerCase() === 'complete'
                }
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    color: '#585858FF',
                    WebkitTextFillColor: '#585858FF', // Needed for Safari
                  },
                }}
              />
            );
          }
        })}
      </>
    );
  };

  return (
    <UserProvider userType={'PARTNER'}>
      {submitDetails && !isEdit && (
        <Stack direction="row" justifyContent="flex-end" m={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={saveAndExit}
            startIcon={<SaveIcon />}
          >
            Save & Exit
          </Button>
        </Stack>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {renderFormFields()}
        <Zoom in={isVisible}>
          <Fab
            onClick={scrollToTop}
            color="primary"
            aria-label="scroll back to top"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              boxShadow: 3,
              zIndex: 1000,
            }}
          >
            <KeyboardArrowUp />
          </Fab>
        </Zoom>
      </Box>
    </UserProvider>
  );
};

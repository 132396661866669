import {
  Add,
  Delete,
  ExpandMore,
  FileDownload,
  Save,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { DynamicLoadingChecklist, ImageUpload } from 'components/CustomMUI';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { nanoid } from 'nanoid';
import { FormField } from 'pages/Settings/Tabs/common';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDialogStore } from 'store/useStore';
import { theme } from 'theme/muiTheme';
import { useModule } from './common/useModule';
const ModuleSection = ({ moduleId, formMethods }) => {
  const [sections, setSections] = useState([]);
  const [subsectionImages, setSubsectionImages] = useState({});
  const [isBulkSaving, setIsBulkSaving] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [exportingSectionId, setExportingSectionId] = useState(null);
  const editDialogState = useDialogStore((state) => state.editDialog);
  const setDeleteDialog = useDialogStore((state) => state.setDeleteDialog);
  // Set to null initially, will be set to the first section ID when data loads
  const [expandedSection, setExpandedSection] = useState(null);
  const [expandedSubsections, setExpandedSubsections] = useState({});
  const [removedImages, setRemovedImages] = useState({});

  moduleId = moduleId ? moduleId : editDialogState?.data?._id;

  const {
    moduleDetails,
    fetchModule,
    moduleLoading,
    storeSection,
    isSectionCreating,
    updateSection,
    isSectionUpdating,
    deleteSection,
    storeSubSection,
    isSubSectionCreating,
    updateSubSection,
    isSubSectionUpdating,
    deleteSubSection,
    subSectionContentUpload,
    validateSection,
    validateSubsection,
    generateModuleSectionDocx,
    isExporting,
  } = useModule(moduleId);

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const clearStoredInfo = () => {
    reset();
    setSections([]);
    setSubsectionImages({});
  };

  const fillStoredData = () => {
    const sectionsToEdit = moduleDetails?.sections ?? [];
    const resetData = {};
    const initialExpandedSubsections = {};

    const processSubsections = (subSections) =>
      subSections?.map((subsection) => {
        resetData[`title-${subsection._id}`] = subsection.title;
        resetData[`notes-${subsection._id}`] = subsection.notes;
        resetData[`description-${subsection._id}`] = subsection.description;
        resetData[`minutes-${subsection._id}`] = subsection.minutes || 0;
        // resetData[`sectionType-${subsection._id}`] = subsection.sectionType;
        initialExpandedSubsections[subsection._id] = true;

        return {
          id: `${subsection._id}`,
          dbId: subsection._id,
          saved: true,
          title: subsection.title,
          // sectionType: subsection.sectionType,
          notes: subsection.notes,
          description: subsection.description,
          minutes: subsection.minutes || 0,
          readingMaterial: subsection.readingMaterial,
        };
      }) || [];

    const processedSections = sectionsToEdit.map((section) => {
      resetData[`title-${section._id}`] = section.title;
      resetData[`activity-${section._id}`] = section.activity;
      resetData[`minutes-${section._id}`] = section.minutes;
      // resetData[`sectionType-${section._id}`] = section.sectionType;
      const subsects = processSubsections(section.subSections);
      return {
        id: `${section._id}`,
        dbId: section._id,
        saved: true,
        title: section.title,
        activity: section.activity,
        minutes: section.minutes,
        // sectionType: section.sectionType,
        subsections: subsects,
      };
    });

    if (processedSections.length) {
      setSections(processedSections);
      reset(resetData);
      if (processedSections.length > 0) {
        setExpandedSection(processedSections[0].id);
      }
      setExpandedSubsections(initialExpandedSubsections);
    }
  };

  useEffect(() => {
    (async () => {
      if (moduleId) {
        clearStoredInfo();
        await fetchModule({ moduleId });
      }
    })();
  }, [moduleId]);

  useEffect(() => {
    fillStoredData();
  }, [moduleDetails]);

  const calculateTotalMinutes = (subsections) => {
    return subsections.reduce((total, subsection) => {
      return total + (Number(subsection.minutes) || 0);
    }, 0);
  };

  const handleAddSection = () => {
    const blankSection = {
      id: `sect-${nanoid()}`,
      dbId: null,
      saved: false,
      title: '',
      activity: '',
      minutes: 0,
      // sectionType: '',
      subsections: [],
    };
    setSections([...sections, blankSection]);

    // Auto-expand the new section
    setExpandedSection(blankSection.id);
  };

  const handleDeleteSection = async (section) => {
    if (section.dbId) {
      await deleteSection({
        moduleId,
        sectionId: section.dbId,
      });
    }

    setSubsectionImages((prevImages) => {
      const newImages = { ...prevImages };
      section.subsections.forEach((subsection) => {
        delete newImages[subsection.id];
      });
      return newImages;
    });

    setSections(sections.filter((item) => item.id !== section.id));
    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[section.id];
      return newErrors;
    });
  };

  const handleAddSubsection = (section) => {
    const blankSubsection = {
      id: `subsect-${nanoid()}`,
      dbId: null,
      saved: false,
      minutes: 0,
      title: '',
      // sectionType: '',
      notes: '',
      description: '',
    };

    setSections(
      sections.map((item) =>
        item.id === section.id
          ? {
              ...item,
              subsections: [...item.subsections, blankSubsection],
            }
          : item
      )
    );

    setExpandedSubsections((prev) => ({
      ...prev,
      [blankSubsection.id]: true,
    }));
  };

  const handleDeleteSubsection = async ({ section, subsection }) => {
    if (subsection.dbId) {
      await deleteSubSection({
        moduleId,
        sectionId: section.dbId,
        subSectionId: subsection.dbId,
      });
    }

    setSubsectionImages((prevImages) => {
      const newImages = { ...prevImages };
      delete newImages[subsection.id];
      return newImages;
    });

    setSections(
      sections.map((item) =>
        item.id === section.id
          ? {
              ...item,
              subsections: item.subsections.filter(
                (sub) => sub.id !== subsection.id
              ),
            }
          : item
      )
    );

    const updatedSections = sections.map((item) => {
      if (item.id === section.id) {
        const updatedSubsections = item.subsections.filter(
          (sub) => sub.id !== subsection.id
        );
        return {
          ...item,
          minutes: calculateTotalMinutes(updatedSubsections),
          subsections: updatedSubsections,
        };
      }
      return item;
    });

    setSections(updatedSections);
  };

  const saveSection = useErrorHandler(async ({ section }) => {
    const validation = validateSection(section);

    if (!validation.isValid) {
      setValidationErrors((prev) => ({
        ...prev,
        [section.id]: validation.errors,
      }));
      return false;
    }

    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[section.id];
      return newErrors;
    });

    for (const subsection of section.subsections) {
      const subsectionValidation = validateSubsection(subsection);
      if (subsectionValidation.isValid) {
        await saveSubsection({ section, subsection });
      }
    }

    const totalMinutes = calculateTotalMinutes(section.subsections);

    const data = {
      title: section.title || '',
      activity: section.activity || '',
      minutes: totalMinutes,
      // sectionType: section.sectionType || '',
    };

    if (section.dbId) {
      await updateSection({
        data,
        moduleId,
        sectionId: section.dbId,
      });
    } else {
      const response = await storeSection({
        data,
        moduleId,
      });

      handleFieldChange({
        sectionId: section.id,
        fieldName: 'dbId',
        value: response.data._id,
      });
    }

    handleFieldChange({
      sectionId: section.id,
      fieldName: 'saved',
      value: true,
    });

    handleFieldChange({
      sectionId: section.id,
      fieldName: 'minutes',
      value: totalMinutes,
    });

    return true;
  });

  const saveSubsection = async ({ section, subsection }) => {
    const validation = validateSubsection(subsection);

    if (!validation.isValid) {
      setValidationErrors((prev) => ({
        ...prev,
        [subsection.id]: validation.errors,
      }));
      return false;
    }

    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[subsection.id];
      return newErrors;
    });

    const data = {
      title: subsection.title || '',
      notes: subsection.notes || '',
      description: subsection.description || '',
      minutes: Number(subsection.minutes) || 0,
      // sectionType: subsection.sectionType || '',
    };

    if (removedImages[subsection.id]) {
      data.readingMaterial = [];
    }

    if (subsection.dbId) {
      await updateSubSection({
        data,
        moduleId,
        sectionId: section.dbId,
        subSectionId: subsection.dbId,
      });

      if (
        subsectionImages[subsection.id]?.file &&
        !removedImages[subsection.id]
      ) {
        const formData = new FormData();
        formData.append('files', subsectionImages[subsection.id].file);
        await subSectionContentUpload({
          moduleId,
          sectionId: section.dbId,
          subSectionId: subsection.dbId,
          file: formData,
        });
      }
    } else {
      const response = await storeSubSection({
        data,
        moduleId,
        sectionId: section.dbId,
      });

      if (
        subsectionImages[subsection.id]?.file &&
        !removedImages[subsection.id]
      ) {
        const formData = new FormData();
        formData.append('files', subsectionImages[subsection.id].file);
        await subSectionContentUpload({
          moduleId,
          sectionId: section.dbId,
          subSectionId: response.data._id,
          file: formData,
        });
      }

      handleFieldChange({
        sectionId: section.id,
        subsectionId: subsection.id,
        fieldName: 'dbId',
        value: response.data._id,
      });
    }

    handleFieldChange({
      sectionId: section.id,
      subsectionId: subsection.id,
      fieldName: 'saved',
      value: true,
    });

    const totalMinutes = calculateTotalMinutes(
      sections.find((s) => s.id === section.id).subsections
    );

    handleFieldChange({
      sectionId: section.id,
      fieldName: 'minutes',
      value: totalMinutes,
    });

    if (section.dbId && section.saved) {
      await updateSection({
        data: { minutes: totalMinutes },
        moduleId,
        sectionId: section.dbId,
      });
    }

    return true;
  };

  const handleFieldChange = ({
    sectionId,
    fieldName,
    value,
    subsectionId = null,
  }) => {
    setSections((prevSections) => {
      const newSections = prevSections.map((section) => {
        if (section.id === sectionId) {
          if (subsectionId) {
            const updatedSubsections = section.subsections.map((subsection) =>
              subsection.id === subsectionId
                ? { ...subsection, [fieldName]: value }
                : subsection
            );

            if (fieldName === 'minutes') {
              const totalMinutes = calculateTotalMinutes(updatedSubsections);
              return {
                ...section,
                minutes: totalMinutes,
                subsections: updatedSubsections,
              };
            }

            return {
              ...section,
              subsections: updatedSubsections,
            };
          } else {
            return { ...section, [fieldName]: value };
          }
        }
        return section;
      });

      return newSections;
    });
  };

  const handleRemoveImage = async (subsection, sectionId) => {
    setRemovedImages((prev) => ({
      ...prev,
      [subsection.id]: true,
    }));

    setSubsectionImages((prev) => {
      const newImages = { ...prev };
      delete newImages[subsection.id];
      return newImages;
    });

    if (subsection.dbId && subsection?.readingMaterial?.length > 0) {
      try {
        await updateSubSection({
          data: { readingMaterial: [] },
          moduleId,
          sectionId: sectionId,
          subSectionId: subsection.dbId,
        });
      } catch (error) {
        console.error('Error removing image:', error);
      }
    }
  };

  const bulkSaveAll = useCallback(async () => {
    try {
      setIsBulkSaving(true);

      let hasValidationErrors = false;
      const newValidationErrors = {};

      // Validate all sections and subsections first
      for (const section of sections) {
        const validation = validateSection(section);
        if (!validation.isValid) {
          newValidationErrors[section.id] = validation.errors;
          hasValidationErrors = true;
        }

        for (const subsection of section.subsections) {
          const subValidation = validateSubsection(subsection);
          if (!subValidation.isValid) {
            newValidationErrors[subsection.id] = subValidation.errors;
            hasValidationErrors = true;
          }
        }
      }

      if (hasValidationErrors) {
        setValidationErrors(newValidationErrors);
        return false;
      }

      // First, process all sections to ensure they exist in the database
      for (const section of sections) {
        // Always use explicit data object with empty strings for text fields
        const data = {
          title: section.title || '',
          activity: section.activity || '',
          minutes: calculateTotalMinutes(section.subsections),
          // sectionType: section.sectionType || '',
        };

        if (!section.dbId) {
          // Create new section
          const response = await storeSection({
            data,
            moduleId,
          });

          // Update the section with the new dbId
          handleFieldChange({
            sectionId: section.id,
            fieldName: 'dbId',
            value: response.data._id,
          });

          // Update the section in our local state
          section.dbId = response.data._id;
          section.saved = true;
        } else if (!section.saved || true) {
          // Always update existing sections during bulk save
          await updateSection({
            data,
            moduleId,
            sectionId: section.dbId,
          });
          section.saved = true;
        }
      }

      // Then, process all subsections
      for (const section of sections) {
        if (section.dbId) {
          for (const subsection of section.subsections) {
            // Always use explicit data object instead of cleanObject
            const data = {
              title: subsection.title || '',
              notes: subsection.notes || '',
              description: subsection.description || '',
              minutes: Number(subsection.minutes) || 0,
              // sectionType: subsection.sectionType || '',
            };

            // If this image was removed, update readingMaterial to empty array
            if (removedImages[subsection.id]) {
              data.readingMaterial = [];
            }

            if (!subsection.dbId) {
              // Create new subsection
              const response = await storeSubSection({
                data,
                moduleId,
                sectionId: section.dbId,
              });

              subsection.dbId = response.data._id;
              subsection.saved = true;

              handleFieldChange({
                sectionId: section.id,
                subsectionId: subsection.id,
                fieldName: 'dbId',
                value: response.data._id,
              });

              // Upload image if exists
              if (
                subsectionImages[subsection.id]?.file &&
                !removedImages[subsection.id]
              ) {
                const formData = new FormData();
                formData.append('files', subsectionImages[subsection.id].file);
                await subSectionContentUpload({
                  moduleId,
                  sectionId: section.dbId,
                  subSectionId: response.data._id,
                  file: formData,
                });
              }
            } else if (!subsection.saved || true) {
              // Always update existing subsections during bulk save
              await updateSubSection({
                data,
                moduleId,
                sectionId: section.dbId,
                subSectionId: subsection.dbId,
              });

              subsection.saved = true;

              // Only upload image if a new one exists
              if (
                subsectionImages[subsection.id]?.file &&
                !removedImages[subsection.id]
              ) {
                const formData = new FormData();
                formData.append('files', subsectionImages[subsection.id].file);
                await subSectionContentUpload({
                  moduleId,
                  sectionId: section.dbId,
                  subSectionId: subsection.dbId,
                  file: formData,
                });
              }
            }
          }
        }
      }

      // Update section minutes after all subsections are processed
      for (const section of sections) {
        if (section.dbId) {
          const totalMinutes = calculateTotalMinutes(section.subsections);

          // Only update if minutes have changed
          if (totalMinutes !== section.minutes) {
            await updateSection({
              data: { minutes: totalMinutes },
              moduleId,
              sectionId: section.dbId,
            });

            handleFieldChange({
              sectionId: section.id,
              fieldName: 'minutes',
              value: totalMinutes,
            });
          }
        }
      }

      // Update local state to reflect all changes
      const updatedSections = sections.map((section) => ({
        ...section,
        saved: true,
        subsections: section.subsections.map((subsection) => ({
          ...subsection,
          saved: true,
        })),
      }));

      setSections(updatedSections);

      // Clear only processed images, not references to existing ones
      const newSubsectionImages = {};
      Object.keys(subsectionImages).forEach((id) => {
        if (!subsectionImages[id].file) {
          newSubsectionImages[id] = subsectionImages[id];
        }
      });
      setSubsectionImages(newSubsectionImages);

      setValidationErrors({});

      return true;
    } catch (error) {
      console.error('Error during bulk save:', error);
      return false;
    } finally {
      setIsBulkSaving(false);
    }
  }, [sections, moduleId, subsectionImages, removedImages]);

  useEffect(() => {
    if (formMethods) {
      formMethods.registerModuleSectionSave = bulkSaveAll;
    }

    return () => {
      if (formMethods) {
        delete formMethods.registerModuleSectionSave;
      }
    };
  }, [formMethods, bulkSaveAll]);

  const handleSectionAccordionChange = (sectionId) => (_, isExpanded) => {
    setExpandedSection(isExpanded ? sectionId : null);
  };

  const handleSubsectionAccordionChange = (subsectionId) => (_, isExpanded) => {
    setExpandedSubsections((prev) => ({
      ...prev,
      [subsectionId]: isExpanded,
    }));
  };

  const handleExportSection = async ({ moduleId, sectionId }) => {
    setExportingSectionId(sectionId);
    try {
      const response = await generateModuleSectionDocx({
        moduleId,
        sectionId,
      });
      const url = response?.data?.url;
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sectionId}.docx`;
      a.click();
    } finally {
      setExportingSectionId(null);
    }
  };

  const renderSections = (id) => {
    const infoFields = [
      {
        name: `title-${id}`,
        label: 'Section Category',
        type: 'select',
        options: [
          { label: 'Mindset Warm-ups', value: 'Mindset Warm-ups' },
          { label: 'Investigation', value: 'Investigation' },
          { label: 'Foundations', value: 'Foundations' },
          { label: 'Lab', value: 'Lab' },
          { label: 'Closing The Learning', value: 'Closing The Learning' },
        ],
        required: true,
      },
      {
        name: `activity-${id}`,
        label: 'Activity',
        type: 'text',
        required: true,
      },
      // {
      //   name: `minutes-${id}`,
      //   label: 'Total Minutes (auto-calculated)',
      //   type: 'number',
      //   disabled: true,
      //   value: section?.minutes || 0,
      // },
      // {
      //   name: `sectionType-${id}`,
      //   label: 'Type',
      //   type: 'select',
      //   options: moduleOptions.sectionType,
      // },
    ];
    return infoFields.map((item) => (
      <Stack gap={2} direction={'column'} mb={3} key={item.name}>
        <FormField
          formType="ADD"
          key={item.name}
          field={item}
          register={register}
          errors={errors}
          watch={watch}
          hookFormUpdater={setValue}
          hookFormTrigger={trigger}
          onChange={(e) =>
            !item.disabled &&
            handleFieldChange({
              sectionId: id,
              fieldName: item.name.split('-')[0],
              value: e.target.value,
            })
          }
        />
        {validationErrors[id] &&
          validationErrors[id][item.name.split('-')[0]] && (
            <Typography color="error" variant="caption">
              {validationErrors[id][item.name.split('-')[0]]}
            </Typography>
          )}
      </Stack>
    ));
  };

  const renderSubsections = ({ sectionId, subsectionId }) => {
    const infoFields = [
      {
        name: `title-${subsectionId}`,
        label: 'Title',
        type: 'text',
        required: true,
      },
      {
        name: `minutes-${subsectionId}`,
        label: 'Minutes',
        type: 'number',
        required: true,
      },
      // {
      //   name: `sectionType-${subsectionId}`,
      //   label: 'Type',
      //   type: 'select',
      //   options: moduleOptions.sectionType,
      // },
      {
        name: `description-${subsectionId}`,
        label: 'Description',
        type: 'text',
        multiline: true,
      },
      {
        name: `notes-${subsectionId}`,
        label: 'Note',
        type: 'text',
        multiline: true,
      },
    ];

    return (
      <Stack gap={2} direction={'column'}>
        {infoFields.map((item) => (
          <FormField
            formType="ADD"
            key={item.name}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
            onChange={(e) => {
              handleFieldChange({
                sectionId: sectionId,
                subsectionId: subsectionId,
                fieldName: item.name.split('-')[0],
                value: e.target.value,
              });
            }}
          />
        ))}
        {validationErrors[subsectionId] &&
          Object.entries(validationErrors[subsectionId]).map(
            ([field, message]) => (
              <Typography key={field} color="error" variant="caption">
                {message}
              </Typography>
            )
          )}
      </Stack>
    );
  };

  return (
    <Box p={2}>
      {isSectionCreating || isSubSectionCreating || isBulkSaving ? (
        <DynamicLoadingChecklist
          isLoading={isSectionCreating || isSubSectionCreating || isBulkSaving}
          checklistItems={[
            {
              id: 'creatingModuleSection',
              label: isSectionCreating
                ? 'Creating Module Section. Please Wait ...'
                : isSectionUpdating
                ? 'Updating Module Section. Please Wait ...'
                : isSubSectionCreating
                ? 'Creating Module Sub-Section. Please Wait ...'
                : isSubSectionUpdating
                ? 'Updating Module Sub-Section. Please Wait ...'
                : 'Saving Sub-Modules In Bulk. Please Wait ...',
              isComplete: false,
            },
          ]}
          title="Loading Modules Details"
        />
      ) : null}

      {moduleLoading ? (
        <Stack direction={'row'} p={2} justifyContent={'center'}>
          <DynamicLoadingChecklist
            isLoading={moduleLoading}
            checklistItems={[
              {
                id: 'fetchingModules',
                label: 'Fetching Modules Section. Please Wait...',
                isComplete: false,
              },
            ]}
            title="Loading Modules Details"
          />
        </Stack>
      ) : (
        sections.map((section, sectionIndex) => (
          <Accordion
            key={section.id}
            expanded={expandedSection === section.id}
            onChange={handleSectionAccordionChange(section.id)}
            sx={{
              mb: 2,
              '&:before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`section-${section.id}-content`}
              id={`section-${section.id}-header`}
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius:
                  expandedSection === section.id ? '4px 4px 0 0' : 4,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                width="100%"
                justifyContent={'space-between'}
              >
                <Typography variant="h6">
                  Section {sectionIndex + 1}: {section.title || ''}
                </Typography>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  {section.dbId && (
                    <Button
                      loadingPosition="start"
                      variant="text"
                      startIcon={
                        exportingSectionId === section.dbId ? (
                          <CircularProgress size={12} />
                        ) : (
                          <FileDownload />
                        )
                      }
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleExportSection({
                          moduleId,
                          sectionId: section.dbId,
                        });
                      }}
                    >
                      Export
                    </Button>
                  )}

                  <Chip
                    label={`${section.minutes} minutes`}
                    variant="outlined"
                    size="small"
                    sx={{ textTransform: 'capitalize', ml: 'auto' }}
                  />
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{ p: 3, backgroundColor: theme.palette.background.paper }}
            >
              {validationErrors[section.id] &&
                Object.keys(validationErrors[section.id]).length > 0 && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    Please fix the validation errors before saving
                  </Alert>
                )}

              {renderSections(section.id)}

              {section.subsections.map((subsection, subsectionIndex) => {
                const disableAction = section.dbId
                  ? false
                  : !section.saved || subsection.saved;

                return (
                  <Accordion
                    key={subsection.id}
                    expanded={Boolean(expandedSubsections[subsection.id])}
                    onChange={handleSubsectionAccordionChange(subsection.id)}
                    sx={{
                      ml: 2,
                      mb: 2,
                      '&:before': {
                        display: 'none',
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`subsection-${subsection.id}-content`}
                      id={`subsection-${subsection.id}-header`}
                      sx={{
                        backgroundColor: theme.palette.grey[50],
                        borderRadius: expandedSubsections[subsection.id]
                          ? '4px 4px 0 0'
                          : 4,
                      }}
                    >
                      <Box display="flex" alignItems="center" width="100%">
                        <Typography variant="subtitle1">
                          Subsection {subsectionIndex + 1}:{' '}
                          {subsection.title || ''}
                        </Typography>
                        <Chip
                          label={`${subsection.minutes} min`}
                          variant="outlined"
                          size="small"
                          sx={{ ml: 'auto', mr: 1 }}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        p: 3,
                        backgroundColor: theme.palette.background.paper,
                      }}
                    >
                      {renderSubsections({
                        sectionId: section.id,
                        subsectionId: subsection.id,
                      })}

                      <Stack direction={'column'} gap={2} mt={2}>
                        {subsection?.readingMaterial?.length ? (
                          <Link
                            href={subsection?.readingMaterial?.[0].fileUrl}
                            download={subsection?.readingMaterial?.[0].fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="none"
                          >
                            <Button variant="text" color="primary">
                              Download Study Material
                            </Button>
                          </Link>
                        ) : null}

                        <ImageUpload
                          onImageChange={(res) => {
                            if (res) {
                              setSubsectionImages((prev) => ({
                                ...prev,
                                [subsection.id]: {
                                  file: res.file,
                                  base64: res.base64,
                                },
                              }));
                              setRemovedImages((prev) => {
                                const newState = { ...prev };
                                delete newState[subsection.id];
                                return newState;
                              });
                            }
                          }}
                          onRemoveImage={() =>
                            handleRemoveImage(subsection, section.dbId)
                          }
                          previewImage={
                            removedImages[subsection.id]
                              ? ''
                              : subsection?.readingMaterial?.[0]
                              ? subsection?.readingMaterial?.[0].fileUrl
                              : subsectionImages[subsection.id]?.base64 || ''
                          }
                          imageId={subsection.id}
                          label="Upload Module Image"
                          color="#5A5C5CFF"
                          showRemoveButton={
                            !!(
                              subsection?.readingMaterial?.[0]?.fileUrl ||
                              subsectionImages[subsection.id]?.base64
                            )
                          }
                        />
                      </Stack>
                      <Stack
                        direction={'row'}
                        sx={{
                          width: '100%',
                          alignItems: 'end',
                          justifyContent: 'flex-end',
                          gap: 1,
                          mt: 2,
                        }}
                      >
                        <Button
                          disabled={disableAction}
                          color="success"
                          size="small"
                          onClick={() =>
                            saveSubsection({ section, subsection })
                          }
                          startIcon={<Save />}
                        >
                          Save
                        </Button>

                        <Button
                          disabled={disableAction}
                          color="error"
                          size="small"
                          onClick={() => {
                            setDeleteDialog({
                              isOpen: true,
                              forItem: 'Module Sub-section',
                              onOk: async () => {
                                await handleDeleteSubsection({
                                  section,
                                  subsection,
                                });
                              },
                            });
                          }}
                          startIcon={<Delete />}
                        >
                          Remove
                        </Button>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                );
              })}

              {section.title && section.activity && (
                <Button
                  disabled={!section.dbId}
                  variant="outlined"
                  fullWidth
                  onClick={() => handleAddSubsection(section)}
                  startIcon={<Add />}
                  sx={{ mt: 2 }}
                >
                  Add New Subsection to Section {sectionIndex + 1}
                </Button>
              )}

              <Stack
                direction={'row'}
                sx={{
                  width: '100%',
                  alignItems: 'end',
                  justifyContent: 'flex-end',
                  gap: 1,
                  mt: 2,
                }}
              >
                <Button
                  size="small"
                  color="success"
                  startIcon={<Save />}
                  onClick={() => {
                    saveSection({ section });
                  }}
                >
                  Save
                </Button>

                <Button
                  size="small"
                  color="error"
                  startIcon={<Delete />}
                  onClick={() => {
                    setDeleteDialog({
                      isOpen: true,
                      forItem: 'Module Section',
                      onOk: async () => {
                        await handleDeleteSection(section);
                      },
                    });
                  }}
                >
                  Remove
                </Button>
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))
      )}

      <Button
        variant="outlined"
        fullWidth
        onClick={handleAddSection}
        startIcon={<Add />}
      >
        Add New Section
      </Button>
      <DeleteDialog />
    </Box>
  );
};

export default ModuleSection;

import { yupResolver } from '@hookform/resolvers/yup';
import { ContentBox } from 'components/Box';
import Stepper from 'components/Stepper/Stepper';
import { UserProvider } from 'pages/Settings/context';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useItemStore } from 'store';
import { StoreKeys } from 'utils';
import { stepMapper } from './common/const';
import { journeyDetails } from './common/schema';
import { useJourney } from './common/useJourney';
import { useModuleSelection } from './common/useModuleSelection';
import { JourneyDetails } from './JourneyDetails';
import ModuleSelection from './ModuleSelection';
import { ModuleTranslation } from './ModuleTranslation';

const EditJourney = () => {
  const [currentJourney, setCurrentJourney] = useState(null);

  const { journeyId } = useParams();
  const navigate = useNavigate();
  const {
    editJourney,
    addJourneyModules,
    isAiModulesCreating,
    isFetchingModules,
  } = useJourney();
  const { selectedModules } = useModuleSelection({
    journeyInfo: currentJourney,
  });

  const unlockedSteps = stepMapper[currentJourney?.status]?.index || 0;
  const itemStore = useItemStore((state) => state.items);
  const journeyList = itemStore[StoreKeys.JOURNEYS];

  const methods = useForm({
    resolver: yupResolver(journeyDetails),
    mode: 'onChange',
    defaultValues: { language: 'English' },
  });

  const validateDetails = async () => {
    // trigger validation for fields that belong to the Details step
    return await methods.trigger();
  };

  const hasChange = () => {
    const currentValues = methods.getValues();
    return (
      JSON.stringify(currentValues) !==
      JSON.stringify(methods.formState.defaultValues)
    );
  };

  const updateJourneyStatus = async (stepKey) => {
    if (unlockedSteps < stepMapper[stepKey].index) {
      await editJourney({
        _id: currentJourney._id,
        status: stepMapper[stepKey].name,
      });
    }
  };

  const submitDetails = async () => {
    await updateJourneyStatus(stepMapper.details.name);
    if (hasChange()) {
      const data = methods.getValues();
      await editJourney(data);
    }
  };

  const submitModules = async () => {
    const alreadyAiGenerated = currentJourney?.aiModuleId?.length;
    if (!alreadyAiGenerated) {
      await addJourneyModules({
        journeyId: currentJourney?._id,
        modules: selectedModules,
      });
    }
    await updateJourneyStatus(stepMapper.modules.name);
  };

  const submitAIModules = async () => {
    await updateJourneyStatus(stepMapper.aiModules.name);
  };

  const submitTranslation = async () => {
    await updateJourneyStatus(stepMapper.translation.name);
  };

  const onComplete = async () => {
    await updateJourneyStatus(stepMapper.complete.name);
  };

  useEffect(() => {
    const journeyInfo = journeyList.find((item) => {
      return item._id === journeyId;
    });
    setCurrentJourney(journeyInfo);
    methods.reset(journeyInfo);

    return () => {
      methods.reset();
    };
  }, []);

  const steps = [
    {
      label: 'Details',
      description: 'Journey Details',
      content: (
        <JourneyDetails
          details={currentJourney}
          submitDetails={submitDetails}
          isEdit={true}
        />
      ),
      validateStep: validateDetails,
      handleSubmit: submitDetails,
    },
    {
      label: 'Modules',
      description: 'Journey Module Selection',
      content: (
        <ModuleSelection
          selectionType="edit"
          journeyId={currentJourney && currentJourney?._id}
          loadingText={
            isAiModulesCreating || isFetchingModules
              ? 'Generating AI modules... This may take up to a minute.'
              : null
          }
        />
      ),
      handleSubmit: submitModules,
    },
    {
      label: 'AI Modules',
      description: 'Journey AI Modules',
      content: (
        <ModuleSelection
          selectionType="ai"
          journeyId={currentJourney && currentJourney?._id}
        />
      ),
      handleSubmit: submitAIModules,
    },
    {
      label: 'Translation',
      description: 'Module Translation',
      content: (
        <ModuleTranslation journeyDetails={currentJourney} modules={[]} />
      ),
      handleSubmit: submitTranslation,
    },
    {
      label: 'Final Modules',
      description: 'Module Translation',
      content: (
        <ModuleSelection
          selectionType="final"
          journeyId={currentJourney && currentJourney?._id}
        />
      ),
      //handleSubmit: submitTranslation,
    },
  ];

  return (
    <UserProvider userType={'PARTNER'}>
      <FormProvider {...methods}>
        <ContentBox style={{ height: '90vh', overFlow: 'scroll' }}>
          <Stepper
            steps={steps}
            orientation="horizontal"
            finishButtonText="Submit"
            onFinish={() => {
              onComplete();
              navigate(-1);
            }}
            unlockedIndex={unlockedSteps}
            showButtons={currentJourney?.status !== stepMapper.complete.name}
            //activeStepProp={stepMapper[currentJourney?.status]?.index}
          />
        </ContentBox>
      </FormProvider>
    </UserProvider>
  );
};

export default EditJourney;
